import lodash from 'lodash';

import { useEffect, useRef, useState } from 'react';

import './Slider.scss';

export default function Slider({ min, max, value, step = 1, handler, onMouseDown, onTouchEnd, thresholdColor }) {

  const input = useRef();
  const [val, setVal] = useState(value);

  useEffect(() => {
    handler?.(val);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [val]);

  const percent = lodash.clamp(100 * (value - min) / (max - min), 0, 100);

  const sliderStyle = { 
    '--slider-color': thresholdColor,
    background: `linear-gradient(to right, ${thresholdColor} ${percent}%, #E9E9EB ${percent}%)`
  }

  return (
    <input
      ref={input}
      className='slider'
      style={sliderStyle}
      type='range'
      min={min}
      max={max}
      step={step}
      value={value}
      onInput={() => { setVal(input.current.value) }}
      onMouseDown={() => { input.defaultValue = value; onMouseDown(); }}
      onTouchEnd={() => { input.defaultValue = value; onTouchEnd(); }}
    />
  );
}
