import { observer } from "mobx-react";
import { useRef } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";

import FormLayout from "./FormLayout/FormLayout";
import FormStep1 from "./FormLayout/FormStep1/FormStep1";
import FormStep2 from "./FormLayout/FormStep2/FormStep2";
import FormStep3 from "./FormLayout/FormStep3/FormStep3";
import FormStep4 from "./FormLayout/FormStep4/FormStep4";
import FormStep5 from "./FormLayout/FormStep5/FormStep5";

import paymentFormStore from "./PaymentFormStore";

import "./PaymentForm.scss";

const PaymentForm = observer(() => {
  const navigate = useNavigate();
  const fieldRefs = {
    companyName: useRef(null),
    inn: useRef(null),
    bankAccount: useRef(null),
    bik: useRef(null),
    businessAddress: useRef(null),
    postalAddress: useRef(null),
    accountingNumber: useRef(null),
  };

  const stepComponents = {
    1: FormStep1,
    2: FormStep2,
    3: FormStep3,
    4: FormStep4,
  };

  const handleBackClick = () => {
    if (paymentFormStore.currentStep === 1 || paymentFormStore.currentStep === 5) {
      navigate("/");
    } else {
      paymentFormStore.handleBack();
    }
  };

  const scrollToError = (errorRef) => {
    if (errorRef.current) {
      const headerHeight = 80;
      const rect = errorRef.current.getBoundingClientRect();
      const offsetTop = rect.top + window.scrollY - headerHeight;
      window.scrollTo({ top: offsetTop, behavior: "smooth" });
    }
  };

  const handleNextClick = () => {
    if (paymentFormStore.currentStep === 2) {
      if (!paymentFormStore.validateFormStep2()) {
        const errorRefs = [
          paymentFormStore.companyNameError ? fieldRefs.companyName : null,
          paymentFormStore.innError ? fieldRefs.inn : null,
          paymentFormStore.bankAccountError ? fieldRefs.bankAccount : null,
          paymentFormStore.bikError ? fieldRefs.bik : null,
          paymentFormStore.businessAddressError ? fieldRefs.businessAddress : null,
          paymentFormStore.postalAddressError ? fieldRefs.postalAddress : null,
          paymentFormStore.accountingNumberError ? fieldRefs.accountingNumber : null,
        ];
        const firstErrorRef = errorRefs.find((ref) => ref !== null);
        if (firstErrorRef) {
          scrollToError(firstErrorRef);
        }
        return;
      }
    }
    paymentFormStore.handleNext();
  };

  if (paymentFormStore.currentStep === 5) {
    return <FormStep5 onClick={handleBackClick} />;
  }

  const StepComponent = stepComponents[paymentFormStore.currentStep];

  return (
    <>
      <Helmet>
        <title>Оплата для юридических лиц — Boostclick</title>
        <meta name="description" content="Удобный способ оплаты для юридических лиц: безналичный расчет в сервисе Boostclick" />
        <meta name="robots" content="noindex" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
      </Helmet>

      <FormLayout
        currentStep={paymentFormStore.currentStep}
        onBack={handleBackClick}
        onNext={handleNextClick}
        isNextDisabled={paymentFormStore.isNextButtonDisabled}
      >
        {StepComponent && <StepComponent refs={fieldRefs} />}
      </FormLayout>
    </>
  );
});

export default PaymentForm;
