import { observer } from "mobx-react";
import { useEffect } from "react";

import Plate from "components/UI/Plate/Plate";

import AppStore from "AppStore";
import popupStore from "./PopupStore";

import "./Popup.scss";

export const Popup = observer(
  ({
    modalType,
    title,
    subtitle,
    children,
    header = false,
    backButton,
    onBack,
  }) => {
    const plateClassName = `popup__plate ${modalType}`;

    const handleClose = () => {
      popupStore.close();
      popupStore.closeReportModal();

      // TODO: setTimeout -> onTransitionEnd
      setTimeout(() => {
        AppStore.resetAgreed();
        AppStore.clearUploadedFile();
        popupStore.setChatStart('welcome');
        popupStore.clearChatMessages();
      }, 300);
    };

    useEffect(() => {
      const handleKeyDown = (e) => {
        if (e.keyCode === 27) {
          handleClose();
        }
      };

      const handleClickOverlay = (e) => {
        if (e.target.classList.contains("popup")) {
          handleClose();
        }
      };

      window.addEventListener("keydown", handleKeyDown);
      document.addEventListener("click", handleClickOverlay);

      return () => {
        window.removeEventListener("keydown", handleKeyDown);
        document.removeEventListener("click", handleClickOverlay);
      };
    }, []);

    return (
      <div
        className={`popup ${
          popupStore.isOpen && popupStore.modalType === modalType
            ? "popup_opened"
            : ""
        }`}
      >
        <Plate className={plateClassName}>
          {header ? (
            <div className="popup__header">
              {backButton && (
                <button
                  className="popup__button-back"
                  type="button"
                  aria-label="Вернуться назад"
                  onClick={onBack}
                />
              )}
              <p className="popup__header-title">{title}</p>
              {subtitle && <p className="popup__header-subtitle">{subtitle}</p>}
              <button
                className="popup__button-close"
                style={header ? { marginLeft: "0" } : ""}
                type="button"
                aria-label="Закрыть модальное окно"
                onClick={handleClose}
              />
            </div>
          ) : (
            <>
              <button
                className="popup__button-close"
                type="button"
                aria-label="Закрыть модальное окно"
                onClick={handleClose}
              />
              <div className="popup__header">
                <p className="popup__header-title">{title}</p>
                {subtitle && (
                  <p className="popup__header-subtitle">{subtitle}</p>
                )}
              </div>
            </>
          )}
          {children}
        </Plate>
      </div>
    );
  }
);
