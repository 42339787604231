import { Fragment } from 'react';
import { Route, Routes } from 'react-router-dom';

import Main from 'pages/Main/Main';
import Market from 'pages/markets/Market/Market';

import { Projects } from 'pages/Projects/Projects';
import Footer from './Footer/Footer';
import Blog from 'pages/Blog/Blog';
import Article from 'pages/Blog/Article/Article';
import NotFoundPages from 'pages/NotFoundPages/NotFoundPages';

import articlesData from 'pages/Blog/Article/articlesData';

import AppStore from 'AppStore';

import './AppWithFooter.scss';

export default function AppWithFooter() {
  return (
    <div className='app-with-footer'>
      <div className='app-with-footer__content'>
        <Routes>
          <Route path='/' element={<Main />} />

          {/* Create routes for all calculators */}
          {AppStore.marketStores.map(marketStore => (
            <Fragment key={marketStore}>
              {marketStore.calculators.map(calculator => (
                <Route key={calculator} path={calculator.mode.path} element={<Market />} />
              ))}
            </Fragment>
          ))}

          <Route path='/blog' element={<Blog />} />
          {articlesData.map((article, index) => (
            <Route
              key={index}
              path={`/blog/${article.id}`}
              element={<Article id={article.id} />}
            />
          ))}
          {AppStore.isAuth && (
            <Route path='/projects'>
              <Route index element={<Projects />} />
            </Route>
          )}

          <Route path='*' element={<NotFoundPages />} />
        </Routes>
      </div>

      <Footer />
    </div>
  );
}
