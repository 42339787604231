import { observer } from "mobx-react";
import { Popup } from "components/Popup/Popup";

import AppStore from "AppStore";

import "./FullScreenshotModal.scss";

const FullScreenshotModal = observer(() => {
  const screenshot = `data:image/jpeg;base64,${AppStore.projectScreenshot}`;

  return (
    <Popup modalType="project-full-screenshot">
      <img src={screenshot} alt="Full screenshot" />
    </Popup>
  );
});

export default FullScreenshotModal;
