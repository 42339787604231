import { observer } from "mobx-react";
import { useState } from "react";
import { Link } from "react-router-dom";

import {
  TelegramShareButton,
  VKShareButton,
  WhatsappShareButton,
} from "react-share";

import "./Navigation.scss";

const Navigation = observer(({ store, links }) => {
  const [activeLink, setActiveLink] = useState(null)
  const headerHeight = 100;
  const currentPageUrl = window.location.href;

  const scrollToElement = (id, headerHeight) => {
    const element = document.getElementById(id);
    if (element) {
      const offsetTop =
        element.getBoundingClientRect().top + window.pageYOffset;
      window.scrollTo({
        top: offsetTop - headerHeight,
        behavior: "smooth",
      });
    }
  };

  const handleClick = (id, linkIndex) => {
    setActiveLink(linkIndex);
    scrollToElement(id, headerHeight);
  };

  return (
    <article className="navigation">
      <h3 className="navigation__title">Содержание</h3>
      <nav className="navigation__nav">
        {links.map((link, index) => (
          <Link
            key={index}
            className={`navigation__nav-link ${
              activeLink === index ? "active" : ""
            }`}
            to={`#${link.to}`}
            onClick={() => handleClick(link.to, index)}
          >
            {link.text}
          </Link>
        ))}
      </nav>
      <div className="navigation__share">
        <p className="navigation__share-text">
          Поделитесь с коллегами или сохраните для себя:
        </p>
        <div className="navigation__share-container">
          <VKShareButton
            url={currentPageUrl}
            title="Посмотри интересную статью"
            className="navigation__share-container-button-vk"
          />
          <WhatsappShareButton
            url={currentPageUrl}
            title="Посмотри интересную статью"
            separator=":: "
            className="navigation__share-container-button-wa"
          />
          <TelegramShareButton
            url={currentPageUrl}
            title="Посмотри интересную статью"
            className="navigation__share-container-button-tg"
          />
        </div>
      </div>
    </article>
  );
});

export default Navigation;
