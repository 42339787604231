export const FAQ_IN_PROJECT = [
  {
    question: "Мою задачу не хотят брать более 3 дней. Что делать?",
    answers: [
      {
        title: "Мою задачу не хотят брать более 3 дней. Почему?",
        text: "Мы — биржа. Исполнители сами принимают решение брать задачу или нет. Чтобы вашу задачу взяли, она должна быть привлекательна и понятна для исполнителей",
      },
      {
        title: "Почему исполнители не хотят брать мою задачу?",
        text: [
          "Причин может быть несколько: ",
          <br />,
          "a) ",
          <b>задача невыгодна для исполнителей</b>,
          <br />,
          <i>
            (<b>Например:</b> Задача сложная, а цена при этом ниже рынка — за
            такие задачи почти не берутся)
          </i>,
          <br />,
          "б) ",
          <b>при заполнении задачи допущена ошибка</b>,
          <br />,
          <i>
            (<b>Например:</b> Исполнители по-просту не могут найти обявление →
            не могут приступить к работе)
          </i>,
        ],
      },
      {
        title: "Что делать, чтобы мою задачу взяли?",
        text: [
          "Действуйте поэтапно, и все получится:",
          <ol>
            <li>
              <b>Исключите возможность ошибки</b>
              <br />
              Для этого проверьте активность вашего объявления, которое
              вы оставляли в задании.{" "}
              При необходимости замените скриншот или ссылку в настройках
            </li>
            <li>
              <b>Добавьте ссылку на объявление  в дополнение к скриншоту</b>
              <br />
              Задания с ссылками исполнители берут быстрее. Чтобы задачу взяли в работу как можно скорее, вы можете добавить ссылку на объявление в настройках
            </li>
            <li>
              <b>Поднимите стоимость работ исполнителей</b>
            </li>
          </ol>,
        ],
      },
    ],
  },
  {
    question: "Зачем поднимать цену?",
    answers: [
      {
        title: "Зачем поднимать цену?",
        text: "Мы рекомендуем поднимать цену, если задачу не берут более 3 дней. Подняв размер вознаграждения, вы поднимаете экономическую привлекательность вашей задачи для исполнителей — они получат больше денег, значит, они готовы взяться за более сложное или долгое задание.",
      },
      {
        title: "На сколько нужно поднять стоимость?",
        text: [
          "Вы поднимаете стоимость на свое усмотрение. ",
          <b>В меню задачи вы можете быстро поднять цену на 50₽ и 100₽.</b>,
          " Как правило, этого достаточно, чтобы исполнители согласились на работу.",
        ],
      },
    ],
    important: [
      {
        title: [
          <strong>ВАЖНО! </strong>,
          "Перед поднятием цены мы рекомендуем проверить актуальность объявления:",
        ],
        text: [
          <ol>
            <li>
              <b>Проверьте активность вашего объявления, </b>
              которое вы оставляли в задании. При необходимости замените скриншот или ссылку.
            </li>
            <li>
              <b>Добавьте ссылку на объявление в дополнение к скриншоту</b>
              <br />
              Задания с ссылками исполнители берут быстрее. Чтобы задачу взяли в
              работу как можно скорее, вы можете добавить ссылку на объявление
            </li>
          </ol>,
        ],
      },
    ],
  },
  {
    question: "Что, если продолжить без изменений?",
    answers: [
      {
        title: "Что, если продолжить без изменений?",
        text: [
          "Вы можете оставить цену без изменений. Ваша задача, может быть, еще найдет своего исполнителя.",
          <br />,
          <i>
            Однако, статистика сервиса показывает, что со временем шанс того,
            что задачу возьмут в работу, снижается: она уходит «вниз»
          </i>,
        ],
      },
      {
        title: "То есть она будет бесконечно «висеть»?",
        text: [
          "Нет, если задачу так и не возьмут по истечение 15 дней после публикации, сервис ",
          <b>автоматически отменяет задачу и переносит ее в архив.</b>,
          <br />,
          <ul>
            <li>
              Деньги останутся на вашем счете, вы сможете перезапустить задачу в
              любое время.
            </li>
          </ul>,
        ],
      },
    ],
    important: [
      {
        title: [
          <strong>ВАЖНО! </strong>,
          "Перед перезапуском мы рекомендуем проверить актуальность объявления:",
        ],
        text: [
          <ol>
            <li>
              <b>Проверьте активность вашего объявления, </b>
              которое вы оставляли в задании. При необходимости замените скриншот или ссылку
            </li>
            <li>
              <b> Добавьте ссылку на объявление в дополнение к скриншоту</b>
              <br />
              Задания с ссылками исполнители берут быстрее. Чтобы задачу взяли в
              работу как можно скорее, вы можете добавить ссылку на объявление
            </li>
            <li>
              <b> Установите цену больше. </b>
              Возможно задача слишком сложная или долгая, а цена низкая — за
              такие задачи почти не берутся
            </li>
          </ol>,
        ],
      },
    ],
  },
];

export const FAQ_IN_REPORT = [
  {
    question: "Советы по модерации",
    answers: [
      {
        title: "Совет 1. Проверяйте внимательно",
        text: "Обращайте внимание на тип активности в шапке отчета и внимательно проверяйте ответы исполнителя, прежде чем подтвержать отчет. До подтверждения деньги заморожены на вашем счете, однако после принятия задания деньги будут переведены исполнителю и вернуть их будет невозможно",
      },
      {
        title: "Совет 2. Не торопитесь",
        text: "Нет обязательства принимать задания немедленно: можете подождать, чтобы удостовериться, что результаты работы не пропадут. Однако обратите внимание, что у каждой задачи есть таймер, по истечению которого задача будет принята автоматически, деньги будут переведены исполнителю и вернуть их будет невозможно",
      },
      {
        title: "Совет 3. Мы готовы помочь",
        text: "В случае затруднений с модерацией вы можете найти ответы в этом разделе. Если здесь нет ответов на ваши вопросы, обратитесь за советом в техподдержку",
      },
    ],
  },
  {
    question: "Ответы неверные. Что делать?",
    answers: [
      {
        title: "Ответы неверные. Что делать?",
        text: "Отклоните отчет, нажав красную кнопку «Отклонить». Появится поле, в котором вы можете оставить комментарий исполнителю: что вас не устроило и что требует доработки, отправить на доработку или сменить исполнителя",
      },
      {
        title: "А что потом? Когда мое задание будет выполнено правильно?",
        text: "Задача с комментарием отправляется исполнителю. Прежний таймер перезапускается. Спустя время вам на проверку придет новый отчет. Мы не можем гарантировать, что исполнитель сделает все верно, ведь мы не агентство, а сервис микрозадач и выступаем посредником между вами и исполнителем. Однако мы делаем все возможное, чтобы улучшить ваш опыт работы с сервисом",
      },
      {
        title: "Что вы предпринимаете для улучшений?",
        text: "Во-первых, исполнитель получает деньги только после того, как вы одобрите отчет — так он мотивирован выполнять задачи добросовестно. Во-вторых, вы можете сменить исполнителя в любое время, прошлый денег не получит. В-третьих, недобросовестный исполнитель понижается в рейтинге и больше вам не попадется",
      },
    ],
  },
  {
    question: "Почему не используете скрины?",
    answers: [
      {
        title:
          "Почему BOOSTCLICK не использует скриншоты для проверки исполнителей?",
        text: "Платформы вроде Avito и OZON не поощряют накрутки. Они могут фиксировать, когда пользователь делает скриншот",
      },
      {
        title: "Почему опасно делать много скриншотов объявления?",
        text: "Массовое создание скриншотов может быть воспринято как факт накрутки, что может привести к блокировке объявлений или аккаунта",
      },
      {
        title: "Как в таком случае осуществляется проверка?",
        text: "Вы уже могли заметить, что мы просим проверить ответы исполнителей. Для проверки качества выполнения работы мы используем проверочные вопросы, ответить на которые можно только, зайдя на объявление и выполнив необходимые действия",
      },
    ],
  },
  {
    question: "Подтверждение недоступно. Почему?",
    answers: [
      {
        title: "Подтверждение недоступно. Почему?",
        text: "Мы ограничили принятие заданий от исполнителей, пока после публикации не пройдет 48 часов. 90% отзывов модерация удаляет как раз в первые 48 часов",
      },
      {
        title: "Когда я могу подтвердить отчет?",
        text: "Вы сможете подтвердить отчет, когда пройдет 48 часов с момента публикации. Если отзыв не удалят за указанное время, то вы можете принять и оплатить работу",
      },
      {
        title: "Зачем нужно это ограничение?",
        text: "Таким образом мы можем гарантировать, что отзывы минуют модерацию и вы не потеряете деньги",
      },
    ],
  },
];
