import { makeAutoObservable, runInAction } from 'mobx';

const THIRTY_MINUTES_IN_MS = 30 * 60 * 1000;

const stores = [];

setInterval(() => {
  runInAction(() => {
    for (const store of stores) {
      store.timeLeft -= 1000;
      store.isRedColor = store.timeLeft <= THIRTY_MINUTES_IN_MS;
    }
  });
}, 1000);

class ReportTimerStore {
  timeLeft = 0;
  isRedColor = false;

  constructor(acceptDatetime) {
    makeAutoObservable(this);
    this.startCountdown(acceptDatetime);
  }

  startCountdown(acceptDatetime) {
    const initialTimeLeft = new Date(acceptDatetime) - Date.now();

    runInAction(() => {
      this.timeLeft = initialTimeLeft > 0 ? initialTimeLeft : 0;
      this.isRedColor = this.timeLeft <= THIRTY_MINUTES_IN_MS;
      stores.push(this);
    });
  }

  restartCountdown(acceptDatetime) {
    this.stopCountdown();
    this.startCountdown(acceptDatetime);
  }

  stopCountdown() {
    runInAction(() => {
      this.timeLeft = 0;
      this.isRedColor = false;
      stores.splice(stores.indexOf(this), 1);
    });
  }
}

export default ReportTimerStore;
