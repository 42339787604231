import { Link } from "react-router-dom";

import Button from "components/UI/Button/Button";

import { useMatchMedia } from "utils/hooks/use-match-media";

import "./FormLayout.scss";

const FormLayout = ({
  children,
  currentStep,
  onBack,
  onNext,
  isNextDisabled,
}) => {
  const { isMobile } = useMatchMedia();

  const steps = [
    { title: "Укажите сумму" },
    { title: "Укажите реквизиты" },
    { title: "Укажите услуги" },
  ];

  const buttonTexts = {
    back: ["Отмена", "Назад", "Назад", "Изменить"],
    next: [
      "Указать реквизиты",
      "Указать услуги",
      "Проверить данные",
      "Сформировать счет",
    ],
  };

  const getStepClass = (index) => {
    if (currentStep > index + 1) return "done";
    if (currentStep === index + 1) return "active";
    return "";
  };

  const getTextClass = (index) => {
    if (currentStep === index + 1) return "current";
    return "inactive";
  };

  return (
    <>
      <div className="form-layout">
        <div className="form-layout__container">
          <div className="form-layout__header">
            <h1 className="form-layout__header-title">
              Оплата для юридических лиц
            </h1>
            <div className="form-layout__steps">
              {steps.map((step, index) => (
                <div key={index} className={`form-layout__steps-${index}`}>
                  <div
                    className={`form-layout__steps-indicator form-layout__steps-${
                      index + 1
                    } ${getStepClass(index)}`}
                  />
                  <div className="form-layout__step">
                    {currentStep > index + 1 && (
                      <div className="form-layout__step-icon" />
                    )}
                    <p className={getTextClass(index)}>{step.title}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>

          <div className="form-layout__content">{children}</div>

          <div className="form-layout__buttons">
            <div className="form-layout__buttons-container">
              <Button
                title={buttonTexts.back[currentStep - 1]}
                className="form-layout__button-cancel"
                onClick={onBack}
              />
              <Button
                title={buttonTexts.next[currentStep - 1]}
                className="form-layout__button-ok"
                onClick={onNext}
                disabled={isNextDisabled}
              />
            </div>

            {isMobile ? (
              <></>
            ) : (
              <p>
                Оплата производится в соответствии с
                <Link to="/docs/offer.pdf"> договором оферты</Link>
              </p>
            )}
          </div>
        </div>
      </div>

      {isMobile ? (
        <p className="form-layout-mobile">
          Оплата производится в соответствии с
          <br />
          <Link to="/docs/offer.pdf"> договором оферты</Link>
        </p>
      ) : (
        <></>
      )}
    </>
  );
};

export default FormLayout;
