import { observer } from "mobx-react";

import { ButtonWithIcon } from "components/UI/ButtonWithIcon/ButtonWithIcon";

import cancelIcon from 'assets/images/tagclosered.svg';

import "./ProjectsActiveReportAuctionCancel.scss";

const ProjectsActiveReportAuctionCancel = observer(({ store, report }) => {
  return (
    <div className="projects-active-report-auction-cancel">
      <div className="projects-active-report-auction-cancel__warn">
        <div className="projects-active-report-auction-cancel__warn-icon" />
        <p>Исполнители не берут или откзываются от задания &#8805; 3-х дней</p>
      </div>
      <ButtonWithIcon
        title="Отменить задачу и вернуть деньги"
        className="projects-active-report-auction-cancel__button"
        icon={cancelIcon}
        onClick={() => store.cancelTaskWithConfirm(report)}
        ariaLabel="Отменить задачу"
      />
    </div>
  );
});

export default ProjectsActiveReportAuctionCancel;
