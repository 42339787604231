import { useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react';

import SelectTag from './SelectTag/SelectTag';

import './CategoryInput.scss';

export const CategoryInput = observer(({ store, error }) => {

  const itemsContainer = useRef();

  const [isExpanded, setIsExpanded] = useState(false);
  const [itemsContainerStyle, setItemsContainerStyle] = useState();

  useEffect(() => {
    setItemsContainerStyle({ height: itemsContainer.current.scrollHeight });
  }, [store]);

  return (
    <div
      className={'avito-category-input ' + (isExpanded ? 'avito-category-input_expanded' : '')}
      tabIndex={0}
      onClick={() => { setIsExpanded(!isExpanded) }}
      onBlur={() => { setIsExpanded(false) }}
    >
      <div className={`avito-category-input__title-container ${error ? 'error' : ''}`}>
        <div className='avito-category-input__title' style={{
            opacity: store.currentCategoryIndex !== undefined 
              ? 1 
              : 0.35 
            }}>
          {store.currentCategoryIndex !== undefined
            ? <SelectTag title={store.market.categories[store.currentCategoryIndex].type} onDelete={event => { event.stopPropagation(); store.selectCategoryByIndex(); }} />
            : 'Выберите категорию из списка'
          }
        </div>
      </div>

      <div ref={itemsContainer} className='avito-category-input__items-container' style={isExpanded ? itemsContainerStyle : {}}>
        {store.market.categories.map((category, i) =>
          <div key={i} className='avito-category-input__item' onClick={() => { store.selectCategoryByIndex(i) }}>{category.type}</div>
        )}
      </div>
    </div>
  );
});
