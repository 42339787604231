import { observer } from "mobx-react";

import { Popup } from "components/Popup/Popup";
import popupStore from "components/Popup/PopupStore";
import Button from "../Button/Button.jsx";

import "./SureModal.scss";

export const SureModal = observer(() => {
  function cancel() {
    popupStore.cancel();
    popupStore.close();
  }

  function ok() {
    popupStore.ok();
    popupStore.close();
  }

  return (
    <Popup modalType="suremodal">
      <p className="suremodal__text">{popupStore.text}</p>
      <div className="suremodal__buttons">
        <Button
          title="Отмена"
          classes={["outlined", "small"]}
          onClick={cancel}
        />
        <Button title="OK" classes={["small"]} onClick={ok} />
      </div>
    </Popup>
  );
});
