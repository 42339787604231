import { ButtonWithIcon } from "components/UI/ButtonWithIcon/ButtonWithIcon";

import { EVENTS, metricsEvent } from "metrics";

import plusIcon from "assets/images/plus.svg";

import "./ProjectsPlate.scss";

export default function ProjectsPlate({ title, comment, children }) {

  const handleReloadHomePage = () => {
    metricsEvent(EVENTS.PROJECT_ADD);
  };

  return (
    <div className="projects-plate">
      <div className="projects-plate__header">
        <h2 className="projects-plate__header-title">{title}</h2>
        {comment && <p className="projects-plate__header-comment">{comment}</p>}
        <ButtonWithIcon
          title="Создать новый проект"
          icon={plusIcon}
          onClick={handleReloadHomePage}
          ariaLabel="Создать новый проект"
          to="/"
          target="_self"
        />
      </div>

      <div>{children}</div>
    </div>
  );
}
