import { makeAutoObservable } from "mobx";
import articlesData from "./Article/articlesData";

class BlogStore {
  articles = articlesData;
  currentPage = 1;
  totalPages = undefined;
  itemsPerPage = 8;
  selectedType = null;
  selectedCategory = null;
  isLoading = false;
  showButton = false;

  constructor() {
    makeAutoObservable(this);
  }

  get currentArticles() {
    const startIndex = (this.currentPage - 1) * this.itemsPerPage;
    const endIndex = startIndex + this.itemsPerPage;
    return this.filterArticles().slice(startIndex, endIndex);
  }

  setIsLoading(value) {
    this.isLoading = value;
  }

  setShowButton(value) {
    this.showButton = value;
  }

  hasMoreArticles() {
    return this.currentPage * this.itemsPerPage < this.filterArticles().length;
  }

  loadMoreArticles() {
    this.currentPage++;
    this.filterArticles();
  }

  filterArticles() {
    let filteredArticles = this.articles;
    if (this.selectedType) {
      filteredArticles = filteredArticles.filter(
        (article) => article.type === this.selectedType
      );
    }
    if (this.selectedCategory) {
      filteredArticles = filteredArticles.filter(
        (article) => article.category === this.selectedCategory
      );
    }
    this.totalPages = Math.ceil(filteredArticles.length / this.itemsPerPage);
    return filteredArticles;
  }

  filterType(type) {
    this.selectedType = type === this.selectedType ? null : type;
  }

  filterCategory(category) {
    this.selectedCategory = category === this.selectedCategory ? null : category;
  }

  resetFilterCategory(category) {
    this.selectedCategory = category;
  }

  addMoreArticles() {
    this.itemsPerPage += 6;
  }
}

const blogStore = new BlogStore();
export default blogStore;