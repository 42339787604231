import { observer } from "mobx-react";
import { Link } from "react-router-dom";

import { Popup } from "components/Popup/Popup";
import Button from "components/UI/Button/Button";

import { EVENTS, metricsEvent } from "metrics";
import screen from "assets/images/instructionsscreen/screen.png";
import windowsIcon from "assets/images/instructionsscreen/windows.svg";
import windowsActiveIcon from "assets/images/instructionsscreen/windows-active.svg";
import macIcon from "assets/images/instructionsscreen/mac.svg";

import "./InstructionsScreenPopup.scss";
import { ButtonWithIcon } from "components/UI/ButtonWithIcon/ButtonWithIcon";

const InstructionsScreenPopup = observer(({ marketStore, popupStore }) => {
  const handleOSChange = (os) => {
    marketStore.setSelectedOS(os);
  };

  return (
    <Popup modalType="instructions-screen" header={true}>
      <div className="instructions-screen__left">
        <div className="instructions-screen__left-header">
          <p className="instructions-screen__left-header-title">
            Как делать скриншот?
          </p>
          <p className="instructions-screen__left-header-text">
            Скриншот — это снимок вашего экрана. Выберите ниже вашу операционную
            систему и следуйте инструкциям
          </p>
        </div>

        <div className="instructions-screen__left-os">
          <ButtonWithIcon 
            title="Windows"
            className={`instructions-screen__left-button-windows ${
              marketStore.windowsOS ? "selected" : ""
            }`}
            icon={marketStore.windowsOS ? windowsActiveIcon : windowsIcon}
            onClick={() => handleOSChange("Windows")}
            ariaLabel="Открыть инструкцию для Windows"
          />
          <ButtonWithIcon 
            title="MacOS"
            className={`instructions-screen__left-button-mac ${
              !marketStore.windowsOS ? "selected" : ""
            }`}
            icon={macIcon}
            onClick={() => handleOSChange("Mac")}
            ariaLabel="Открыть инструкцию для Mac"
          />
        </div>

        {
          <div className="instructions-screen__left-instruction">
            <div>
              <span>Весь экран:</span>
              <br />
              {marketStore.windowsOS ? (
                <>
                  <p>На клавиатуре нажмите PrtScn (Print Screen)</p>
                  <p>
                    <span>Только активное окно: </span>
                    Нажмите Alt + PrtScn
                    <br />
                    <br />
                    Вам будет предложено выбрать место сохранения
                    <br />
                    <span>или</span>
                    <br />
                    Снимок сохранится автоматически в папке «Изображения» →
                    «Снимки экрана»
                  </p>
                </>
              ) : (
                <>
                  <p>На клавиатуре нажмите Shift + Command (⌘) + 3</p>
                  <p>
                    <span>Выбрать область: </span>
                    Нажмите Shift + Command (⌘) + 4 и выберите область для
                    скриншота
                  </p>
                  <br />
                  <p>По умолчанию скриншоты сохраняются на Рабочий стол</p>
                </>
              )}
            </div>
          </div>
        }

        <div className="instructions-screen__left-buttons">
          <Link
            className="instructions-screen__left-support-link"
            to={process.env.REACT_APP_SUPPORT_ADDRESS}
            target="_blank"
            onClick={() => { metricsEvent(EVENTS.TELEGRAM_HELP) }}
          >
            Связаться с поддержкой
          </Link>
          <Button
            title="Все понятно!"
            onClick={() => {
              marketStore.setInstructionsScreen(false);
              if (marketStore.projectSetup) {
                popupStore.open("project-setup");
              }
            }}
          />
        </div>
      </div>
      <div className="instructions-screen__right">
        <div
          className="instructions-screen__right-close-icon"
          onClick={() => popupStore.close()}
        />
        <div className="instructions-screen__right-instruction">
          {marketStore.windowsOS ? (
            <div className="instructions-screen__right-combination-windows" />
          ) : (
            <div className="instructions-screen__right-combination-mac" />
          )}
          <img
            className="instructions-screen__right-screen"
            src={screen}
            alt="Пример скрина"
          />
          <ul className="instructions-screen__right-list">
            <li>Название товара</li>
            <li>Фотография товара</li>
            <li>Название бренда</li>
          </ul>
        </div>
      </div>
    </Popup>
  );
});

export default InstructionsScreenPopup;
