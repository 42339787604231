import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";

import Button from "components/UI/Button/Button";

import "./NotificationLine.scss";

const NotificationLine = observer(({ store }) => {
  const navigate = useNavigate();

  if (!store.notification) return null;

  const handleClose = () => {
    store.clearNotification();
  };

  return (
    <div className={`notification-line notification-line__${store.notification.status}`}>
      <div className="notification-line__inner">
        {store.notification.status === "failed" ? (
          <div className="notification-line__failed-container">
            <p className="notification-line__failed-message">{store.notification.message}</p>
            <Button
              className="notification-line__failed-button-close"
              type="button"
              aria-label="Закрыть уведомление"
              onClick={handleClose}
            />
          </div>
        ) : (
          <div className="notification-line__update-container">
            <div className="notification-line__update-message">
              <p className="notification-line__update-message-title">Обновление</p>
              <p className="notification-line__update-message-text">{store.notification.message}</p>
            </div>
            <div className="notification-line__update-buttons">
            <Button
                title="Попробовать"
                className="notification-line__update-button-do"
                type="button"
                aria-label="Посмотреть обновление"
                onClick={() => {
                  navigate(store.notification.actionLink);
                  handleClose();
                }}
              />
              <Button
                title="Позже"
                className="notification-line__update-button-close"
                type="button"
                aria-label="Скрыть уведомление"
                onClick={handleClose}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
});

export default NotificationLine;
