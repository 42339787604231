import classNames from "classnames";
import { useEffect, useRef, useState } from "react";

import { Hint } from "../Hint/Hint";
import Tooltips from "../Tooltips/Tooltips";

import {
  TITLE_FOR_MIN_ACTIONS,
  DESCRIPTION_FOR_MIN_ACTIONS,
  TITLE_FOR_MAX_ACTIONS,
  DESCRIPTION_FOR_MAX_ACTIONS,
  TITLE_FOR_NOTIFICATION_ACTIONS,
  DESCRIPTION_FOR_NOTIFICATION_ACTIONS,
} from "./spinBoxConstants";

import "./SpinBox.scss";

function conditionalTooltips(description, block) {
  if (description) {
    return <Tooltips description={description}>
      {block}
    </Tooltips>;
  }
  return block;
}

export default function SpinBox({
  store,
  activity,
  count,
  editable = true,
  manual = false,
  onDown,
  onUp,
  onChange,
  dis,
  showNotificationReviewAi,
}) {
  const hintRef = useRef();
  const [showModal, setShowModal] = useState(null);
  const [showNotification, setShowNotification] = useState(false);

  const downClassName =
    "spin-box__control spin-box__control_down" +
    (onDown && !dis
      ? " spin-box__control_enabled"
      : " spin-box__control_disabled");
  const upClassName =
    "spin-box__control spin-box__control_up" +
    (onUp ? " spin-box__control_enabled" : "");

  const handleChange = (e) => {
    const newValue = parseInt(e.target.value);
    if (!isNaN(newValue) && newValue >= 0) {
      onChange(newValue);
    }
  };

  const handleDown = () => {
    if (onDown && count > 0 && !dis) {
      onDown();
    } else if (count > 0 && dis) {
      setShowModal("min");
    }
  };

  const handleUp = () => {
    if (!onUp) return;
    if (!activity.canAdd) {
      setShowModal("max");
      return;
    }
    if (showNotificationReviewAi && count === 1) setShowNotification(true);

    onUp();
  };

  const renderHint = (condition) => {
    let title = "";
    let text = "";

    if (condition === "min") {
      title = TITLE_FOR_MIN_ACTIONS;
      text = DESCRIPTION_FOR_MIN_ACTIONS;
    } else if (condition === "max") {
      title = TITLE_FOR_MAX_ACTIONS;
      text = DESCRIPTION_FOR_MAX_ACTIONS;
    } else if (condition === "notification-review") {
      title = TITLE_FOR_NOTIFICATION_ACTIONS;
      text = DESCRIPTION_FOR_NOTIFICATION_ACTIONS;
    }

    return (
      <div ref={hintRef}>
        <Hint
          className="hint-spin-box"
          title={title}
          text={text}
          buttonText="Ок, понятно"
          onClick={() => {
            setShowModal(null);
            setShowNotification(false);
          }}
        />
      </div>
    );
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (hintRef.current && !hintRef.current.contains(event.target)) {
        setShowModal(false);
        setShowNotification(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [hintRef]);

  return (
    <div
      className={`spin-box-container ${
        activity.price ? "first-container" : ""
      }`}
    >
      {activity.price && (
        <div className="spin-box-special">
          <div className="spin-box-special__price">
            {conditionalTooltips(activity.subactionsComment,
              <>
                {(activity.subactions ?? 1) > 1
                  ? Array(activity.subactions).fill(activity.price / activity.subactions + '\u00A0₽').join(' + ')
                  : activity.price + '\u00A0₽'
                }
                {activity.itemTitle && <span> / {activity.itemTitle}</span>}
              </>
            )}
          </div>
        </div>
      )}
      <div className="spin-box">
        {editable && (
          <>
            <div
              className={
                downClassName + (count > 0 ? "" : " spin-box__control_disabled")
              }
              onClick={handleDown}
            />
            {showModal === "min" && renderHint("min")}
          </>
        )}
        <input
          className={`spin-box__counter
          ${count === 0 ? "spin-box__control_disabled" : ""}`}
          type="number"
          readOnly={!manual}
          value={count}
          onChange={handleChange}
        />
        {editable && (
          <>
            <div
              className={classNames(upClassName, activity.canAdd ? "" : "spin-box__control_disabled_up")}
              onClick={handleUp}
            />
            {showModal === "max" && renderHint("max")}
            {showNotification && renderHint("notification-review")}
          </>
        )}
      </div>
    </div>
  );
}
