import { observer } from "mobx-react";
import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useInView } from "react-intersection-observer";
import SortMenu from "./SortMenu/SortMenu";
import ListArticles from "./ListArticles/ListArticles";
import SkeletonArticle from "./ListArticles/SkeletonArticle/SkeletonArticle";

import blogStore from "./BlogStore";
import { BLOG_TITLE, BLOG_SUBTITLE, NO_ARTICLES } from "./blogConstants";

import "./Blog.scss";

const Blog = observer(() => {
  const { ref, inView } = useInView({ threshold: 1 });

  useEffect(() => {
    window.scroll(0, 0);
    blogStore.filterArticles();
  }, []);

  useEffect(() => {
    if (inView && blogStore.hasMoreArticles() && !blogStore.isLoading) {
      blogStore.setIsLoading(true);
      setTimeout(() => {
        blogStore.addMoreArticles();
        blogStore.setIsLoading(false);
      }, 1500);
    }
  }, [inView]);

  useEffect(() => {
    if (!blogStore.showButton && inView) {
      blogStore.setShowButton(true);
    }
  }, [inView]);

  const currentArticles = blogStore.currentArticles;

  const handleScrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <main className="blog">
      <Helmet>
        <title>Полезная информация сервиса Boostclick</title>
        <meta name="description" content="Читайте гайды, новости, документацию и свежие обновления сервиса Boostclick. Не нашли решения своего вопроса? Напишите нам в поддержку." />
        <meta name="viewport" content="initial-scale=0" />
      </Helmet>

      <div className="blog__header">
        <h1 className="blog__header-title">{BLOG_TITLE}</h1>
        <p className="blog__header-subtitle">{BLOG_SUBTITLE}</p>
      </div>
      <SortMenu store={blogStore} />
      <div className="blog__articles">
        {currentArticles.length === 0 && (
          <div className="blog__articles-empty">{NO_ARTICLES}</div>
        )}
        {currentArticles.map((article, index) => (
          <ListArticles key={index} article={article} isFirst={index === 0} />
        ))}
        {blogStore.hasMoreArticles() &&
          Array.from({ length: 6 }).map((_, index) => (
            <div className="skeleton-wrapper" ref={ref}>
              <SkeletonArticle key={index} />
            </div>
          ))}
      </div>

      {blogStore.showButton && (
        <button className="blog__button" onClick={handleScrollToTop} />
      )}
    </main>
  );
});

export default Blog;
