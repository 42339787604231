import classNames from 'classnames';

import './Plate.scss';

export default function Plate({ style, className, children }) {
  return (
    <div className={classNames('plate-article', className)} style={style}>
      {children}
    </div>
  );
}