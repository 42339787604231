import "./StatisticsSection.scss";

const StatisticsSection = () => {
  return (
    <section className="statistics">
      <div>
        Завершено
        <div className="statistics__count">
          <span>4</span>
          <span>9</span>
          <span>2</span>          
          <span>1</span>
          <span>0</span>
          <span>0</span>
        </div>
        заданий от 4k пользователей биржы
        <br />
        Boostclick
      </div>
    </section>
  );
};

export default StatisticsSection;
