import './FooterButton.scss';

export function FooterButton({ to, title, icon, onClick }) {
  const iconStyle = {
    background: icon ? `center/contain url(${icon}) no-repeat` : undefined
  };

  return (
    <a href={to} target="_blank" rel="noopener noreferrer">
      <button className={'footer-button' + (icon ? ' footer-button_iconed' : '')} onClick={onClick}>
        {icon && <span className='footer-button__icon' style={iconStyle}></span>}
        {title}
      </button>
    </a>
  );
}
