import { Link } from "react-router-dom";

import "./ButtonWithIcon.scss";

export function ButtonWithIcon({
  title,
  icon,
  ariaLabel,
  onClick,
  disabled,
  className,
  to,
  target = "_blank",
}) {
  const iconStyle = {
    background: icon ? `url(${icon}) center/contain no-repeat` : undefined,
  };

  return (
    <>
      {to ? (
        <Link
          className="button-with-icon__link"
          to={to}
          target={target}
          onClick={onClick}
          rel="noopener noreferrer"
        >
          <button
            className={`${icon ? "button-with-icon" : ""} ${className || ""}`}
            type="button"
            aria-label={ariaLabel}
            disabled={disabled}
          >
            {icon && (
              <div className="button-with-icon__icon" style={iconStyle} />
            )}
            {title}
          </button>
        </Link>
      ) : (
        <button
          className={`${icon ? "button-with-icon" : ""} ${className || ""}`}
          type="button"
          aria-label={ariaLabel}
          onClick={onClick}
          disabled={disabled}
        >
          {icon && <div className="button-with-icon__icon" style={iconStyle} />}
          {title}
        </button>
      )}
    </>
  );
}
