import { useState } from "react";
import { observer } from "mobx-react";

import FoldableSection from "components/UI/FoldableSection/FoldableSection";
import Tooltips from "components/UI/Tooltips/Tooltips";

import ProjectSetupModal from "pages/Projects/components/ProjectSetupModal/ProjectSetupModal";
import ProjectEditingTitle from "pages/Projects/components/ProjectSetupModal/ProjectEditingTitle/ProjectEditingTitle";
import ProjectsProgressIndicator from "../ProjectsProgressIndicator/ProjectsProgressIndicator";
import ProjectsActiveBlockWait from "./ProjectsActiveBlockWait/ProjectsActiveBlockWait";
import ProjectsActiveBlockError from "./ProjectsActiveBlockError/ProjectsActiveBlockError";
import { ProjectsActiveReportRow } from "./ProjectsActiveReportRow/ProjectsActiveReportRow";

import ProjectLinkButton from "pages/Projects/components/ProjectLinkButton/ProjectLinkButton";

import AppStore from "AppStore";

import {
  findActivityCount,
  getProjectReportsWithStatus,
  calcTotalProjectActions,
} from "../../../Projects/ProjectsConstants";

import { ProjectStatuses, ReportStatuses } from "client";

import "./ProjectsActiveRow.scss";

/**
 * @param {Object[]} activities
 * @returns {Object[]}
 */
function getActiveReportsToShow(reports) {
  if (!reports) return reports;

  const filtered = reports.filter(
    (report) => report.status !== ReportStatuses.NEW
  );

  const ready = filtered.filter(
    (report) => report.status === ReportStatuses.READY
  );
  const run = filtered.filter((report) => report.status === ReportStatuses.RUN);

  const other = filtered.filter(
    (report) =>
      ![
        ReportStatuses.READY,
        ReportStatuses.RUN,
        ReportStatuses.NEW,
        ReportStatuses.FINISHED,
      ].includes(report.status)
  );

  ready.sort((a, b) => new Date(a.acceptDatetime) - new Date(b.acceptDatetime));
  run.sort((a, b) => new Date(a.sentDatetime) - new Date(b.sentDatetime));
  other.sort(
    (a, b) => new Date(a.scheduledDatetime) - new Date(b.scheduledDatetime)
  );

  const sorted = [...ready, ...other, ...run];
  return sorted;
}

export const ProjectsActiveRow = observer(
  ({ project, initialExpanded = false }) => {
    const [expanded, setExpanded] = useState(initialExpanded);
    const [renderReports, setRenderReports] = useState(initialExpanded);

    function invertExpanded(e) {
      if (AppStore.editingTitle && AppStore.editingProjectId === project.id) return;
      if (e.target.closest(".project-editing-title__button-save, .projects-active-row__button-config")) return;

      if (expanded && AppStore.isSetupOpen && AppStore.openSetupProjectId === project.id) {
        setTimeout(() => {
          AppStore.setIsSetupOpen(false);
          AppStore.setOpenSetupProjectId(null);
          AppStore.clearUploadedFile('default');
          AppStore.startEditingNewLink(false);
        }, 500);
      }

      setExpanded(!expanded);
      if (!expanded)
        setRenderReports(true);
    }

    const status = project?.status;
    const activities = project?.activities;

    const actions = calcTotalProjectActions(project);
    const reviewsCount = findActivityCount(activities, "MAIN.REVIEW");
    const reportsToShow = getActiveReportsToShow(project.reports);
    const newReports = getProjectReportsWithStatus(project.reports, "new");
    const finish = getProjectReportsWithStatus(project.reports, "finished");
    finish.sort((a, b) => new Date(b.sentDatetime) - new Date(a.sentDatetime));

    const onTransitionEnd = () => {
      if (!expanded)
        setRenderReports(false);
    };

    return (
      <FoldableSection className="projects-active-row" expanded={expanded} onTransitionEnd={onTransitionEnd}>
        <div className="projects-active-row__title-container">
          <div className="projects-active-row__title-section" onClick={invertExpanded}>
            <div className={`projects-active-row__title-section-icon ${expanded ? "expanded" : ""}`} />
            {AppStore.editingTitle &&
              AppStore.editingProjectId === project.id ? (
              <ProjectEditingTitle project={project} />
            ) : (
              <Tooltips description={project.fullTitle}>
                <div className="projects-active-row__title">
                  {project.titleToShow}
                </div>
              </Tooltips>
            )}
            {AppStore.editingTitle &&
              AppStore.editingProjectId === project.id ? (
              <></>
            ) : (
              <button
                type="button"
                aria-label="Редактировать"
                className="projects-active-row__button-edit"
                onClick={() => {
                  AppStore.startEditingProjectTitle(project.id);
                  AppStore.setEditingTitle(true);
                  AppStore.setNewTitle(project.title);
                }}
              />
            )}
            <div className="projects-active-row__config">
              {!project.cancelling ?
                <Tooltips description="Раскройте проект и нажмите здесь для Настроек проекта" innerStyle={{ left: "45px" }}>
                  <button
                    type="button"
                    aria-label="Настройки проекта"
                    className="projects-active-row__button-config"
                    onClick={() => {
                      if (AppStore.isSetupOpen && AppStore.openSetupProjectId !== project.id) {
                        AppStore.setIsSetupOpen(false);
                        AppStore.setOpenSetupProjectId(null);
                        setTimeout(() => {
                          AppStore.clearUploadedFile('default');
                          AppStore.setIsSetupOpen(true);
                          AppStore.setOpenSetupProjectId(project.id);
                        }, 0);
                      } else {
                        AppStore.setIsSetupOpen(!AppStore.isSetupOpen);
                        AppStore.setOpenSetupProjectId(AppStore.isSetupOpen ? project.id : null);
                      }
                    }}
                  />
                </Tooltips>
                :
                <div className="projects-active-row__no-config" />
              }
            </div>
          </div>

          <div className="projects-active-row__info">
            <ProjectLinkButton style={{ visibility: project.link ? 'visible' : 'hidden' }} link={project.link} />
            {!project.cancelling ?
              <ProjectsProgressIndicator
                state={project.progress}
                status={status}
              />
              :
              <div className="projects-active-row__info-status">Процесс остановки</div>
            }
            <div className="projects-active-row__info-counter">
              <Tooltips description="Всего действий">
                <div className="projects-active-row__info-counter-activity-all">
                  <div className="projects-active-row__info-counter-activity-all-icon" />
                  {actions}
                </div>
              </Tooltips>
              <Tooltips description="Количество отзывов" innerStyle={{ left: "65px" }}>
                <div className="projects-active-row__info-counter-activity-reviews">
                  <div className="projects-active-row__info-counter-activity-reviews-icon" />
                  {reviewsCount}
                </div>
              </Tooltips>
            </div>
            <p className="projects-active-row__info-city">
              {project.city ? project.city : "——"}
            </p>
            <p className="projects-active-row__info-price">{project ? `${project.price.toLocaleString()} ₽` : ""}</p>
          </div>
          <div className="projects-active-row__buttons">
            <Tooltips description="При остановке проекта неизрасходованные деньги вернуться на ваш баланс.">
              <button
                type="button"
                aria-label="Остановка проекта"
                className="projects-active-row__stop-icon"
                onClick={() => {
                  AppStore.stopProjectWithConfirm(project);
                }}
              />
            </Tooltips>
          </div>
        </div>

        {renderReports && (
          <>
            {AppStore.isSetupOpen && AppStore.openSetupProjectId === project.id && (
              <ProjectSetupModal
                project={project}
                link={project.link}
                onClose={() => {
                  AppStore.setIsSetupOpen(false);
                  AppStore.setOpenSetupProjectId(null);
                  AppStore.startEditingNewLink(false);
                  AppStore.clearUploadedFile('default');
                }}
              />
            )}
            <div>
              {status === ProjectStatuses.FAILED && (
                <ProjectsActiveBlockError project={project} />
              )}

              {reportsToShow?.map((report, i) => (
                <ProjectsActiveReportRow
                  key={i}
                  project={project}
                  report={report}
                />
              ))}
              {newReports.length > 0 && <ProjectsActiveBlockWait isReviews={reviewsCount} />}

              {newReports.map((report, i) => (
                <ProjectsActiveReportRow
                  key={i}
                  project={project}
                  report={report}
                />
              ))}
              {finish.map((report, i) => (
                <ProjectsActiveReportRow
                  key={i}
                  project={project}
                  report={report}
                />
              ))}
            </div>
          </>
        )}
      </FoldableSection>
    );
  }
);
