import { Link } from "react-router-dom";

import FirstArticle from "./FirstArticle/FirstArticle";
import OtherArticle from "./OtherArticle/OtherArticle";

import "./ListArticles.scss";

export default function ListArticles({ article, isFirst }) {
  const { id, path, imageUrl } = article;
  const articleClass = isFirst ? "first-article" : "other-articles";

  const containerStyle = { backgroundImage: `url(${imageUrl})`,};
  if (isFirst) containerStyle.backgroundImage = `linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.95) 100%), ${containerStyle.backgroundImage}`;

  return (
    <Link key={id} to={`${id ? id : path}`} target={`${id ? "_self" : "_blank"}`} rel={id ? undefined : "noopener"} className={`articles ${articleClass}`}>
      <div className={`articles__container ${articleClass}__container`} style={containerStyle}>
        {isFirst && <FirstArticle article={article} />}
      </div>
      {!isFirst && <OtherArticle article={article} isExternal={!!path} />}
    </Link>
  );
}
