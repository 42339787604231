import { observer } from "mobx-react";

import { TextInput } from "components/UI/TextInput/TextInput";

import paymentFormStore from "../../PaymentFormStore";
import { getFormatPaymentAmount } from "utils/helpers";
import { useMatchMedia } from "utils/hooks/use-match-media";

import "./FormStep1.scss";

const FormStep1 = observer(() => {
  const { isMobile } = useMatchMedia();

  const handleInputChange = (e) => {
    const rawValue = e.target.value.replace(/[^\d]/g, "");
    paymentFormStore.setAmountOfPayment(rawValue);
  };

  const handleSumButtonClick = (amount) => {
    paymentFormStore.setAmountOfPayment(amount);
  };

  return (
    <div className="form-step1">
      <div className="form-step1__container">
        <label htmlFor="amountOfPayment">Введите сумму перевода</label>
        <TextInput
          id="amountOfPayment"
          type="text"
          inputmode="numeric"
          placeholder="Введите сумму от 5 000 рублей"
          maxLength={7}
          value={getFormatPaymentAmount(paymentFormStore.amountOfPayment)}
          onChange={handleInputChange}
          onKeyDown={(e) => {
            if (e.key === "0" && paymentFormStore.amountOfPayment === "")
              e.preventDefault();
          }}
          errortext={paymentFormStore.amountOfPaymentError}
        />

        <div className="form-step1__buttons">
          {[5000, 10000, 15000, 20000].map((amount) => (
            <button
              key={amount}
              className="form-step1__buttons-sum"
              type="button"
              aria-label="Выберите сумму взноса"
              onClick={() => handleSumButtonClick(amount)}
            >
              {amount.toLocaleString()} &#8381;
            </button>
          ))}
        </div>
      </div>

      <div className="form-step1__result">
        {isMobile ? (
          <p>
            <span className="form-step1__result-sum">
              {paymentFormStore.resultSum.toLocaleString()} &#8381;
            </span>
            <br />C учетом комиссии 6% за пополнение
          </p>
        ) : (
          <p>
            C учетом комиссии 6% за пополнение:
            <span className="form-step1__result-sum">
              {paymentFormStore.resultSum.toLocaleString()} &#8381;
            </span>
          </p>
        )}
      </div>
    </div>
  );
});

export default FormStep1;
