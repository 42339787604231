import "./Footnote.scss";

export default function Footnote({ textItalic, boldTextItalic, style }) {
  return (
    <div className="footnote" style={style}>
      <p className="footnote-text">
        <span>{boldTextItalic}</span>
        {textItalic}
      </p>
    </div>
  );
}
