import "./Description.scss";

export default function Description({ text, bold, classes = [], style }) {
  return (
    <p className={`description ${classes.join(" ")}`} style={style}>
      <span>{bold}</span>
      {text}
    </p>
  );
}
