import './ProjectPropertyColumn.scss';

export default function ProjectPropertyColumn({ title, text, className, activityIcon, status, color, timer }) {
  return (
    <div className={`project-property-column ${status}`}>
      <div className='project-property-column__title'>{title}</div>
      <div className={`project-property-column__text ${className}`} style={{ color }}>
        {activityIcon && <div
          className="project-property-column__icon"
          style={{ backgroundImage: `url(${activityIcon})` }}
        />}
        {text}
      </div>
      {timer && 
        <div className="project-property-column__timer">
          <div className="project-property-column__timer-icon" />
          <p>{timer}</p>
        </div>
      }
    </div>
  );
}
