import { observer } from 'mobx-react';

import { ActivityRow } from 'components/ActivityRow/ActivityRow';

import './ActivitiesCalculator.scss';

export const ActivitiesCalculator = observer(({ store, reviewRef, commentRef }) => {
  return (
    <div className='avito-step2-activities-calculator'>
      {store.currentCalculator.actions.map((action, i) =>
        <ActivityRow
          key={i}
          store={store}
          activity={action}
          count={action.count}
          onDown={() => { store.currentCalculator.subAction(action) } }
          onUp={() => { store.currentCalculator.addAction(action) }}
          onChange={() => { }} // TODO: set count
          dis={!action.canSub}
          reviewRef={reviewRef}
          commentRef={commentRef}
        />
      )}
    </div>
  );
});
