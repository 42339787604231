import classNames from 'classnames';

import './Preloader.scss'

const Preloader = ({ className }) => {
	return (
		<div className={classNames('preloader', className)}>
			<div className='preloader__container'>
				<span className='preloader__round'></span>
			</div>
		</div>
	)
};

export default Preloader;