import { observer } from "mobx-react";
import { useEffect, useState } from "react";

import ActionLink from "./ActionLink/ActionLink";
import { ButtonWithIcon } from "components/UI/ButtonWithIcon/ButtonWithIcon";

import arrow from "assets/images/arrowRightBlack.svg";
import magicIcon from "assets/images/activities/magic.svg";

import "./ActivityList.scss";

const ActivityList = observer(({ store, isMobile }) => {
  const [isAnimated, setIsAnimated] = useState(false);
  const { market, currentCalculator } = store.currentMarketStore;
  const hasReviewAction = currentCalculator.actions.some(action => action.type === 'MAIN.REVIEW');

  const orderedActions = [
    market.modes[0],
    ...(hasReviewAction ? [{
      title: "Генерация отзыва от GPT",
      titleComment: "не пишите сами — GPT сделает это за вас",
      icon: magicIcon,
      path: 'https://ai.boostclick.ru/',
      target: '_blank',
      statusForHomePage: "new",
      isGPT: true,
    }] : []),
    ...market.modes.slice(1),
    ...market.modes[0].actions
  ];

  const combinedActions = orderedActions.map(action => ({
    ...action,
    icon: action.iconForHomePage || action.icon,
    isMode: action.isMode !== false
  })).slice(0, 9);

  const getMarketTitle = (index) => {
    if (market.id === "avito") {
      return index < 4 ? "на досках объявлений" : "";
    }
    return market.title;
  };

  useEffect(() => {
    setIsAnimated(true);
    const timer = setTimeout(() => setIsAnimated(false), 300);
    return () => clearTimeout(timer);
  }, [market]);

  return (
    <section className={`main__services_activity ${isAnimated ? 'animated' : ''}`}>
      <div className={`main__services_activity-list ${isAnimated ? 'animated' : ''}`}>
        <div className="main__services_activity-list-column left">
          {combinedActions.slice(0, 5).map((action, index) => (
            <ActionLink
              key={index}
              action={action}
              path={action.path || currentCalculator.mode.path}
              isMobile={isMobile}
              isAnimated={isAnimated}
              marketTitle={action.isGPT ? "" : getMarketTitle(index)}
              isMode={action.isMode}
              status={action.statusForHomePage}
              target={action.target}
            />
          ))}
        </div>
        <div className="main__services_activity-list-column right">
          {combinedActions.slice(5).map((action, index) => (
            <ActionLink
              key={index + 5}
              action={action}
              path={action.path || currentCalculator.mode.path}
              isMobile={isMobile}
              isAnimated={isAnimated}
              marketTitle={action.isGPT ? "" : getMarketTitle(index + 5)}
              isMode={action.isMode}
              target={action.target}
            />
          ))}
          <ButtonWithIcon
            key={market.id}
            className="main__services_activity-order"
            title={`Заказать для ${market.id === "avito" ? "объявлений" : market.title}`}
            to={currentCalculator.mode.path}
            icon={arrow}
            onClick={() => window.scrollTo(0, 0)}
            target="_self"
          />
        </div>
      </div>
    </section>
  );
});

export default ActivityList;