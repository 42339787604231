import { observer } from "mobx-react";
import { useState } from "react";

import ActivityList from "./ActivityList/ActivityList";
import MarketItem from "../MarketItem/MarketItem";

import { MARKETS } from "AppStore";

import { getCountPlatforms } from "utils/helpers";

import "./ServicesSection.scss";

const ServicesSection = observer(({ store, isMobile }) => {
  const [animateActivityList, setAnimateActivityList] = useState(false);
  const platformCount = MARKETS.length;

  const handleMarketSelect = (index) => {
    setAnimateActivityList(true);
    store.selectMarketStoreByIndex(index);
    setTimeout(() => setAnimateActivityList(false), 300);
  };

  return (
    <section className="main__services">
      <div className="main__services_platforms">
        <div className="main__services_platforms-market">
          {MARKETS.map((market, index) => (
            <MarketItem
              key={index}
              market={market}
              index={index}
              onClick={() => { store.selectMarketStoreByIndex(index); handleMarketSelect(index); }}
              isSelected={index === store.currentMarketStoreIndex}
              containerClassName="main__services_platforms-items"
              itemClassName="main__services_platforms-item"
              logoClassName="main__services_platforms-logo"
              titleClassName="main__services_platforms-title"
            />
          ))}
        </div>
        <div className="main__services_platforms-content">
          <p className="main__services_platforms-content-title">Уже доступно <i>{platformCount} {getCountPlatforms(platformCount)}</i></p>
          <p className="main__services_platforms-content-text">Но скоро их будет еще больше!</p>
        </div>
      </div>
      <ActivityList store={store} isMobile={isMobile} animate={animateActivityList} />
    </section>
  );
});

export default ServicesSection;
