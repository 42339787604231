import { observer } from "mobx-react";
import paymentFormStore from "pages/PaymentForm/PaymentFormStore";

import "./FormStep4.scss";

const FormStep4 = observer(() => {
  const resultSum = paymentFormStore.resultSum.toLocaleString();

  const renderServices = () => {
    return Object.entries(paymentFormStore.services)
      .filter(([_, isSelected]) => isSelected)
      .map(([service, _]) => (
        <div key={service} className="form-step4__services-item">
          <div className="form-step4__services-icon" />
          <p>{paymentFormStore.renderTitleServices(service) || service}</p>
        </div>
      ));
  };

  return (
    <div className="form-step4">
      <p className="form-step4__title">Проверьте данные для счета</p>

      <div className="form-step__container">
        <p className="form-step__title">
          Сумма c учетом комиссии 6% за пополнение
        </p>
        <p className="form-step4__data">{resultSum} &#8381;</p>
      </div>

      <div className="form-step__container">
        <div>
          <p className="form-step__title">Название организации или ИП</p>
          <p className="form-step4__data">{paymentFormStore.companyName}</p>
        </div>
        <div>
          <p className="form-step__title">ИНН</p>
          <p className="form-step4__data">{paymentFormStore.inn}</p>
        </div>
      </div>
      <div className="form-step__container">
        <div>
          <p className="form-step__title">Расчетный счет</p>
          <p className="form-step4__data">{paymentFormStore.bankAccount}</p>
        </div>
        <div>
          <p className="form-step__title">БИК</p>
          <p className="form-step4__data">{paymentFormStore.bik}</p>
        </div>
        <div>
          <p className="form-step__title">КПП</p>
          <p className="form-step4__data">{paymentFormStore.kpp || "-"}</p>
        </div>
        <div>
          <p className="form-step__title">Юридический адрес организации</p>
          <p className="form-step4__data">{paymentFormStore.businessAddress}</p>
        </div>
      </div>
      <div className="form-step__container">
        <div>
          <p className="form-step__title">Адрес для отправки документов</p>
          <p className="form-step4__data">{paymentFormStore.postalAddress}</p>
        </div>
        <div>
          <p className="form-step__title">Телефон бухгалтерии</p>
          <p className="form-step4__data">
            {paymentFormStore.formatPhoneNumber(paymentFormStore.accountingNumber)}
          </p>
        </div>
        <div>
          <p className="form-step__title">Email аккаунта</p>
          <p className="form-step4__data">{paymentFormStore.accountingEmail}</p>
        </div>

        <div className="form-step4__services">
          <p className="form-step4__services-title">
            Вы оплачиваете
          </p>
          {renderServices()}
        </div>
      </div>
    </div>
  );
});

export default FormStep4;
