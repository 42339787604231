import client from "client";
import { observable, action, makeObservable } from "mobx";

class DropDownMenuStore {
  constructor() {
    makeObservable(this, {
      isActive: observable,
      setIsActive: action,
      open: action,
      close: action,

      balance: observable,
      changeBalance: action,

      displayFormat: observable,
      setDisplayFormat: action
    });
  }

  isActive = false;
  setIsActive(boolean) {
    this.isActive = boolean;
  }

  open = async () => {
    this.setIsActive(true);

    const balance = await client.getBalance();
    this.changeBalance(balance);
  };
  
  close = () => {
    this.setIsActive(false);
  };

  balance = 0;
  changeBalance(newBalance) {
    this.balance = newBalance;
  }

  // for DropDownLinks
  displayFormat = "grid";
  setDisplayFormat(format) {
    this.displayFormat = format;
  }
}

const dropDownMenuStore = new DropDownMenuStore();
export default dropDownMenuStore;
