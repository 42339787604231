import { useState } from "react";

import Plate from "../Plate/Plate";

import { useMatchMedia } from "utils/hooks/use-match-media";

import "./FAQ.scss";

export default function FAQ(props) {
  const { isMobile } = useMatchMedia();
  const { style, text, customPlateStyle } = props;

  const defaultPlateStyle = isMobile
    ? {
        position: "absolute",
        padding: "8px",
        top: "calc(100% + 6px)",
        left: "calc((-185px - 180px) / 2)",
        width: "185px",
        borderRadius: "8px",
        transition: "all 0.3s",
        opacity: 0,
      }
    : {
        position: "absolute",
        padding: "14px",
        top: "calc(100% + 12px)",
        left: "calc((-185px + 17px) / 2)",
        width: "185px",
        borderRadius: "10px",
        transition: "all 0.3s",
        opacity: 0,
      };

  const plateStyle = { ...defaultPlateStyle, ...customPlateStyle };

  const [platestyle, setPlatestyle] = useState(plateStyle);
  const [isActive, setIsActive] = useState(false);

  function activate() {
    setIsActive(true);
    setTimeout(() => {
      setPlatestyle({ ...platestyle, opacity: 1 });
    }, 1);
  }
  function deactivate() {
    setPlatestyle({ ...platestyle, opacity: 0 });
    setTimeout(() => {
      setIsActive(false);
    }, 300);
  }

  return (
    <div
      className="faq"
      style={style}
      onMouseOver={activate}
      onMouseLeave={deactivate}
    >
      {isActive && (
        <Plate style={platestyle}>
          <div className="faq__text">
            {text}
          </div>
        </Plate>
      )}
    </div>
  );
}
