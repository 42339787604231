export const EVENTS = {
  SIGNUP_OPEN: 'signup_open',
  SIGNUP_SUBMIT: 'signup_submit',
  DEPOSIT_OPEN: 'deposit_open',
  PROJECT_ADD: 'project_add',
  PROJECT_STEP: 'project_step',
  PROJECT_SUBMIT: 'project_submit',
  TELEGRAM_CHANNEL: 'telegram_channel',
  TELEGRAM_HELP: 'telegram_help',
};

const MAPPING = {
  [EVENTS.SIGNUP_OPEN]: 'reg_header',
  [EVENTS.SIGNUP_SUBMIT]: 'reg_zayavka_otpravka_formi_header',
  [EVENTS.DEPOSIT_OPEN]: 'supplement',
  [EVENTS.PROJECT_ADD]: 'add_project',
  [EVENTS.PROJECT_STEP]: 'next-step',
  [EVENTS.PROJECT_SUBMIT]: 'new_project_otpravka_formi',
  [EVENTS.TELEGRAM_CHANNEL]: 'read_telegram',
  [EVENTS.TELEGRAM_HELP]: 'need_help',
}

const YM_ID = (() => {
  const t = process.env.REACT_APP_YM_ID;
  if (!t)
    return;
  const n = Number(t);
  if (!Number.isInteger(n))
    return;
  return n;
})();

export function metricsEvent(event) {
  if (!YM_ID)
    return;
  const e = MAPPING[event];
  if (e)
    window.ym?.(YM_ID, 'reachGoal', e);
}
