import image1 from "assets/images/main/image.png";
import image2 from "assets/images/main/image1.png";
import image3 from "assets/images/main/image2.png";

export const carouselSlides = [
  {
    image: image3,
    caption: [
      <span>Настраивайте проекты под себя </span>,
      "— выбирайте бюджет, время",
      <br />,
      " отправления заданий и соотношение действий в цепочке",
    ],
  },
  {
    image: image2,
    caption: [
      <span>Мои проекты </span>,
      "— место, где собраны",
      <br />,
      " все ваши заказы и сценарии",
    ],
  },
  {
    image: image1,
    caption: [
      <span>Проверяйте отчеты исполнителей </span>,
      "— отклоняйте,",
      <br />,
      "если видите некачественную работу",
    ],
  },
];