import { observer } from "mobx-react";
import { Link } from "react-router-dom";

import Plate from "components/UI/Plate/Plate";
import Button from "components/UI/Button/Button";

import AppStore from "AppStore";

import "./WarningBox.scss";

const WarningBox = observer(() => {

  if (!AppStore.warningVisible)
    return null;

  return (
    <div className="warning-box">
      <Plate className="warning-box__plate">
        <button
          className="warning-box__close-icon"
          onClick={() => { AppStore.setWarningVisible(false) }}
        />
        <div className="warning-box__icon" />
        <p className="warning-box__message">
          На вас поступила жалоба от исполнителя.<br />Просим связаться с техподдержкой.
        </p>
        <Link to={process.env.REACT_APP_SUPPORT_ADDRESS} target="_blank">
          <Button title="Написать в техподдержку" className="warning-box__support" />
        </Link>
      </Plate>
    </div>
  );
});

export default WarningBox;
