import { observer } from "mobx-react";
import InfoSection from "../InfoSection";

import "./CommentSection.scss";

const CommentSection = observer(({ store }) => {
  return (
    <>
      <InfoSection
        title="Напишите комментарий к работе исполнителя"
        subtitle="Иногда исполнители не успевают ответить на вопросы или неверно находят объявление — в таком случае вы можете попросить того же исполнителя доработать заказ, оставив комментарий к работе. Исполнитель получит ваше сообщение."
        type="comment"
      >
        <textarea
          className="comment-section__textarea"
          placeholder="Начните печатать здесь"
          value={store.reportData.answer ? store.comment || "" : store.comment}
          onChange={(e) => {
            store.setComment(e.target.value);
          }}
        />
      </InfoSection>

      <div className="comment-section__attention">
        <div className="comment-section__attention-img" />
        <div className="comment-section__attention-text">
          <p>
            Если это не первый неудачный отчет по задаче, рекомендуем сменить
            исполнителя
          </p>
          <p>
            Исполнитель не получит вознаграждение. Вы платите только за успешное
            выполнение
          </p>
        </div>
      </div>
    </>
  );
});

export default CommentSection;
