import classNames from "classnames";

import { Fragment } from "react";

import "./TextBlock.scss";

export default function TextBlock({
  title,
  text,
  titleClassName,
  textClassName,
}) {
  return (
    <div className={classNames("text-block__title", titleClassName)}>
      {Array.isArray(title)
        ? title.map((item, i) => <Fragment key={i}>{item}</Fragment>)
        : title}
      {text && (
        <span className={classNames("text-block__span", textClassName)}>
          {Array.isArray(text)
            ? text.map((item, i) => <Fragment key={i}>{item}</Fragment>)
            : text}
        </span>
      )}
    </div>
  );
}
