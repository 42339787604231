import classNames from "classnames";

import { TextInput } from "components/UI/TextInput/TextInput";
import { useMatchMedia } from "utils/hooks/use-match-media";

import "./InputLink.scss";

export default function InputLink({ className, value, onChange, errortext }) {
  const { isMobile } = useMatchMedia();

  const placeholder = isMobile
    ? "Укажите ссылку"
    : "Укажите ссылку формата https://";

  return (
    <TextInput
      className={classNames("input-link", className)}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      errortext={errortext}
    />
  );
}
