import { observer } from "mobx-react";

import "./QuestionsReportModal.scss";

export const QuestionsReportModal = observer(({ report }) => {
  return (
    <div className="report-modal__subplate">
      <h3>Проверочные вопросы</h3>
      {report.questions.map((question, i) => (
        <p className="report-modal__question" key={i}>{`Вопрос ${
          1 + i
        }: ${question}`}</p>
      ))}
    </div>
  );
});
