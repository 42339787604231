import React, { useMemo } from "react";

import "./ProjectsProgressIndicator.scss";

function getClassNameForIndex(i, state, status) {
  let className = "projects-progress-indicator__part";
  if (status === "failed") {
    if (i <= state)
      className += " projects-progress-indicator__part_failed";
    else 
      className += " projects-progress-indicator__part_failed-empty";
  } else {
      if (i < state) 
        className += " projects-progress-indicator__part_done";
      else if (i === state)
        className += " projects-progress-indicator__part_running";
  } 
  return className;
}

const ProjectsProgressIndicator = React.memo(({ state, status }) => {
  const classNames = useMemo(() => {
    const classes = [];
    for (let i = 0; i < 10; i++) {
      classes.push(getClassNameForIndex(i, state, status));
    }
    return classes;
  }, [state, status]);

  return (
    <div className="projects-progress-indicator">
      {classNames.map((className, i) => (
        <div key={i} className={className}></div>
      ))}
    </div>
  );
});

export default ProjectsProgressIndicator;
