import { observer } from "mobx-react";
import { useEffect } from "react";
import {
  AddressSuggestions,
  BankSuggestions,
  PartySuggestions,
} from "react-dadata";

import { TextInput } from "components/UI/TextInput/TextInput";

import paymentFormStore from "../../PaymentFormStore";
import AppStore from "AppStore";

import "./FormStep2.scss";

const FormStep2 = observer(({ refs }) => {
  useEffect(() => {
    paymentFormStore.loadSavedData();

    if (AppStore.userEmail && !paymentFormStore.accountingEmail) {
      paymentFormStore.updateField("accountingEmail", AppStore.userEmail);
    }
  }, []);

  const handleChange = (field) => (e) => {
    if (field !== "accountingEmail") {
      let value = e.target.value;
      if (["inn", "bankAccount", "kpp", "accountingNumber"].includes(field)) {
        value = value.replace(/[^\d]/g, "");
      }
      paymentFormStore.updateField(field, value);
    }
  };

  const handlePartyChange = (suggestion) => {
    paymentFormStore.updatePartyData(suggestion);
  };

  const handleBIKChange = (suggestion) => {
    paymentFormStore.updateBankData(suggestion);
  };

  const handleAddressChange = (field) => (suggestion) => {
    paymentFormStore.updateAddress(field, suggestion);
  };

  const handleManualInput = (field) => (e) => {
    const value = e.target.value;
    paymentFormStore.updateField(field, value);
  };

  const renderInput = (field, label, placeholder, options = {}) => (
    <label htmlFor={field} className="form-step__title" ref={refs[field]}>
      {label}
      <TextInput
        id={field}
        type="text"
        placeholder={placeholder}
        value={paymentFormStore[field]}
        onChange={handleChange(field)}
        errortext={paymentFormStore[`${field}Error`]}
        list={`${field}-suggestions`}
        disabled={field === "accountingEmail"}
        {...options}
      />
    </label>
  );

  return (
    <div className="form-step2">
      <div className="form-step__container">
        <label htmlFor="companyName" className="form-step__title" ref={refs.companyName}>
          Название организации / ИП или ИНН
          <PartySuggestions
            token={process.env.REACT_APP_DADATA_API_KEY}
            value={paymentFormStore.companyName ? { value: paymentFormStore.companyName } : null}
            onChange={(suggestion) => {
              if (suggestion) {
                handlePartyChange(suggestion);
              } else {
                handleManualInput('companyName')(suggestion);
              }
            }}
            inputProps={{
              id: "party",
              placeholder: "Введите название организации / ИП или ИНН",
              className: `react-dadata__input ${paymentFormStore.companyNameError ? 'error' : ''}`,
              onChange: handleManualInput('companyName'),
            }}
          />
        </label>
        {renderInput("inn", "ИНН", "Введите ИНН", { minLength: 10, maxLength: 12, inputmode: "numeric" })}
      </div>
      <div className="form-step__container">
        {renderInput("bankAccount", "Р/С", "Введите расчетный счет", { minLength: 20, maxLength: 20, inputmode: "numeric", })}
        <label htmlFor="bik" className="form-step__title" ref={refs.bik}>
          Банк
          <BankSuggestions
            token={process.env.REACT_APP_DADATA_API_KEY}
            query={paymentFormStore.bankName || paymentFormStore.bik}
            value={paymentFormStore.bankName ? { value: paymentFormStore.bankName } : null}
            onChange={handleBIKChange}
            count={5}
            minChars={3}
            inputProps={{
              id: "bik",
              inputMode: "numeric",
              placeholder: "Введите БИК / Название Банка",
              className: `react-dadata__input ${paymentFormStore.bikError ? 'error' : ''}`
            }}
          />
        </label>
        {renderInput("kpp", "КПП", "Введите КПП или оставьте поле пустым", { maxLength: 9, inputmode: "numeric" })}
        <label htmlFor="businessAddress" className="form-step__title" ref={refs.businessAddress}>
          Юридический адрес организации
          <AddressSuggestions
            token={process.env.REACT_APP_DADATA_API_KEY}
            value={paymentFormStore.businessAddress ? { value: paymentFormStore.businessAddress } : null}
            onChange={(suggestion) => {
              if (suggestion) {
                handleAddressChange("businessAddress")(suggestion);
              } else {
                handleManualInput("businessAddress")(suggestion);
              }
            }}
            inputProps={{
              id: "businessAddress",
              placeholder: "Введите юридический адрес организации",
              className: `react-dadata__input ${paymentFormStore.businessAddressError ? 'error' : ''}`,
              onChange: handleManualInput("businessAddress"),
            }}
          />
        </label>
      </div>
      <div className="form-step__container">
        <label htmlFor="postalAddress" className="form-step__title" ref={refs.postalAddress}>
          Адрес для отправки документов
          <AddressSuggestions
            token={process.env.REACT_APP_DADATA_API_KEY}
            value={paymentFormStore.postalAddress ? { value: paymentFormStore.postalAddress } : null}
            onChange={(suggestion) => {
              if (suggestion) {
                handleAddressChange("postalAddress")(suggestion);
              } else {
                handleManualInput("postalAddress")(suggestion);
              }
            }}
            inputProps={{
              id: "postalAddress",
              placeholder: "Введите адрес для отправки документов",
              className: `react-dadata__input ${paymentFormStore.postalAddressError ? 'error' : ''}`,
              onChange: handleManualInput("postalAddress"),
            }}
          />
        </label>
        {renderInput("accountingNumber", "Телефон бухгалтерии", "Введите номер телефона", { minLength: 11, maxLength: 11, inputmode: "numeric" })}
        {renderInput("accountingEmail", "E-Mail аккаунта", "Введите e-mail")}
      </div>
    </div>
  );
});

export default FormStep2;
