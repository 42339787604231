import { observer } from 'mobx-react';
import { useEffect, useMemo, useState } from 'react';
import { Helmet } from 'react-helmet';

import ProjectsActive from './ProjectsActive/ProjectsActive';
import ProjectsDrafts from './ProjectsDrafts/ProjectsDrafts';
import ProjectsArchive from './ProjectsArchive/ProjectsArchive';
import { ButtonWithIcon } from 'components/UI/ButtonWithIcon/ButtonWithIcon';
import Preloader from 'components/Preloader/Preloader';

import AppStore from 'AppStore';

import plusIcon from "assets/images/plus.svg";
import { EVENTS, metricsEvent } from 'metrics';

import "./Projects.scss";

/**
 * @param {Object[]} projects
 * @param {String[]} statuses
 */
function filterProjectsByStatuses(projects, statuses) {
  return projects?.filter((project) => statuses.includes(project.status));
}

/**
 * @param {Object[]} projects
 * @param {String} status
 */
function filterProjectsByStatus(projects, status) {
  return filterProjectsByStatuses(projects, [status]);
}

export const Projects = observer(() => {
  const [isLoading, setIsLoading] = useState(true);

  const tabs = useMemo(
    () => [
      {
        id: "active",
        label: `Активные проекты`,
        component: ProjectsActive,
        projects: filterProjectsByStatuses(AppStore.projects, [
          "running",
          "failed",
        ]),
        count:
          filterProjectsByStatuses(AppStore.projects, ["running", "failed"])
            ?.length ?? 0,
      },
      {
        id: "drafts",
        label: `Черновики`,
        component: ProjectsDrafts,
        projects: filterProjectsByStatus(AppStore.projects, "new"),
        count: filterProjectsByStatus(AppStore.projects, "new")?.length ?? 0,
      },
      {
        id: "archive",
        label: `Архив`,
        component: ProjectsArchive,
        projects: filterProjectsByStatus(AppStore.projects, "finished"),
        count:
          filterProjectsByStatus(AppStore.projects, "finished")?.length ?? 0,
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [AppStore.projects]
  );

  useEffect(() => {
    window.scroll(0, 0);
    AppStore.updateProjects()
    .finally(() => {
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      AppStore.checkReadyReportsAndShowWarning();
    }, 43200000);

    return () => clearInterval(interval);
  }, []);

  const handleReloadHomePage = () => {
    metricsEvent(EVENTS.PROJECT_ADD);
  };

  return (
    <main className="projects">
      <Helmet>
        <title>Мои проекты — Boostclick</title>
        <meta name="description" content="Мои проекты в сервисе Boostclick" />
        <meta name="viewport" content="initial-scale=0" />
        <meta name="robots" content="noindex" />
      </Helmet>

      <div className="projects__inner">
        {/* <ProjectsFilter /> */}
        <div className='projects__header'>
          <h1 className='projects__header-title'>Мои проекты</h1>
          <ButtonWithIcon
            title='Добавить проект'
            className="projects__header-add-button"
            icon={plusIcon}
            onClick={handleReloadHomePage}
            ariaLabel="Добавить новый проект"
            to="/"
            target='_self'
          />
        </div>

        <div className="projects__tabs-container">
          <div className="projects__tabs">
            {tabs.map((tab) => (
              <button
                key={tab.id}
                className={`projects__tab ${AppStore.activeTab === tab.id ? "projects__tab-active" : ""}`}
                type="button"
                aria-label={`Вкладка ${tab.label}`}
                onClick={() => AppStore.setActiveTab(tab.id)}
              >
                {`${tab.label} (${tab.count})`}
              </button>
            ))}
          </div>
          <div className="projects__tab-content">
          {isLoading ? 
            <Preloader />
              : 
            tabs.map((tab) => AppStore.activeTab === tab.id && (<tab.component key={tab.id} projects={tab.projects} />))
          }
          </div>
        </div>
      </div>
    </main>
  );
});
