import { observer } from "mobx-react";
import { useState } from "react";

import { TextInput } from "components/UI/TextInput/TextInput";

import AppStore from "AppStore";

import "./ProjectEditingTitle.scss";

const ProjectEditingTitle = observer(({ project }) => {
  const [newTitle, setNewTitle] = useState('');

  return (
    <>
      {AppStore.editingTitle && AppStore.editingProjectId === project.id && (
        <div className="project-editing-title">
          <TextInput
            placeholder="Введите название проекта"
            type="text"
            value={newTitle}
            onChange={(e) => setNewTitle(e.target.value)}
          />
          <button
            className="project-editing-title__button-save"
            onClick={() => {
              AppStore.updateProjectDetails(project, { title: newTitle });
              AppStore.setEditingTitle(false);
            }}
          />
        </div>
      )}
    </>
  );
});

export default ProjectEditingTitle;
