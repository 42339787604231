import { observer } from "mobx-react";

import ProjectsActiveReportAuctionRise from "./ProjectsActiveReportAuctionRise/ProjectsActiveReportAuctionRise";
import { FAQReportModal } from "pages/Projects/ReportModal/FAQReportModal/FAQReportModal";
import { FAQ_IN_PROJECT } from "../../../../ReportModal/FAQReportModal/faqConstants";

import "./ProjectsActiveReportAuction.scss";

const ProjectsActiveReportAuction = observer(({ store, report }) => {
  return (
    <div className="projects-active-report-auction">
      <ProjectsActiveReportAuctionRise
        store={store}
        report={report}
      />
      <FAQReportModal
        type="faq-in-project"
        store={store}
        faq={FAQ_IN_PROJECT}
      />
    </div>
  );
});

export default ProjectsActiveReportAuction;
