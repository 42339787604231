import Accordion from 'components/UI/Accordion/Accordion';
import Plate from 'components/UI/Plate/Plate';

import './Answers.scss';

export default function Answers({ title, questions }) {
  return (
    <section id='faq' className='answers'>
      <h2 className='answers__title'>{title}</h2>

      <Plate className='answers__plate'>
        {questions?.map((question, i) =>
          <Accordion key={i} {...question} isH3={question.isH3} />
        )}
      </Plate>
    </section>
  );
}
