import create from "assets/images/dashboard/howtoworks/step1-icon.svg";
import search from "assets/images/dashboard/howtoworks/step2-icon.svg";
import report from "assets/images/dashboard/howtoworks/step3-icon.svg";
import step1 from "assets/images/dashboard/howtoworks/step1.svg";
import step2 from "assets/images/dashboard/howtoworks/step2.svg";
import step3 from "assets/images/dashboard/howtoworks/step3.svg";

export const STEP1_TITLE_CREATE_TASK = "Вы создаете задачу";
export const STEP1_DESCRIPTION_CREATE_TASK_PART1 = "Она попадает в "; // TODO: use string template
export const STEP1_DESCRIPTION_CREATE_TASK_PART2 = " Там вы можете оплатить ее, сперва пополнив счет в "; // TODO: use string template
export const STEP2_TITLE_LOOKING_PERFORMER = "Сервис ищет исполнителя";
export const STEP2_DESCRIPTION_LOOKING_PERFORMER  = "Система автоматически формирует сценарии и ищет под каждый исполнителя";
export const STEP3_TITLE_CHECK_PAY_REPORT = "Вы проверяете отчет и оплачиваете работу";
export const STEP3_DESCRIPTION_CHECK_PAY_REPORT = "Вы принимаете отчет или отклоняете, если он заполнен неверно. Платите только за готовое задание";

export { create, search, report, step1, step2, step3 };
