import human1 from "assets/images/dashboard/face-1.png";
import human2 from "assets/images/dashboard/face-2.png";
import human3 from "assets/images/dashboard/face-3.png";

import "./IntroPerformers.scss";

export default function IntroPerformers() {

  return (
    <div className="intro-performers">
        <div className="intro-performers__people">
          <img src={human1} alt="Лицо человека-исполнителя" className="intro-performers__people_img-human1" />
          <img src={human2} alt="Лицо человека-исполнителя" className="intro-performers__people_img-human2" />
          <img src={human3} alt="Лицо человека-исполнителя" className="intro-performers__people_img-human3" />
          <div className="intro-performers__people_img-check" />
        </div>

        <div className="intro-performers__people_description">
          <p className="intro-performers__people_description-title">+ 47 000 исполнителей</p>
          <p className="intro-performers__people_description-text">и ни одного бота</p>
        </div>
    </div>
  );
}
