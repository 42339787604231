import visa from "assets/images/footer/logos/visa.svg";
import mir from "assets/images/footer/logos/mir.svg";
import podeli from "assets/images/footer/logos/podeli.svg";
import ymoney from "assets/images/footer/logos/ymoney.svg";
import otp from "assets/images/footer/logos/OTP.svg";
import yapay from "assets/images/footer/logos/yapay.svg";
import mts from "assets/images/footer/logos/mts.svg";
import mir2 from "assets/images/footer/logos/mir2.svg";
import halva from "assets/images/footer/logos/halva.svg";
import hc from "assets/images/footer/logos/hc.svg";
import sbp from "assets/images/footer/logos/sbp.svg";
import master from "assets/images/footer/logos/master.svg";
import union from "assets/images/footer/logos/union.svg";

import "./FooterPlaceLogos.scss";

const placesData = [
  { img: visa },
  { img: mir },
  { img: podeli },
  { img: ymoney },
  { img: otp },
  { img: yapay },
  { img: mts },
  { img: mir2 },
  { img: halva },
  { img: hc },
  { img: sbp },
  { img: master },
  { img: union },
];

export default function FooterPlaceLogos() {
  return (
    <div className="footer-place-logo">
      <div className="footer-place-logo__container">
        <p className="footer-place-logo__title">В BOOSTCLICK доступна</p>
        <p className="footer-place-logo__text">Безопасная оплата онлайн</p>
        <ul>
          <li>официально</li>
          <li>с чеком</li>
          <li>быстро</li>
        </ul>
        <div className="footer-place-logo__check" />
      </div>

      <div className="footer-place-logo__icon">
        <div className="footer-place-logo__icon-first">
          <div className="footer-place-logo__icon-first-logo" />
        </div>
        {placesData.map((item, index) => (
          <div
            key={index}
            className="footer-place-logo__icon-logo"
            style={{ backgroundImage: `url(${item.img})` }}
          />
        ))}
      </div>
    </div>
  );
}
