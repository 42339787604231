import "./HeaderArticle.scss";

export default function HeaderArticle({ articleData, hasDescription }) {
  const { data, title, description, time, type, category, imageUrl } = articleData;

  const containerStyle = {
    backgroundImage: `linear-gradient(
      180deg,
      rgba(0, 0, 0, 0) 0%,
      rgba(0, 0, 0, 0.95) 100%
    ), url(${imageUrl})`
  };

  return (
    <div className={`${hasDescription ? "with-description" : ""}`}>
      <div className="header-article" style={containerStyle}>
        <div className="header-article__container">
          <div className="header-article__info">
            <p>{data}</p>
          </div>

          <h1 className="header-article__title">{title}</h1>
          <div className="header-article__background" />
        </div>
      </div>

      <div
        className={`header-article__subcontainer" ${
          hasDescription ? "with-description-subcontainer" : ""
        }`}
      >
        {hasDescription && (
          <div className="header-article__description">
            <h3 className="header-article__description-title">О чем статья?</h3>
            <p className="header-article__description-text">{description}</p>
          </div>
        )}

        <div
          className={`header-article__param ${
            hasDescription ? "with-description-param" : ""
          }`}
        >
          <div className="header-article__param-text">
            Время чтения:{" "}
            <span className="header-article__param-time">{time} минут</span>
          </div>
          <div className="header-article__tags">
            <div className="header-article__tags-tag">{type}</div>
            {category && <div className="header-article__tags-tag">{category}</div>}
          </div>
        </div>
      </div>
    </div>
  );
}
