import classNames from 'classnames';

import './ProjectsArchiveAbstractRow.scss';

export default function ProjectsArchiveAbstractRow({className, children}) {
  return (
    <div className={classNames('projects-archive-abstract-row', className)}>
      {children}
    </div>
  );
}
