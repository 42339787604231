import Carousel from "components/UI/Carousel/Carousel";

import "./AdvantagesSection.scss";

const AdvantagesSection = ({ slides, isMobile }) => (
  <section className="main__advantages">
    <div className="main__advantages_small">
      <div className="main__advantages_small-left">
        <p>+ 47 000 исполнителей</p>
        {isMobile && <span>Задачи, которые вы ставите на бирже, выполняют реальные, проверенные пользователи</span>}
      </div>
      <div className="main__advantages_small-right">
        <div className="main__advantages_small-right-icon" />
        <p>И ни одного бота</p>
      </div>
    </div>

    <div className="main__advantages_medium">
      <p>Отправим чек и договор <br /> Работаем {isMobile ? "с юр." : "с юридическими"} лицами</p>
      <span>Безопасная оплата через Робокассу</span>
      <div className="main__advantages_medium-bg" />
    </div>
    
    <div className="main__advantages_big">
      <div className="main__advantages_big-description">
        <p>Только вы решаете, <br /> когда задача завершена</p>
        <p className="main__advantages_big-description-text">Вы проверяете отчеты, <br /> а учет проектов всегда перед вами!</p>
      </div>
      {!isMobile && <Carousel slides={slides} />}
    </div>
  </section>
);

export default AdvantagesSection;
