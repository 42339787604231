import './Button.scss';

export default function Button({ title, className, classes = [], style = {}, onClick = () => { }, disabled = false, type, bold }) {
  // classes: [outlined, small, wide]

  return (
    <button type={type} disabled={disabled} style={style} className={'button ' + (className ?? '') + classes.join(' ')} onClick={onClick}>
      {title}
      {bold && <span>{bold}</span>}
    </button>
  );
}
