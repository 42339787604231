import { makeAutoObservable, runInAction } from "mobx";

import AppStore from "AppStore";
import messageBoxStore from "MessageBox/MessageBoxStore";

import client, { ApiError } from "client";
class PaymentFormStore {
  recipientDetails = {
    companyName: "Индивидуальный предприниматель ЧЕРЕМХИНА О.В.",
    inn: "780632809205",
    bankAccount: "40802810803000058306",
    bik: "044030723",
    corrAccount: "30101810100000000723",
    bankName: "Филиал «Северная столица» АО «РАЙФФАЙЗЕНБАНК»",
    kpp: "-",
    businessAddress: "Ленинградская обл, Всеволожский р-н, г Всеволожск",
  };

  constructor() {
    this.token = AppStore.token;

    this.companyName = "";
    this.inn = "";
    this.bankAccount = "";
    this.bik = "";
    this.bankName = "";
    this.corrAccount = "";
    this.kpp = "";
    this.businessAddress = "";
    this.postalAddress = "";
    this.accountingNumber = "";
    this.accountingEmail = AppStore.userEmail || "";

    makeAutoObservable(this);
    this.loadSavedData();
  }

  isStep1Valid = false;
  isStep2Valid = false;
  isStep3Valid = false;

  currentStep = 1;
  setCurrentStep(step) {
    this.currentStep = step;
  }

  stepValidations = {
    1: () => this.validateFormStep1(),
    3: () => this.validateFormStep3(),
  };

  get isNextButtonDisabled() {
    const validation = this.stepValidations[this.currentStep];
    return validation ? !validation() : false;
  }

  handleNext() {
    const validation = this.stepValidations[this.currentStep];
    const isValid = validation ? validation() : true;

    if (this.currentStep === 2) {
      if (!this.validateFormStep2()) {
        return;
      }
    }

    if (isValid) {
      if (this.currentStep === 4) {
        this.handleCreateDeposit();
      } else {
        this.setCurrentStep(this.currentStep + 1);
      }
    }
  }

  handleBack() {
    if (this.currentStep > 1) {
      this.setCurrentStep(this.currentStep - 1);
    }
  }

  resultSum = "";

  amountOfPayment = "";
  amountOfPaymentError = "";
  setAmountOfPayment(amount) {
    runInAction(() => {
      this.amountOfPayment = amount;
      this.amountOfPaymentError = "";
      this.calculateResultSum();
      this.isStep1Valid = this.validateFormStep1();
    });
  }

  calculateResultSum() {
    if (this.amountOfPayment) {
      const sum = parseInt(this.amountOfPayment);
      const commission = Math.round((sum * 6) / 100);
      this.resultSum = sum + commission;
      return this.resultSum.toLocaleString();
    }

    this.resultSum = "";
    return "";
  }

  validateFormStep1() {
    const amount = Number(this.amountOfPayment);
    this.isStep1Valid = amount >= 5000 && amount <= 1000000;
    return this.isStep1Valid;
  }

  companyName = "";
  companyNameError = false;
  setCompanyName(name) {
    this.companyName = name;
    this.companyNameError = false;
  }

  inn = "";
  innError = false;
  setInn(inn) {
    this.inn = inn;
    this.innError = false;
  }

  bankAccount = "";
  bankAccountError = false;
  setBankAccount(account) {
    this.bankAccount = account;
    this.bankAccountError = false;
  }

  bik = "";
  bikError = false;
  setBik(bik) {
    this.bik = bik;
    this.bikError = false;
  }

  businessAddress = "";
  businessAddressError = false;
  setBusinessAddress(address) {
    this.businessAddress = address;
    this.businessAddressError = false;
  }

  postalAddress = "";
  postalAddressError = false;
  setPostalAddress(address) {
    this.postalAddress = address;
    this.postalAddressError = false;
  }

  accountingNumber = "";
  accountingNumberError = false;
  setAccountingNumber(number) {
    this.accountingNumber = number;
    this.accountingNumberError = false;
  }

  accountingEmail = "";
  setAccountingEmail(email) {
    if (!this.accountingEmail) {
      this.accountingEmail = email;
    }
  }

  formatPhoneNumber(number) {
    const cleaned = ("" + number).replace(/\D/g, "");
    const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})$/);
    if (match) {
      const intlCode = match[1] === "7" ? "+7" : "8";
      return `${intlCode} ${match[2]} ${match[3]} ${match[4]} ${match[5]}`;
    }
    return number;
  }

  updateField(field, value) {
    this[field] = value;
    this[`${field}Error`] = false;
    this.saveData();
  }

  updatePartyData(suggestion) {
    if (suggestion && suggestion.data) {
      this.setCompanyName(suggestion.value);
      this.setInn(suggestion.data.inn);
      this.kpp = suggestion.data.kpp || "";
      this.setBusinessAddress(suggestion.data.address?.value || "");
      this.setPostalAddress(suggestion.data.address?.value || "");
    }
    this.saveData();
  }

  updateBankData(suggestion) {
    if (suggestion) {
      this.setBik(suggestion.data.bic);
      this.bankName = suggestion.data.name.payment;
      this.corrAccount = suggestion.data.correspondent_account;
      this.saveData();
    }
  }

  updateAddress(field, suggestion) {
    if (suggestion) {
      if (field === "businessAddress") {
        this.setBusinessAddress(suggestion.value);
      } else if (field === "postalAddress") {
        this.setPostalAddress(suggestion.value);
      }
      this.saveData();
    }
  }

  saveData() {
    const dataToSave = {
      companyName: this.companyName,
      inn: this.inn,
      bankAccount: this.bankAccount,
      bik: this.bik,
      bankName: this.bankName,
      corrAccount: this.corrAccount,
      kpp: this.kpp,
      businessAddress: this.businessAddress,
      postalAddress: this.postalAddress,
      accountingNumber: this.accountingNumber,
      accountingEmail: this.accountingEmail,
    };

    if (this.token) {
      const key = `formStep2Data_${this.token}`;
      localStorage.setItem(key, JSON.stringify(dataToSave));
    }
  }

  loadSavedData() {
    if (this.token) {
      const key = `formStep2Data_${this.token}`;
      const savedData = JSON.parse(localStorage.getItem(key) || "{}");
      Object.keys(savedData).forEach((key) => {
        if (this.hasOwnProperty(key)) {
          this[key] = savedData[key];
        }
      });
    }
  }

  validateFormStep2() {
    if (!this.companyName) {
      this.companyNameError = true;
      messageBoxStore.showError("Укажите название организации или ИП");
      return;
    }
    if (!this.inn || this.inn.length < 10) {
      this.innError = true;
      messageBoxStore.showError("Укажите ИНН (минимум 10 цифр)");
      return;
    }
    if (!this.bankAccount || this.bankAccount.length !== 20) {
      this.bankAccountError = true;
      messageBoxStore.showError("Укажите расчетный счет (20 цифр)");
      return;
    }
    if (!this.bik || this.bik.length !== 9) {
      this.bikError = true;
      messageBoxStore.showError("Укажите БИК Банка (9 цифр)");
      return;
    }
    if (!this.businessAddress) {
      this.businessAddressError = true;
      messageBoxStore.showError("Укажите юридический адрес организации");
      return;
    }
    if (!this.postalAddress) {
      this.postalAddressError = true;
      messageBoxStore.showError("Укажите адрес для отправки документов");
      return;
    }
    if (!this.accountingNumber || this.accountingNumber.length !== 11) {
      this.accountingNumberError = true;
      messageBoxStore.showError("Укажите телефон бухгалтерии (11 цифр)");
      return;
    }

    this.companyNameError = false;
    this.innError = false;
    this.bankAccountError = false;
    this.bikError = false;
    this.businessAddressError = false;
    this.postalAddressError = false;
    this.accountingNumberError = false;

    return true;
  }

  services = {
    behavior: false,
    reviews: false,
    views: false,
    subscribers: false,
    reposts: false,
    likes: false,
    comments: false,
  };

  updateService(service, value) {
    runInAction(() => {
      this.services[service] = value;
      this.isStep3Valid = this.isAnyServiceSelected;
    });
  }

  renderTitleServices(service) {
    const serviceNames = {
      behavior: "Поведенческий фактор (ПФ)",
      reviews: "Отзывы",
      views: "Просмотры",
      subscribers: "Подписчики",
      reposts: "Репосты",
      likes: "Лайки",
      comments: "Комментарии",
    };

    return serviceNames[service] || service;
  }

  get isAnyServiceSelected() {
    return Object.values(this.services).some((value) => value);
  }

  validateFormStep3() {
    this.isStep3Valid = this.isAnyServiceSelected;
    return this.isStep3Valid;
  }

  date = "";
  setCurrentDate() {
    const now = new Date();
    this.date = now.toLocaleDateString("ru-RU");
  }

  formattedInvoiceNumber = "";
  formatInvoiceNumber(id) {
    return `СБ-${String(id).padStart(3, "0")}`;
  }

  invoiceId = null;
  payoffUrl = null;
  async handleCreateDeposit() {
    try {
      const amount = this.resultSum;

      const businessInfo = {
        companyName: this.companyName,
        inn: this.inn,
        bankAccount: this.bankAccount,
        bik: this.bik,
        bankName: this.bankName,
        corrAccount: this.corrAccount,
        kpp: this.kpp,
        businessAddress: this.businessAddress,
        postalAddress: this.postalAddress,
        accountingNumber: this.accountingNumber,
        accountingEmail: this.accountingEmail,

        services: this.services,
      };

      const invoice = await client.createInvoice(amount, businessInfo);

      this.invoiceId = invoice.id;
      this.formattedInvoiceNumber = this.formatInvoiceNumber(invoice.id);
      this.payoffUrl = invoice.payoffUrl;
      this.setCurrentDate();

      this.setCurrentStep(5);
    } catch (e) {
      if (e instanceof ApiError) {
        messageBoxStore.showError(e.message);
        return;
      } else {
        console.error("Произошла ошибка при отправки данных на сервер:", e);
      }
    }
  }

  resetForm() {
    this.amountOfPayment = "";
    this.resultSum = "";
    this.date = "";
    this.invoiceId = null;
    this.formattedInvoiceNumber = "";
    this.payoffUrl = null;

    this.isStep1Valid = false;
    this.isStep2Valid = false;
    this.isStep3Valid = false;

    this.services = {
      behavior: false,
      reviews: false,
      views: false,
      subscribers: false,
      reposts: false,
      likes: false,
      comments: false,
    };
    this.currentStep = 1;
  }
}

const paymentFormStore = new PaymentFormStore();
export default paymentFormStore;
