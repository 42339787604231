import { Fragment, useState } from "react";

import { ButtonWithIcon } from "../ButtonWithIcon/ButtonWithIcon";

import arrow from "assets/images/arrowLeftSmall.svg";

import "./Carousel.scss";

const Carousel = ({ slides }) => {
  const [currentSlide, setCurrentSlide] = useState(1);

  const nextSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prevSlide) => (prevSlide - 1 + slides.length) % slides.length);
  };

  return (
    <div className="carousel">
      <div className="carousel__slides">
        <div className="carousel__slide carousel__slide-left" style={{backgroundImage: `url(${slides[(currentSlide - 1 + slides.length) % slides.length].image})`}} />
        <div className="carousel__slide carousel__slide-center" style={{ backgroundImage: `url(${slides[currentSlide].image})`}} />
        <div className="carousel__slide carousel__slide-right" style={{backgroundImage: `url(${slides[(currentSlide + 1) % slides.length].image})`}} />
      </div>

      <div className="carousel__controls">
          <div className="carousel__caption">
            {Array.isArray(slides[currentSlide].caption)
              ? slides[currentSlide].caption.map((item, i) => <Fragment key={i}>{item}</Fragment>)
              : slides[currentSlide].caption
            }
          </div>
        <div className="carousel__buttons">
          <button className="carousel__button carousel__button-prev" type="button" aria-label="Предыдущий слайд" onClick={prevSlide} />
          <ButtonWithIcon
            title="Далее"
            icon={arrow}
            onClick={nextSlide}
            ariaLabel="Следующий слайд"
          />
        </div>
      </div>
    </div>
  );
};

export default Carousel;
