import { makeAutoObservable, runInAction } from 'mobx';

const POPUP_TIMEOUT_MS = 3000;

class PopupBoxStore {

  timeoutId;
  status;
  message;
  image = null;

  isOpen = false;

  constructor() {
    makeAutoObservable(this);
  }

  showInfo(message, image = null) {
    this.open('info', message);
    this.image = image;
  }

  showError(message) {
    this.open('error', message);
  }

  open(status, message) {
    this.status = status;
    this.message = message;

    this.isOpen = true;

    if (this.timeoutId !== undefined)
      clearTimeout(this.timeoutId);
    this.timeoutId = setTimeout(() => {
      runInAction(() => {
        this.timeoutId = undefined;
        this.isOpen = false; 
      })},
      POPUP_TIMEOUT_MS
    );
  }
}

const popupBoxStore = new PopupBoxStore();
export default popupBoxStore;
