import { observer } from "mobx-react";

import Button from "components/UI/Button/Button";
import ProjectsDraftsAbstractRow from "../ProjectsDraftsAbstractRow/ProjectsDraftsAbstractRow";
import ProjectLinkButton from "pages/Projects/components/ProjectLinkButton/ProjectLinkButton";
import Tooltips from "components/UI/Tooltips/Tooltips";
import ProjectEditingTitle from "pages/Projects/components/ProjectSetupModal/ProjectEditingTitle/ProjectEditingTitle";

import AppStore from "AppStore";

import {
  calcTotalProjectActions,
  findActivityCount,
} from "../../../Projects/ProjectsConstants";

import "./ProjectsDraftsRow.scss";

const ProjectsDraftsRow = observer(({ project }) => {

  const activities = project?.activities;
  const reviewsCount = findActivityCount(activities, "MAIN.REVIEW");

  if (!project)
    return null;

  return (
    <ProjectsDraftsAbstractRow className="projects-drafts-row">
      <div className="projects-drafts-row__title-container">
        <div className="projects-drafts-row__title-section">
          <div />
          {AppStore.editingTitle &&
            AppStore.editingProjectId === project.id ? (
            <ProjectEditingTitle project={project} />
          ) : (
            <Tooltips description={project.fullTitle}>
              <div className="projects-drafts-row__title">
                {project.titleToShow}
              </div>
            </Tooltips>
          )}
        </div>
        {AppStore.editingTitle && AppStore.editingProjectId === project.id ? (
          <></>
        ) : (
          <button
            type="button"
            aria-label="Редактировать"
            className="projects-drafts-row__button-edit"
            onClick={() => {
              AppStore.startEditingProjectTitle(project.id);
              AppStore.setEditingTitle(true);
              AppStore.setNewTitle(project.title);
            }}
          />
        )}
      </div>
      <div className="projects-drafts-row__info">
        <ProjectLinkButton style={{ visibility: project.link ? 'visible' : 'hidden' }} link={project.link} />
        <div className="projects-drafts-row__info-status">Ожидает оплаты</div>
        <div className="projects-drafts-row__info-counter">
          <Tooltips description="Всего действий">
            <div className="projects-drafts-row__info-counter-activity-all">
              <div className="projects-drafts-row__info-counter-activity-all-icon" />
              {calcTotalProjectActions(project)}
            </div>
          </Tooltips>
          <Tooltips description="Количество отзывов" innerStyle={{ left: "70%" }}>
            <div className="projects-drafts-row__info-counter-activity-reviews">
              <div className="projects-drafts-row__info-counter-activity-reviews-icon" />
              {reviewsCount}
            </div>
          </Tooltips>
        </div>
        <div className="projects-archive-row__info-city">
          {project.city ? project.city : "—"}
        </div>
      </div>
      <div className="projects-drafts-row__buttons">
        <Button
          title="Списать "
          bold={project ? `${project.price.toLocaleString()} ₽` : undefined}
          onClick={() => {
            AppStore.payProject(project);
          }}
        />
        <button
          type="button"
          aria-label="Удалить"
          className="projects-drafts-row__delete-icon"
          onClick={() => {
            AppStore.deleteProjectWithConfirm(project);
          }}
        />
      </div>
    </ProjectsDraftsAbstractRow>
  );
});

export default ProjectsDraftsRow;
