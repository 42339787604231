import { observer } from "mobx-react";

import Faqed from "components/UI/Faqed/Faqed";
import InputLink from "components/InputLink/InputLink";
import Button from "components/UI/Button/Button";
import UploadFile from "components/UploadFile/UploadFile";
import { CategoryInput } from "../componentsStep1/CategoryInput/CategoryInput";
import { Phrase } from "../componentsStep1/Phrase/Phrase";
import CityInput from "../componentsStep1/CityInput/CityInput";
import InstructionsScreenPopup from "../componentsStep1/InstructionsScreenPopup/InstructionsScreenPopup";

import AppStore from "AppStore";
import popupStore from "components/Popup/PopupStore";

import { getDativeCase } from "utils/helpers";

import "./IsDesktopStep1.scss";

export const IsDesktopStep1 = observer(
  ({
    store,
    needFullParams,
    nameSite,
    linkRef,
    categoryRef,
    cityRef,
    searchPhrasesRef,
    screenshotRef,
    onChangeLinkToAd,
    onChangeAddress,
    onClickCreateProject,
    descriptionScreenshot,
    descriptionCategory,
    descriptionCity,
    iconUploadFile,
  }) => {
    return (
      <div className="market-step1">
        <div className={`market-step1__link_settings ${!needFullParams ? "without-border" : ""}`}>
          {needFullParams && (
            <div className={`market-step1__link_settings-screen ${AppStore.uploadedFileError ? " error" : ""}`} ref={screenshotRef}>
              <Faqed text={descriptionScreenshot}>
                <p className="market-step1__label">Прикрепите скриншот объявления</p>
              </Faqed>
              <button
                className="market-step1__link_settings-screen-instructions"
                type="button"
                aria-label="Инструкция 'Как сделать скриншот?'"
                onClick={() => {
                  store.setInstructionsScreen(true);
                  popupStore.open("instructions-screen");
                }}
              >
                Как сделать скриншот?
              </button>
              <UploadFile
                title="Прикрепите скриншот"
                icon={iconUploadFile}
                className="avito-upload-file__add-button"
              />
            </div>
          )}
          <div className="market-step1__link_settings-link" ref={linkRef}>
            <p className="market-step1__label">{store.market.targetLinkComment}</p>
            <InputLink
              defaultValue={store.linkToAd}
              onChange={onChangeLinkToAd}
              errortext={store.linkError}
            />
          </div>
        </div>
        {needFullParams && (
          <div className="market-step1__parameters">
            <div className="market-step1__parameters_settings">
              <div ref={categoryRef}>
                <Faqed text={descriptionCategory}>
                  <p className="market-step1__label">
                    Укажите категорию продвижения поисковой фразы
                  </p>
                </Faqed>
                <CategoryInput store={store} error={store.categoryError} />
              </div>
              <div ref={cityRef}>
                <Faqed text={descriptionCity}>
                  <p className="market-step1__label">
                    Укажите город продвижения поисковой фразы
                  </p>
                </Faqed>
                <CityInput onChange={onChangeAddress} error={store.cityError} />
              </div>
            </div>
            <div className="market-step1__parameters_settings-phrase" ref={searchPhrasesRef}>
              <p className="market-step1__label">Какие поисковые фразы надо продвинуть на {getDativeCase(nameSite)}?</p>
              <p className="market-step1__note">
                Делайте более <b>точные поисковые запросы</b>{" "}
                и не используйте запросы с одним поисковым словом
              </p>
              <Phrase store={store} />
            </div>
          </div>
        )}
        <Button
          className="market-step1__button-create"
          title="Создать проект"
          onClick={onClickCreateProject}
        />
        {store.instructionsScreen && (
          <InstructionsScreenPopup
            marketStore={store}
            popupStore={popupStore}
          />
        )}
      </div>
    );
  }
);
