import like from 'assets/images/activities/like.svg';
import busket from 'assets/images/activities/busket.svg';
import click from 'assets/images/activities/click.svg';
import dislike from 'assets/images/activities/dislike.svg';
import download from 'assets/images/activities/download.svg';
import eye from 'assets/images/activities/eye.svg';
import message from 'assets/images/activities/message.svg';
import glasses from 'assets/images/activities/glasses.svg';
import heart from 'assets/images/activities/heart.svg';
import open from 'assets/images/activities/open.svg';
import people from 'assets/images/activities/people.svg';
import phone from 'assets/images/activities/phone.svg';
import repost from 'assets/images/activities/repost.svg';
import search from 'assets/images/activities/search.svg';
import star from 'assets/images/activities/star.svg';
import survey from 'assets/images/activities/survey.svg';
import time from 'assets/images/activities/time.svg';
import upPrice from 'assets/images/upDoubleArrow.svg';
import AppStore from 'AppStore';

export const NO_PROJECTS_COMMENT_FOR_DRAFTS = "Неоплаченных проектов нет, все активны или в архиве";
export const NO_PROJECTS_COMMENT_FOR_ARCHIVE = "Завершите активный проект, и здесь он будет всегда под рукой";
export const NO_PROJECTS_COMMENT_FOR_ACTIVE = "Возобновите проект из архива, запустите проект из черновиков или создайте новый";

/** @param {Date} d */
export function formatReportDate(d) {
  let str = "";
  str += d.getDate().toString().padStart(2, "0");
  str += ".";
  str += (1 + d.getMonth()).toString().padStart(2, "0");
  str += ".";
  str += d.getFullYear();
  str += " ";
  str += d.getHours().toString().padStart(2, "0");
  str += ":";
  str += d.getMinutes().toString().padStart(2, "0");
  return str;
}

export function formatReportDateArchive(dateString) {
  const d = new Date(dateString);
  const year = d.getFullYear().toString().slice(-2);
  const month = (d.getMonth() + 1).toString().padStart(2, "0");
  const day = d.getDate().toString().padStart(2, "0");
  return `${day}.${month}.${year}`;
}

export function formatTime(time) {
  const hours = Math.floor(time / (1000 * 60 * 60)).toString().padStart(2, '0');
  const minutes = Math.floor((time / (1000 * 60)) % 60).toString().padStart(2, '0');
  const seconds = Math.floor((time / 1000) % 60).toString().padStart(2, '0');
  return `${hours}:${minutes}:${seconds}`;
};

export function getHoursFormatTime(time) {
  const hours = Math.floor(time / (1000 * 60 * 60)).toString().padStart(1, "0");
  return `${hours} ч.`;
}

export function findActivityCount(activities, type) {
  return activities?.find(activity => activity.type === type)?.count ?? 0;
}

/**
 * @param {Object[]} reports
 * @param {String} status
 * @returns {boolean}
 */
export function hasProjectReportWithStatus(reports, status) {
  if (!reports)
    return reports;
  return !!reports.find(report => report.status === status);
}

/**
 * @param {Object[]} reports
 * @param {String} status
 * @returns {Object[]}
 */
export function getProjectReportsWithStatus(reports, status) {
  return reports?.filter(report => report.status === status);
}

/**
 * @param {Object[]} reports
 * @param {String} status
 * @returns {Object[]}
 */
export function getProjectReportsWithNotStatus(reports, status) {
  return reports?.filter(report => report.status !== status);
}

export function calcTotalProjectActions(project) {
  if (!project)
    return 0;

  const actionsMap = AppStore.findMarketStoreById(project.type.toLowerCase())?.market.actionsMap;
  return project.activities?.reduce((total, action) =>
    total + action.count * (actionsMap?.[action.type]?.subactions ?? 1), 0) ?? 0;
}

export {
  like,
  busket,
  click,
  dislike,
  download,
  eye,
  message,
  glasses,
  heart,
  open,
  people,
  phone,
  repost,
  search,
  star,
  survey,
  time,
  upPrice,
}

export const AUTO_AI_DATE_TEXT
  = 'Наши AI-алгоритмы подберут лучшее время активности с учетом исторических данных по посещаемости площадки';
