
import React, { Suspense } from "react";

import Button from "components/UI/Button/Button";

import { useMatchMedia } from "utils/hooks/use-match-media";

import "./FormStep5.scss";

const PDFGenerator = React.lazy(() => import("../../PDFGenerator"));

const FormStep5 = ({ onClick }) => {
  const { isMobile } = useMatchMedia();

  return (
    <div className="form-step5">
        <div className="form-step5-container">
          <div className="form-step5-icon" />
          <div className="form-step5-description">
            <p className="form-step5-title">Счет готов</p>
            <p className="form-step5-text">
              Мы сформировали счет. Скачайте его по кнопке ниже, если загрузка
              не началась автоматически
            </p>
          </div>
        </div>
        <Suspense fallback={<div>Формирование счета...</div>}>
          <PDFGenerator />
        </Suspense>
        {isMobile ? (
          <Button
            title="Закрыть"
            className="form-step5-button-close"
            onClick={onClick}
          />
        ) : (
          <></>
        )}
      </div>
  );
};

export default FormStep5;
