import { observer } from "mobx-react";

import { Popup } from "components/Popup/Popup";
import { ButtonWithIcon } from "components/UI/ButtonWithIcon/ButtonWithIcon";
import InfoPoint from "components/UI/InfoPoint/InfoPoint";

import AppStore from "AppStore";
import popupBoxStore from "PopupBox/PopupBoxStore";

import list from "assets/images/botIcon/list.svg";
import time from "assets/images/botIcon/time.svg";
import clock from "assets/images/botIcon/clock.svg";
// import link from "assets/images/botIcon/link.svg";
import telegram from "assets/images/botIcon/tg-logo.svg";
import number2 from "assets/images/botIcon/number-2.svg";

import "./BotModal.scss";

export const BotModal = observer(() => {

  const infoPointsTop = [
    { icon: list, text: "Отправим отчеты по выбранным типам заданий", },
    { icon: time, text: "Сообщим о времени на проверку", },
    { icon: clock, text: "Предупредим об автопринятии за 24 ч., чтобы вы точно успели проверить отчет", },
    // { icon: link, text: "Прикрепим ссылку на отчет исполнителя", },
  ];

  const infoPointsBottom = [
    { icon: number2, text: "Перейдите в Telegram-бот и вставьте код. Следуйте инструкциям бота", },
  ];

  const handleCopyCode = (code) => {
    navigator.clipboard.writeText(code);
    popupBoxStore.showInfo("Код скопирован");
  }

  return (
    <Popup modalType={`bot-modal`} title="Уведомления в Telegram">
      <div className="bot-modal__container">
        <div className="bot-modal__info_points-top">
          {infoPointsTop.map(({ icon, text }, index) => <InfoPoint key={index} icon={icon} text={text} />)}
        </div>

        {AppStore.telegramId === null && <div className="bot-modal__info_points">
          <p className="bot-modal__info_points-title">Как подключить?</p>
          <div className="bot-modal__info_points-bottom">
            <div className="bot-modal__info_points-item">
              <div className="bot-modal__info_points-item-icon" />
              <p className="bot-modal__info_points-item-text">Скопируйте код:</p>
              <div className="bot-modal__info_points-item-code">
                <p className="bot-modal__info_points-item-code-text">{AppStore.OTP}</p>
                <div className="bot-modal__info_points-item-code-icon" onClick={() => handleCopyCode(AppStore.OTP)} />
              </div>
            </div>
            {infoPointsBottom.map(({ icon, text }, index) => <InfoPoint key={index} icon={icon} text={text} />)}
          </div>
        </div>}

        <div className="bot-modal__buttons">
          <ButtonWithIcon
            title="Перейти в Telegram-бота"
            className="bot-modal__button-bot"
            icon={telegram}
            ariaLabel="Кнопка бота-уведомлений"
            to={(process.env.REACT_APP_BOT_ADDRESS ?? 'https://t.me/BoostclickBot') + (AppStore.telegramId ? '' : `?start=${AppStore.OTP}`)}
            onClick={() => {}}
          />
        </div>
      </div>
    </Popup>
  );
});

export default BotModal;
