import { Link } from "react-router-dom";

import Badges from "components/UI/Badges/Badges";

import "./MarketItem.scss";

const MarketItem = ({
  market,
  index,
  onClick,
  linkTo,
  showBadgeStatus,
  containerClassName,
  itemClassName,
  logoClassName,
  titleClassName,
  showArrow = false,
  showBadge = false,
  isSelected = false,
}) => {

  const logoClass = isSelected
    ? `${logoClassName} ${logoClassName}-active`
    : `${logoClassName} ${logoClassName}-disabled`;

  const titleClass = isSelected
    ? `${titleClassName} ${titleClassName}-active`
    : `${titleClassName}`;

  return (
    <Link
      key={index}
      to={linkTo}
      className={containerClassName}
      onClick={onClick}
    >
      <div className={itemClassName}>
        <div className={`${itemClassName}-container`}>
          {isSelected && <div className="selected-line" />}
          <div className={logoClass} style={{ backgroundImage: `url(${isSelected ? market.icon : market.disabledIcon})` }} />
          <p className={titleClass}>{market.titlePlaces}</p>
        </div>
        {showArrow && <div className={`${itemClassName}-arrow`} />}
      </div>
      {showBadge && <Badges status={showBadgeStatus} />}
    </Link>
  );
};

export default MarketItem;
