import "./ListBlock.scss";

export default function ListBlock({
  type,
  items,
  bold,
  blockTitle,
  classes = [],
  style,
}) {
  const ListComponent = type === "ul" ? "ul" : "ol";

  return (
    <>
      <ListComponent
        className={`list-block__list ${classes.join(" ")} ${
          type === "ol" ? "ol" : ""
        }`}
        style={style}
      >
        {blockTitle && <p>{blockTitle}</p>}

        {items &&
          items.map((item, index) => (
            <li
              key={index}
              className={`list-block__list-item ${
                type === "ol" ? "ol-li" : ""
              }`}
              style={item.style}
            >
              {item.bold && <span>{item.bold}</span>}
              {item.text}
            </li>
          ))}
      </ListComponent>
    </>
  );
}
