import Button from "components/UI/Button/Button";
import { ButtonWithIcon } from "components/UI/ButtonWithIcon/ButtonWithIcon";
import { TimeArticle } from "../TimeArticle/TimeArticle";

import arrowUpRight from 'assets/images/arrowUpRight.svg';

import "./OtherArticle.scss";

export default function OtherArticle({ article, isExternal }) {
  const { type, title, time } = article;

  return (
    <>
      <TimeArticle
        time={time}
        style={{
          marginTop: "8px",
          marginRight: "8px",
        }}
      />
      <div className="other-articles__info">
        <div className="other-articles__type">{type}</div>
        <h2 className="other-articles__title">{title}</h2>
        {!isExternal && <Button title='Читать' className="other-articles__info-button" />}
        {isExternal && <ButtonWithIcon title='Читать на Дзене' className="other-articles__info-button" icon={arrowUpRight} />}
      </div>
    </>
  );
}
