import { Fragment, useEffect } from "react";
import { Helmet } from "react-helmet";

import Navigation from "./UI/Navigation/Navigation";
import HeaderArticle from "./UI/HeaderArticle/HeaderArticle";
import Footnote from "./UI/Footnote/Footnote";
import Definition from "./UI/Definition/Definition";
import TextBlock from "./UI/TextBlock/TextBlock";
import TitleBlock from "./UI/TitleBlock/TitleBlock";
import Description from "./UI/Description/Description";
import ListBlock from "./UI/ListBlock/ListBlock";
import Subtotal from "./UI/Subtotal/Subtotal";
import GridBlock from "./UI/GridBlock/GridBlock";

import articlesData from "./articlesData";

import "./Article.scss";

export default function Article({ id }) {
  const article = articlesData.find((a) => a.id === id);

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  if (!article) {
    return <div>Статья не найдена</div>;
  }

  return (
    <main className="article">
      <Helmet>
        <title>{article.metaTitle}</title>
        <meta name="description" content={article.metaDescription} />
        <meta name="viewport" content="initial-scale=0" />
      </Helmet>

      <HeaderArticle
        articleData={article}
        hasDescription={article.hasDescriptionHeader}
      />
      <div className="article__container">
        <div className="text-block">
          {article.section.map((section, index) => {
            return (
              <TextBlock key={index}>
                <TitleBlock title={section.title} id={section.id} />
                {section.content.map((content, idx) => (
                  <Fragment key={`${index}-${idx}`}>
                    {content.type === "Description" && (
                      <Description
                        text={content.text}
                        bold={content.bold}
                        classes={content.classes}
                        style={content.style}
                      />
                    )}
                    {content.type === "Definition" && (
                      <Definition
                        title={content.title}
                        text={content.text}
                        classes={content.classes}
                        style={content.style}
                      />
                    )}
                    {content.type === "Footnote" && (
                      <Footnote
                        textItalic={content.textItalic}
                        boldTextItalic={content.boldTextItalic}
                        classes={content.classes}
                        style={content.style}
                      />
                    )}
                    {content.type === "List" && (
                      <ListBlock
                        type={content.listType}
                        items={content.items}
                        bold={content.bold}
                        blockTitle={content.blockTitle}
                        classes={content.classes}
                        style={content.style}
                      />
                    )}
                    {content.type === "Subtotal" && (
                      <Subtotal
                        title={content.title}
                        text={content.text}
                        blocks={content.blocks}
                        classes={content.classes}
                        style={content.style}
                      />
                    )}
                    {content.type === "Grid" && (
                      <GridBlock
                        title={content.title}
                        description={content.description}
                        items={content.items}
                        classes={content.classes}
                        style={content.style}
                      />
                    )}
                    {content.type === "Image" && (
                      <img
                        className="img"
                        src={content.src}
                        alt={content.alt}
                        style={content.style}
                      />
                    )}
                  </Fragment>
                ))}
              </TextBlock>
            );
          })}
        </div>
        <div>
          <Navigation links={article.navigation} />
        </div>
      </div>
    </main>
  );
}
