import { observer } from "mobx-react";
import { useEffect, useMemo, useState } from "react";

import ProjectsPlate from "../components/ProjectsPlate/ProjectsPlate";
import ProjectsArchiveRow from "./ProjectsArchiveRow/ProjectsArchiveRow";
import ProjectsFilter from "../components/ProjectsFilter/ProjectsFilter";

import AppStore from "AppStore";

import { NO_PROJECTS_COMMENT_FOR_ARCHIVE } from "../../Projects/ProjectsConstants";

import "./ProjectsArchive.scss";

const ProjectsArchive = observer(({ projects }) => {
  const comment = !projects || projects.length ? "" : NO_PROJECTS_COMMENT_FOR_ARCHIVE;
  const [expandedTypes, setExpandedTypes] = useState([]);
  const [openDropdown, setOpenDropdown] = useState(null);

  const groupedProjects = useMemo(() => {
    return projects?.reduce((acc, project) => {
      const type = project.type;
      if (!acc[type]) {
        acc[type] = [];
      }
      acc[type].push(project);
      return acc;
    }, {});
  }, [projects]);

  useEffect(() => {
    if (groupedProjects) {
      setExpandedTypes(Object.keys(groupedProjects || {}));
    }
  }, [groupedProjects]);

  const toggleType = (type) => {
    if (expandedTypes.includes(type)) {
      setExpandedTypes(expandedTypes.filter((t) => t !== type));
    } else {
      setExpandedTypes([...expandedTypes, type]);
    }
  };

  const toggleDropdown = (type) => {
    setOpenDropdown(openDropdown === type ? null : type);
  };

  return (
    <div className="projects-archive">
      {projects?.length ? (
        <>
          {Object.entries(groupedProjects).map(([type, typeProjects]) => {
            const filteredProjects = AppStore.getFilteredProjects('archive', type, typeProjects);
            const cities = AppStore.getCitiesForType(typeProjects);

            return (
              <div key={type}>
                <div className="projects-archive__row">
                  <div className="projects-archive__row-header" onClick={() => toggleType(type)} >
                    <div className={`projects-archive__row-accordion-icon ${ expandedTypes.includes(type) ? "expanded" : "" }`} />
                    <h3 className="projects-archive__row-title">Проекты {type === "AVITO" ? "ОБЪЯВЛЕНИЯ" : type} ( {filteredProjects.length ? filteredProjects.length || typeProjects.length : 0} шт.)</h3>
                  </div>
                  <ProjectsFilter
                    cities={cities}
                    selectedCities={AppStore.selectedCities.archive}
                    type={type}
                    typeProjects={typeProjects}
                    onChange={(type, city) => AppStore.setSelectedCity('archive', type, city)}
                    isOpen={openDropdown === type}
                    onToggle={() => toggleDropdown(type)}
                  />
                </div>

                <div className={`projects-archive__row-reports ${expandedTypes.includes(type) ? "expanded" : ""}`}>
                  {filteredProjects.length > 0 ? 
                    filteredProjects.map((project, i) => <ProjectsArchiveRow key={i} project={project} />) 
                  :
                    <div className="projects-archive__no-results">Нет результатов</div>
                  }
                </div>
              </div>
            );
          })}
        </>
      ) : (
        <ProjectsPlate title="Тут пусто" comment={comment} />
      )}
    </div>
  );
})

export default ProjectsArchive;