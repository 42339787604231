import { observer } from "mobx-react";
import { Link, useNavigate } from "react-router-dom";

import Button from "components/UI/Button/Button";
import CheckBox from "components/UI/CheckBox/CheckBox";
import { Popup } from "../Popup/Popup";

import AppStore from "../../AppStore";
import popupStore from "../Popup/PopupStore";
import messageBoxStore from "MessageBox/MessageBoxStore";

import { getCookie, setCookie } from "utils/cookie";
import { rulesList } from "./acceptRulesModalConstants";
import { ApiError } from "client";

import { EVENTS, metricsEvent } from "metrics";

import "./AcceptRulesModal.scss";

export const AcceptRulesModal = observer(() => {
  const marketStore = AppStore.currentMarketStore;
  const navigate = useNavigate();

  const handleAgreeClick = async () => {
    AppStore.setAgreedWithRules(true);
    try {
      await AppStore.handleAgreeClickForLoggedInUser();
      AppStore.resetAgreed();
      popupStore.close();
    } catch (e) {
      if (e instanceof ApiError) {
        messageBoxStore.showError(e.message);
        return;
      } else {
        console.error("Произошла ошибка при соглашении с правилами сайта:", e);
      }
    }
  };

  const handleAgreeClickCreatingProject = async () => {
    marketStore.setAgreedRulesCreatingProject(true);
    try {
      const projectId = await marketStore.createNewProject();
      if (!projectId)
        return;

      metricsEvent(EVENTS.PROJECT_SUBMIT);

      const redirectUrl = getCookie("redirect_url");
      if (redirectUrl) {
        setCookie("redirect_url", "", { expires: -1 });
        navigate(redirectUrl);
      } else {
        navigate("/projects");
        popupStore.close();
      }

      AppStore.resetAgreed();
      AppStore.clearUploadedFile();
      marketStore.clearFieldsAfterCreateProjects();
    } catch (e) {
      if (e instanceof ApiError) {
        messageBoxStore.showError(e.message);
        return;
      } else {
        console.error("Произошла ошибка при создании проекта:", e);
      }
    }
  };

  const handleRaisePriceConfirm = async () => {
    try {
      if (
        popupStore.reportDataForRaisePrice &&
        popupStore.newExtraPriceForRaisePrice
      ) {
        await popupStore.updateTaskExtraPrice(
          popupStore.reportDataForRaisePrice,
          popupStore.newExtraPriceForRaisePrice
        );
        popupStore.setReportDataForRaisePrice(null, null);
      }
      AppStore.resetAgreed();
      popupStore.close();
      popupStore.setShowModalAuction(false);
    } catch (e) {
      if (e instanceof ApiError) {
        messageBoxStore.showError(e.message);
        return;
      } else {
        console.error("Произошла ошибка поднятии стоимости отзыва:", e);
      }
    }
  };

  return (
    <Popup
      modalType="accept-rules"
      title="Правила и условия предоставления услуг BOOSTCLICK"
      subtitle={
        popupStore.agreedRulesRaisePrice
          ? "Перед повышением ставки обратите внимание!"
          : ""
      }
    >
      <ol className="accept-rules__list">
        {(popupStore.agreedRulesRaisePrice
          ? rulesList.forRaisePrice
          : rulesList.main
        ).map((item, index) => (
          <li key={index}>{item}</li>
        ))}
        {!popupStore.agreedRulesRaisePrice && (
          <li>
            Я прочитал(-a) и согласен(-a) с{" "}
            <Link
              className="accept-rules__subinfo-link"
              to="/docs/offer.pdf"
              target="_blank"
            >
              договором-офертой
            </Link>
          </li>
        )}
      </ol>
      <div className="accept-rules__check">
        <CheckBox
          title="С правилами и условиями ознакомлен(-а)"
          classes={["accept-rules__check-chose"]}
          checked={AppStore.agreedRules}
          onClick={() => AppStore.setAgreedRules(!AppStore.agreedRules)}
        />
        {!marketStore.isCreatingProject &&
          !popupStore.agreedRulesRaisePrice && (
            <CheckBox
              title={[
                "С ",
                <Link
                  className="accept-rules__subinfo-link"
                  to="/docs/policy.pdf"
                  target="_blank"
                >
                  политикой конфиденциальности
                </Link>,
                " ознакомлен(-а)",
              ]}
              classes={["accept-rules__check-chose"]}
              checked={AppStore.agreedPrivacy}
              onClick={() => AppStore.setAgreedPrivacy(!AppStore.agreedPrivacy)}
            />
          )}
      </div>
      {!marketStore.isCreatingProject && !popupStore.agreedRulesRaisePrice && (
        <div className="accept-rules__buttons">
          <Link
            className="accept-rules__support"
            to={process.env.REACT_APP_SUPPORT_ADDRESS}
            target="_blank"
            onClick={() => { metricsEvent(EVENTS.TELEGRAM_HELP) }}
          >
            <Button title="Задать вопрос" />
          </Link>
          <Button
            title="Согласен(-а)"
            onClick={handleAgreeClick}
            disabled={!AppStore.agreedRules || !AppStore.agreedPrivacy}
          />
        </div>
      )}
      {marketStore.isCreatingProject && !popupStore.agreedRulesRaisePrice && (
        <div className="accept-rules__buttons">
          <Link
            className="accept-rules__support"
            to={process.env.REACT_APP_SUPPORT_ADDRESS}
            target="_blank"
            onClick={() => { metricsEvent(EVENTS.TELEGRAM_HELP) }}
          >
            <Button title="Задать вопрос" />
          </Link>
          <Button
            title="Согласен(-a)"
            onClick={handleAgreeClickCreatingProject}
            disabled={!AppStore.agreedRules}
          />
        </div>
      )}
      {popupStore.agreedRulesRaisePrice && !marketStore.isCreatingProject && (
        <div className="accept-rules__buttons">
          <Link
            className="accept-rules__support"
            to={process.env.REACT_APP_SUPPORT_ADDRESS}
            target="_blank"
            onClick={() => { metricsEvent(EVENTS.TELEGRAM_HELP) }}
          >
            <Button title="Задать вопрос" />
          </Link>
          <Button
            title="Согласен(-a)"
            onClick={handleRaisePriceConfirm}
            disabled={!AppStore.agreedRules}
          />
        </div>
      )}
    </Popup>
  );
});
