import Intro from "components/Intro/Intro";

import "./IntroWithPoints.scss";

export default function IntroWithPoints({
  title,
  comment,
  points,
  marketPath
}) {

  return (
    <Intro title={title} comment={comment} marketPath={marketPath}>
      <div className="intro-with-points__info-container">
        <p className="intro-with-points__comment">{comment}</p>
        <ul className="intro-with-points__list">
          {points?.map((point, i) => (
            <li key={i}>{point}</li>
          ))}
        </ul>
      </div>
    </Intro>
  );
}
