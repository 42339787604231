import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';

import './FoldableSection.scss';

export default function FoldableSection({ children, className, expanded, onTransitionEnd }) {

  const ref = useRef();

  const [height, setHeight] = useState(0);
  const [animated, setAnimated] = useState(!expanded);

  useEffect(() => {
    setHeight(expanded ? ref.current.scrollHeight : 0);
    if (!animated && height)
      setAnimated(true);
  }, [expanded, animated, height]);

  const header = children?.[0] ?? children;
  const data = children?.[1];

  return (
    <div className={classNames('foldable-section', className)}>
      {header && <div className='foldable-section__header'>
        {header}
      </div>}
      {data &&
        <div
          ref={ref}
          style={{ height: height }}
          className={classNames('foldable-section__main', animated ? 'foldable-section__main_animated' : '')}
          onTransitionEnd={onTransitionEnd}
        >
          {data}
        </div>
      }
    </div>
  );
}
