import { useEffect } from "react";

import background from "assets/images/main/phone.png";

import "./AboutSection.scss";

const AboutSection = ({ isMobile }) => {
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const aboutSection = document.querySelector(".main__about");
      const bgImage = document.querySelector(".main__about_bg-image");

      if (aboutSection && bgImage) {
        const sectionTop = aboutSection.offsetTop;
        const sectionHeight = aboutSection.offsetHeight;

        if (
          scrollPosition > sectionTop - window.innerHeight &&
          scrollPosition < sectionTop + sectionHeight
        ) {
          const relativeScroll =
            scrollPosition - (sectionTop - window.innerHeight);
          const translateY = relativeScroll * 0.3;
          bgImage.style.transform = `translateY(-${translateY}px)`;
        }
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <section className="main__about">
      <h2 className="main__about-title">Продвижение <br /> <span>без наценок</span></h2>
      <div className="main__about_bg">
        <img src={background} alt="background" className="main__about_bg-image" />
        <div className="main__about_bg-decore1" />
        <div className="main__about_bg-decore2" />
      </div>

      <div className="main__about-text">
        <p><strong>Boostclick</strong> — простой способ создания задач и полный {!isMobile && <br />} контроль над их выполнением через проверку отчетов</p>
        <p>Вы размещаете задачи, а исполнители помогают вам с продвижением. {isMobile && <br />} Без посредников и агентств — только прямое взаимодействие и максимальная выгода</p>
      </div>
    </section>
  );
};

export default AboutSection;
