import { observer } from "mobx-react";

import "./AnswerReportModal.scss";

export const AnswerReportModal = observer(({ report }) => {
  const prepareAnswer = (answer) => {
    const decodedAnswer = answer.replace(/&(#?\w+);/g, (match, entity) => {
      return String.fromCharCode(parseInt(entity.replace(/\W/g, ""), 10));
    });

    const lines = [];
    let currentLine = "";
    let foundNewParagraph = false;
    const newParagraphRegex = /\r\n\r\n/;

    for (let i = 0; i < decodedAnswer.length; i++) {
      const char = decodedAnswer[i];
      if (
        char === "\r" &&
        decodedAnswer.slice(i, i + 4).match(newParagraphRegex)
      ) {
        lines.push(currentLine.trim());
        currentLine = "";
        foundNewParagraph = true;
        i += 3;
      } else if (
        char === "\r" &&
        decodedAnswer[i + 1] === "\n" &&
        !foundNewParagraph
      ) {
        lines.push(currentLine.trim());
        currentLine = "";
        i++;
      } else {
        currentLine += char;
      }
    }

    if (currentLine.trim()) {
      lines.push(currentLine.trim());
    }

    return lines;
  };

  return (
    <div
      className={`report-modal__subplate ${
        report.answer
          ? "report-modal__answers-container"
          : "report-modal__answers-container-empty"
      }`}
    >
      <h3>Ответы исполнителя</h3>
      {report.answer ? (
        prepareAnswer(report.answer).map((answer, index) => (
          <p key={index} style={{ overflowWrap: "anywhere" }}>
            {answer}
          </p>
        ))
      ) : (
        <p className="report-modal__subplate-empty-answer">
          Исполнитель не ответил на проверочные вопросы. Задание, возможно,
          выполнено некачественно, рекомендуем отклонить.
        </p>
      )}
    </div>
  );
});
