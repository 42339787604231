import { observer } from "mobx-react";
import { useEffect, useState } from "react";

import ProjectsPlate from "../components/ProjectsPlate/ProjectsPlate";
import { ProjectsActiveRow } from "./ProjectsActiveRow/ProjectsActiveRow";
import ProjectsFilter from "../components/ProjectsFilter/ProjectsFilter";

import AppStore from "AppStore";

import { NO_PROJECTS_COMMENT_FOR_ACTIVE } from "../../Projects/ProjectsConstants";

import "./ProjectsActive.scss";

const ProjectsActive = observer(({ projects }) => {
  const comment = !projects || projects.length ? "" : NO_PROJECTS_COMMENT_FOR_ACTIVE;
  const allTypes = projects?.map((project) => project.type) || [];

  const [expandedTypes, setExpandedTypes] = useState(allTypes);
  const [openDropdown, setOpenDropdown] = useState(null);

  const toggleType = (type) => {
    if (expandedTypes.includes(type)) {
      setExpandedTypes(expandedTypes.filter((t) => t !== type));
    } else {
      setExpandedTypes([...expandedTypes, type]);
    }
  };

  const groupedProjects = projects?.reduce((acc, project) => {
    const type = project.type;
    if (!acc[type]) {
      acc[type] = [];
    }
    acc[type].push(project);
    return acc;
  }, {});

  let indexToExpand = projects?.findIndex((project) =>
    project.reports?.find((report) => report.status === "ready")
  );
  if (indexToExpand < 0 && projects?.length) indexToExpand = 0;

  useEffect(() => {
    const projectTypes = new Set(projects?.map((project) => project.type));
    setExpandedTypes(Array.from(projectTypes));
  }, [projects]);

  const toggleDropdown = (type) => {
    setOpenDropdown(openDropdown === type ? null : type);
  };

  return (
    <div className='projects-active'>
      {projects?.length ? (
        <>
          {Object.entries(groupedProjects).map(([type, typeProjects]) => {
            const filteredProjects = AppStore.getFilteredProjects('active', type, typeProjects);
            const cities = AppStore.getCitiesForType(typeProjects);

            return (
              <div key={type}>
                <div className="projects-active__row">
                  <div className="projects-active__row-header" onClick={() => toggleType(type)} >
                    <div className={`projects-active__row-accordion-icon ${ expandedTypes.includes(type) ? "expanded" : "" }`} />
                    <h3 className="projects-active__row-title">Проекты {type === "AVITO" ? "ОБЪЯВЛЕНИЯ" : type} ( {filteredProjects.length ? filteredProjects.length || typeProjects.length : 0} шт.)</h3>
                  </div>
                  <ProjectsFilter
                    cities={cities}
                    selectedCities={AppStore.selectedCities.active}
                    type={type}
                    typeProjects={typeProjects}
                    onChange={(type, city) => AppStore.setSelectedCity('active', type, city)}
                    isOpen={openDropdown === type}
                    onToggle={() => toggleDropdown(type)}
                  />
                </div>
                <div className={`projects-active__row-reports ${expandedTypes.includes(type) ? "expanded" : ""}`}>
                  {filteredProjects.length > 0 ? 
                    filteredProjects.map((project, i) => <ProjectsActiveRow key={i} project={project} initialExpanded={i === indexToExpand} />) 
                  :
                    <div className="projects-active__no-results">Нет результатов</div>
                  }
                </div>
              </div>
            );
          })}
        </>
      ) : (
        <ProjectsPlate title="Тут пусто" comment={comment} />
      )}
    </div>
  );
})

export default ProjectsActive;
