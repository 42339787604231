import { observer } from "mobx-react";
import { Link } from "react-router-dom";

import Badges from "components/UI/Badges/Badges";

import "./ActionLink.scss";

const ActionLink = observer(
  ({ action, path, isAnimated, isMode, marketTitle, status, isMobile, target = '_self', }) => {
    return (
      <Link
        to={path}
        onClick={() => window.scrollTo(0, 0)}
        className={`main__action-link ${isAnimated ? "animated" : ""}`}
        target={target}
      >
        <div className="main__action-link-info">
          <div className="main__action-link-icon" style={{ backgroundImage: `url(${action.icon})` }} />
          <div className="main__action-link-title">
            <p className="main__action-link-label">{isMode && !action.title.includes(marketTitle) ? `${action.title} ${marketTitle}` : action.title}</p>
            {!isMobile && action.titleComment && <p className="main__action-link-sublabel">{action.titleComment}</p>}
          </div>
        </div>
        {isMobile &&
          <div className="main__action-link-container">
            <p className="main__action-link-sublabel">{action.titleComment}</p>
            <div className="main__action-link-arrow" />
          </div>
        }
        {!isMobile && <div className="main__action-link-arrow" />}
        {status && <Badges status={status} />}
      </Link>
    );
  }
);

export default ActionLink;
