import "./Notice.scss";

export const Notice = ({ notice }) => {
  return (
    <ul className="avito-step2__notices-container">
      {notice.map((item, index) => (
        <li key={index} className="avito-step2__notice">
          {item}
        </li>
      ))}
    </ul>
  );
};
