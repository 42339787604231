import ListBlock from "../ListBlock/ListBlock";
import "./Subtotal.scss";

export default function Subtotal({ title, text, blocks, classes = [], style }) {
  return (
    <div className={`subtotal ${classes.join(" ")}`} style={style}>
      <p className="subtotal-bold">{title}</p>
      <p className="subtotal-text">{text}</p>
      {blocks && (
        <div className="blocks">
          <div className="block1">
            <ListBlock
              type={blocks.block1[0].listType}
              items={blocks.block1[0].items}
							blockTitle={blocks.block1[0].blockTitle}
            />
          </div>
          <div className="block2">
            <ListBlock
              type={blocks.block2[0].listType}
              items={blocks.block2[0].items}
							blockTitle={blocks.block2[0].blockTitle}
            />
          </div>
        </div>
      )}
    </div>
  );
}
