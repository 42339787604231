import { Fragment } from 'react';
import classNames from 'classnames';

import './CheckBox.scss';

export default function CheckBox({ title, checked, onClick, classes = [], markClass = '', innerMarkClass = '' }) {
  return (
    <div className={`check-box ${classes.join(' ')}`} onClick={onClick}>
      <div className={classNames('check-box__mark', markClass, checked ? 'check-box__mark-active' : '')}>
        <div className={classNames(innerMarkClass, 'check-box__mark-inner', checked ? 'check-box__mark-inner_checked' : '')} />
      </div>
      <div className='check-box__title'>
        {Array.isArray(title) 
          ? title.map((item, i) => <Fragment key={i}>{item}</Fragment>)
          : title
        }
      </div>
    </div>
  );
}
