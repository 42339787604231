import { Fragment } from "react";
import classNames from "classnames";

import "./Hint.scss";

export const Hint = ({
  title,
  text,
  buttonText,
  strong = false,
  strongTitle,
  strongText,
  onClick,
  className,
}) => {
  return (
    <div className={classNames("hint", className)}>
      <p className="hint__title">{title}</p>
      <div className="hint__text">
        {Array.isArray(text)
          ? text.map((item, i) => <Fragment key={i}>{item}</Fragment>)
          : text
        }
      </div>
      {buttonText && 
        <button className="hint__button" type="button" onClick={onClick}>
          {buttonText}
        </button>
      }
      {strong && (
        <div className="hint__strong">
          <p className="hint__strong-title">{strongTitle}</p>
          <p className="hint__strong-text">
            {Array.isArray(strongText)
              ? strongText.map((item, i) => <Fragment key={i}>{item}</Fragment>)
              : strongText
            }
          </p>
        </div>
      )}
    </div>
  );
};
