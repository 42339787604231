import "./ProjectsActiveReportBlockWarn.scss";

export default function ProjectsActiveReportBlockWarn({ timer }) {
  return (
    <div className="projects-active-report-row__warn-info">
      <div className="projects-active-report-row__warn-info-icon" />
      <div className="projects-active-report-row__warn-info-lines-container">
        <p className="projects-active-report-row__warn-info-line-first">
          Проверьте отчет как можно скорее!
        </p>
        <p className="projects-active-report-row__warn-info-line-second">
          На проверку осталось менее 24 часов. Если вы не успеете проверить
          отчет,
          <br />
          система примет его автоматически — будет невозможно вернуть деньги.
        </p>
      </div>
      <div className="projects-active-report-row__warn-info-timer">
        <span>До автоодобрения</span>
        {timer}
      </div>
    </div>
  );
}
