import "./Definition.scss";

export default function Definition({ title, text, classes = [], style }) {
  return (
    <div className={`definition ${classes.join(" ")}`} style={style}>
      <p className="definition-bold">
        {title}
        <span className="definition-text">{text}</span>
      </p>
    </div>
  );
}
