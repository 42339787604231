import { observer } from "mobx-react";

import popupBoxStore from "PopupBox/PopupBoxStore";

import "./ProjectEditingLink.scss";

const ProjectEditingLink = observer(({ store, link }) => {
  const handleCopyCurrentLink = () => {
    navigator.clipboard.writeText(link);
    popupBoxStore.showInfo("Ссылка скопирована");
  }

  return (
    <div className="project-setup__links">
      {store.isEditingNewLink ? (
        <input
          className="project-setup__links-input"
          placeholder="Введите новую ссылку"
          value={store.newLink}
          onChange={(e) => store.setNewLink(e.target.value)}
          />
      ) : (
        <>
          <div className="project-setup__links-link">{link}</div>
          <div className="project-setup__links-icon" onClick={handleCopyCurrentLink} />
        </>
      )}
    </div>
  );
});

export default ProjectEditingLink;
