import { observer } from "mobx-react";

// import { ButtonWithIcon } from "components/UI/ButtonWithIcon/ButtonWithIcon";
import TextBlock from "components/UI/TextBlock/TextBlock";
import Toggler from "components/UI/Toggler/Toggler";
import CityInput from "pages/markets/Market/SettingsStep/componentsStep1/CityInput/CityInput";
import { Phrase } from "pages/markets/Market/SettingsStep/componentsStep1/Phrase/Phrase";

import { useMatchMedia } from "utils/hooks/use-match-media";

// import copyIcon from "assets/images/copy.svg";

import "./SettingsSeoYandex.scss";

const SettingsSeoYandex = observer(({
  store,
  cityRef,
  searchPhrasesRef,
  brandPhrasesRef,
}) => {
  const { isMobile } = useMatchMedia();

  const handleToggler = () => {
    store.setTogglerActive(!store.isTogglerActive);
  };

  const onChangeAddress = (e) => {
    store.setAddress(e.value);
    store.setCountry(e.data.country);
    store.setCity(e.data.city);
  };
  
  // TODO: убрать этот костыль потом
  if (store.market.id === 'yandexmaps') {
    store.brandPhrases = ["Яндекс Карты"];
    store.city = "Москва";
  };

  return (
    <div className="settings-seo-ya__container">
      <div className="settings-seo-ya__container-phrases" ref={searchPhrasesRef}>
        <div className="settings-seo-step__title">
          <TextBlock title={isMobile ? (
            <>
              {store.market.id === 'yandexseo' && "Фразы для поиска в Яндексе"}
              {store.market.id === 'yandexmaps' && "Название компании в Яндексе или Яндекс Картах"}
            </>
          ) : (
            <>
              {store.market.id === 'yandexseo' && "По каким фразам исполнитель должен искать ваш сайт?"}
              {store.market.id === 'yandexmaps' && "Название компании в Яндексе или Яндекс Картах"}
            </>
          )} titleClassName="settings-seo-step__label" />
          <TextBlock title={["Делайте точные", <span> поисковые запросы </span>, "не менее 2-3 слов",]} titleClassName="settings-seo-step__sublabel" />
        </div>

        <Phrase store={store} maxPhrases={15} phraseType="search" />

        <div className="settings-seo-ya__container-phrases_count">
          {/* <ButtonWithIcon title="Вставить из файла" icon={copyIcon} /> */}
          <p>{store.searchPhrases.length}<span>/15</span></p>
        </div>
      </div>

      {store.market.id === 'yandexseo' && (
        <>
          <div className="settings-seo-ya__container-brand" ref={brandPhrasesRef}>
            <div className="settings-seo-step__title toggle-button">
              <div className="settings-seo-step__title-container">
                <Toggler isActive={store.isTogglerActive} handler={handleToggler} />
                <TextBlock title={isMobile ? "Автодобавление бренда к фразам" : "Добавлять бренд к фразам автоматически"} text={isMobile ? "Если сайта нет в ТОП-20 выдачи Яндекса" : ["Это необходимо, если запрос не входит в ТОП-20.", <br />, "Так вас смогут найти исполнители и выполнить задание.",]} titleClassName="settings-seo-step__label toggle-button" />
              </div>
              {!isMobile && <TextBlock title={[<span><i>Совет: </i></span>, "Укажите бренд на кириллице, латинице, сокращением и т.д.", <br />, "Мы перемешаем варианты с фразами и отправим исполнителям",]} titleClassName="settings-seo-step__sublabel" />}
            </div>

            {store.isTogglerActive && isMobile &&
              <>
                <p className="settings-seo-ya__container-brand_subtitle">Название бренда</p>
                <Phrase store={store} maxPhrases={10} phraseType="brand" disabled={!store.isTogglerActive} />
                <TextBlock title={[<span><i>Совет: </i></span>, "Укажите бренд на кириллице, латинице, сокращением и т.д. Мы перемешаем варианты с фразами и отправим исполнителям",]} titleClassName="settings-seo-step__sublabel toggle-button" />
              </>
            }
            {!isMobile &&
              <>
                <p className="settings-seo-ya__container-brand_subtitle">Название бренда</p>
                <Phrase store={store} maxPhrases={10} phraseType="brand" disabled={!store.isTogglerActive} />
              </>
            }
            <div className="settings-seo-ya__container-brand_badge">Для молодого SEO</div>
          </div>

          <div className="settings-seo-ya__container-city" ref={cityRef}>
            <div className="settings-seo-step__title">
              <TextBlock title={isMobile ? "Город продвижения поисковой фразы" : "Выберите город продвижения поисковой фразы"} titleClassName="settings-seo-step__label" />
              {!isMobile && <TextBlock title={["Если", <span> за 24 часа </span>, "не найдется исполнитель в выбранном ГЕО — ", <br />, "задание станет доступно исполнителям из соседних городов",]} titleClassName="settings-seo-step__sublabel" />}
            </div>
            <CityInput onChange={onChangeAddress} error={store.cityError} />
            {isMobile && <TextBlock title={["Если", <span> за 24 часа </span>, "не найдется исполнитель в выбранном ГЕО — задание станет доступно исполнителям из соседних городов",]} titleClassName="settings-seo-step__sublabel settings-seo-city" />}
          </div>
        </>
      )}
    </div>
  );
});

export default SettingsSeoYandex;
