export const rulesList = {
  main: [
    "Я понимаю, что Boostclick является сервисом-посредником между заказчиком и исполнителем",
    "Я понимаю, что Boostclick не выполняет задания самостоятельно, а только передает их исполнителям",
    "Я понимаю, что Boostclick не несет ответственности за исполнителей и качество их работы",
    "Я понимаю, что я должен(-a) самостоятельно проверять и модерировать отчеты исполнителей",
    "Я понимаю, что если время на проверку отчетов истечет, исполнитель получит оплату автоматически, и вернуть ее будет невозможно",
  ],
  forRaisePrice: [
    [
      "Я понимаю, что повышение цены делает задание привлекательнее, но",
      <i> не гарантирует, что ее успеет взять добросовестный исполнитель</i>,
    ],
    [
      "Я понимаю, что",
      <i> BOOSTCLICK не несет ответственности за исполнителей</i>,
      " и качество их работы",
    ],
    [
      "Я понимаю, что",
      <i> я должен(-a) самостоятельно проверять и модерировать отчеты</i>,
      " исполнителей",
    ],
    "Я понимаю, что если время на проверку отчетов истечет, исполнитель получит оплату автоматически, и вернуть ее будет невозможно, даже если услуга не оказана",
  ],
};
