import {
  HEADER_ACTIONS_ICONS,
  ACTION_ICONS,
  MARKET_MODES,
  MARKET_MODE_STATUS,
  Market
} from '../market';

import yandexSeoIcon from "assets/images/MarketLogo/yandex.svg";
import yandexSeoDisabledIcon from "assets/images/MarketLogo/disabled/yandex.svg";
import yandexSeoLogo from "assets/images/MarketFullLogo/yandex.svg";

const YANDEX_SEO_FAQ = [
  {
    title: 'Что такое накрутка поведенческих факторов для Яндекса?',
    isH3: true,
    text:
      <div>
        Накрутка поведенческих факторов - это процесс искусственного увеличения показателей, которые влияют на ранжирование сайта в поисковой выдаче.
        <br />
        К ним относятся:
        <ul>
          <li>Просмотры страниц</li>
          <li>Время на сайте</li>
          <li>Глубина просмотра</li>
          <li>Количество переходов по ссылкам</li>
        </ul>
        <br />
        Сервис использует для накрутки ПФ реальных пользователей, которые получают задания на внутренней бирже.
        Исполнители получают только скриншот, что делает весь пользовательский путь максимально органическим.
        <br />
        <br />
        В отчетах мы не используем скриншоты и вопросы, на которые можно ответить с помощью копированного контента.
        <br />
        Это не позволит при накрутке ПФ Яндекс Метрике зафиксировать регулярные подозрительные действия.
      </div>
  },
  {
    title: 'Почему накрутка поведенческих факторов в Яндексе важна для сайта?',
    isH3: true,
    text:
      <p>
        Каждому поисковику необходимо понимать, по какому принципу ранжировать выдачу.
        <br />
        Одна из важных метрик при ранжировании - это поведение пользователей на сайте.
        <br />
        Чем лучше ваши метрики относительно конкурентов, тем полезнее ваш сайт будет казаться для поисковика, и тем выше могут быть ваши позиции.*
        <br />
        <br />
        * ПФ - это вспомогательный инструмент, и важно, чтобы была выполнена верная внутренняя и внешняя оптимизация.
      </p>
  },
  {
    title: 'Как вы накручиваете поведенческие факторы для Яндекса?',
    isH3: true,
    text:
      <p>
        Мы используем реальных пользователей, которые просматривают ваш сайт по заданным параметрам.
        <br />
        Это позволяет избежать искусственной накрутки и получить естественные, безопасные для поисковых систем показатели.
      </p>
  },
  {
    title: 'Безопасно ли пользоваться вашими услугами?',
    isH3: false,
    text:
      <p>
        Да, наши услуги безопасны. Мы используем только проверенные методы, которые не нарушают правила поисковых систем.
      </p>
  },
  {
    title: 'Как долго действует эффект от накрутки в Яндексе?',
    isH3: false,
    text:
      <p>
        Эффект от накрутки сохраняется до тех пор, пока поведение пользователей на вашем сайте более качественное относительно трафика конкурентов, а параметры внешней и внутренней оптимизации не ухудшаются.
      </p>
  },
  {
    title: 'Как заказать услуги?',
    isH3: false,
    text:
      <p>
        Оформите заказ на нашем сайте, указав необходимые параметры. После оплаты исполнители приступят к выполнению заказа.
        <br />
        <br />
        После выполнения каждой задачи вы получите отчет от исполнителя, и его можно будет сравнить с поведением в Яндекс Вебвизоре.
        <br />
        <br />
        Если задача сделана верно, то примите отчет, а если нет, то отправьте на доработку.
      </p>
  },
  {
    title: 'Сколько стоит накрутка поведенческих факторов в Яндексе?',
    isH3: false,
    text:
      <p>
        Цена зависит от количества поисковых запросов и конкуренции.
        <br />
        Вы можете рассчитать стоимость проекта в калькуляторе при создании проекта.
      </p>
  },
];

const STEP_TWO_TITLE_FAQ = <p>
  Почему при добавлении одного действия в калькуляторе появляются
  дополнительные действия?
  <br />
  <br />
  Сервис формирует уникальные сценарии для каждого исполнителя, и каждый
  сценарий начинается для нового исполнителя с поиска товара по сделанному
  скриншоту вашей ссылки.
</p>;

const ACTIONS = [
  { type: 'ALWAYS.OPEN', icon: ACTION_ICONS.OPEN, title: 'Открыть поисковик Яндекс', initialShare: 1 },
  { type: 'ALWAYS.SEARCH', icon: ACTION_ICONS.SEARCH, title: 'Набрать поисковый запрос', initialShare: 1 },
  { type: 'ALWAYS.COMPETITORS', icon: ACTION_ICONS.TIME, title: 'Открыть несколько сайтов из ТОП-10', titleComment: 'не более 10 сек', initialShare: 1 },
  { type: 'ALWAYS.FIND', icon: ACTION_ICONS.SEARCH, title: 'Найти сайт в поисковой выдаче Яндекса', initialShare: 1 },
  { type: "ALWAYS.CLICK", icon: ACTION_ICONS.CLICK, title: "Зайти на продвигаемый сайт", initialShare: 1 },

  {
    type: 'EXTRA.DESCRIPTION',
    icon: ACTION_ICONS.GLASSES,
    title: 'Изучить меню / каталог / услуги / товар',
    titleComment: 'от 60 секунд',
    initialShare: 1,
    activityType: 'Изучение',
  },

  {
    type: 'MAIN.INFO',
    icon: ACTION_ICONS.SEARCH,
    title: 'Почитать информацию о компании',
    initialShare: 1,
    activityType: 'Информация',
  },
  {
    type: 'MAIN.GEO',
    icon: ACTION_ICONS.PEOPLE,
    title: 'Зайти в адреса / контакты / карту',
    initialShare: 0,
    activityType: 'Контакты',
  },
  {
    type: 'MAIN.PHONE',
    icon: ACTION_ICONS.PHONE,
    title: 'Копировать номер телефона с сайта',
    titleComment: 'без звонка',
    initialShare: 0,
    activityType: 'Телефон',
  },
  {
    type: 'MAIN.EMAIL',
    icon: ACTION_ICONS.MESSAGE,
    title: 'Копировать на сайте почту из контактов',
    initialShare: 0,
    activityType: 'Почта',
  },
];

const STEP_PHRASE_2 = 'Укажите информацию для поиска сайта и закажите накрутку в Яндекс';

const YANDEX_SEO_KEYWORDS = [
  'Yandex', 'yandex', 'Яндекс', 'яндекс'
];

const CHAIN_PRICE = 14;

class YandexSeoClass extends Market {

  id = 'yandexseo';

  host = 'yandex.ru';
  url = 'https://www.yandex.ru/';

  title = 'Яндекс';

  isMarketplace = false;
  isSeo = true;

  icon = yandexSeoIcon;
  disabledIcon = yandexSeoDisabledIcon;
  logo = yandexSeoLogo;
  titlePlaces = 'Яндекс';
  statusForHomePage = MARKET_MODE_STATUS.NEW;

  chainsPerDayNormal = 42;

  chainPrice = CHAIN_PRICE;
  chainsMin = 20;
  chainsMax = 1000;

  chainsDefault = 20;

  reviewPrice = 250;

  chainsStep = 10;
  viewsStep = 10;

  keywords = YANDEX_SEO_KEYWORDS;

  targetLinkComment = 'Укажите тут адрес вашего сайта';
  targetLinkCommentShort = 'Укажите сайт';

  modes = [
    {
      id: MARKET_MODES.CHAINS,
      path: '/nakrutka-pf-yandex',
      rating: 0.01,
      title: 'Накрутка ПФ',
      navigateTitle: 'Накрутка ПФ в поиске Яндекса',
      icon: HEADER_ACTIONS_ICONS.PROMOTION,
      iconForHomePage: ACTION_ICONS.ACTIONS,
      status: MARKET_MODE_STATUS.NEW,
      statusForHomePage: MARKET_MODE_STATUS.NEW,
      meta: {
        title: `Накрутка ПФ Яндекс от ${CHAIN_PRICE} руб. - заказать накрутку поведенческих факторов | Boostclick`,
        description: 'Мы не продаем клик — мы продаем пользовательский путь. С нами вы можете улучшить SEO на Яндексе через накрутку ПФ.',
      },
      intro: {
        title: 'ПФ продвижение в поиске Яндекс',
        comment: 'Через выполнение микрозадач от реальных пользователей',
        points: [
          'Повысит эффективность рекламных объявлений',
          'Увеличит просмотры и активность в аккаунте',
          'Оптимизирует затраты на рекламу',
          'Улучшит позиции по поисковым запросам'
        ]
      },
      howItWorks: [
        'Настройте параметры активности в соответствие с конкуренцией',
        'Укажите фразы для поиска вашего сайта',
        'Укажите город и прикрепите скриншот целовой страницы',
        'После оплаты мы передадим исполнителю скриншот и параметры для поиска',
        'Проверьте ответы и активность, примите работу или отправьте на доработку'
      ],
      stepPhrase1: 'Настройте параметры для быстрой накрутки ПФ',
      stepPhrase2: STEP_PHRASE_2,
      stepTwoTitleFaq: STEP_TWO_TITLE_FAQ,
      calculatorTitle: 'Накрутка ПФ Яндекс',
      titleFAQ: "Вопрос-ответ о накрутке поведенческих факторов в Яндекс",
      faq: YANDEX_SEO_FAQ,
      actions: (() => {
        return [
          ...ACTIONS.filter(action => action.type !== 'MAIN.REVIEW' && !action.type.startsWith('VIEW.')),
          ...ACTIONS.filter(action => action.type === 'MAIN.REVIEW'),
        ];
      })(),
      notice: [
        '* Сервис сгенерирует уникальные цепочки действий с учетом поисковых запросов',
        'Каждый исполнитель получит индивидуальное задание, что гарантирует разное поведение пользователей на вашем сайте',
        'Сложность цепочек и время исполнения зависит от их количества в проекте',
      ]
    },
  ];

  actionsMap = ACTIONS.reduce((map, action) => {
    map[action.type] = action; return map;
  }, {});

  emptyUrlStr = 'Укажите ссылку на сайт';
  invalidUrlStr = 'Укажите корректную ссылку на сайт';
}

const YandexSeo = new YandexSeoClass();
export default YandexSeo;
