import { AddressSuggestions } from 'react-dadata';

import './react-dadata.scss';
import './CityInput.scss';

export default function CityInput({ searchCountries = true, onChange, error }) {
  return (
    <div className='avito-city-input'>
      <AddressSuggestions
        token={process.env.REACT_APP_DADATA_API_KEY}
        filterLocations={searchCountries ? [{ 'country': '*' }] : []}
        filterFromBound='city'
        filterToBound='city'
        inputProps={{
          'placeholder': 'Введите город',
          'className': `react-dadata__input ${error ? 'error' : ''}`
        }}
        onChange={onChange}
      />
    </div>
  )
}
