import { observer } from "mobx-react";

import { FAQModal } from "pages/Projects/ReportModal/FAQReportModal/FAQModal/FAQModal";
import popupStore from "components/Popup/PopupStore";

import { FAQ_IN_REPORT, FAQ_IN_PROJECT } from "../faqConstants";

import "./RenderFAQModal.scss";

export const RenderFAQModal = observer(() => {
  const modals = {
    "faq-in-report": (
      <FAQModal type="faq-in-report" store={popupStore} faq={FAQ_IN_REPORT} />
    ),
    "faq-in-project": (
      <FAQModal type="faq-in-project" store={popupStore} faq={FAQ_IN_PROJECT} />
    ),
  };

  return modals[popupStore.modalType];
});
