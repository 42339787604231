import Description from "../Description/Description";
import ListBlock from "../ListBlock/ListBlock";
import "./GridBlock.scss";

export default function GridBlock({
  title,
  items,
  description,
  classes = [],
  style,
}) {
  return (
    <div className="grid">
      {description && <p className="grid-description">{description}</p>}
      <p className="grid-title">{title}</p>
      <div className="grid-container">
        {items.map((item, index) => (
          <div className="grid-item" key={index} style={style}>
            {item.type === "Description" && (
              <Description
                type={item.listType}
                text={item.text}
                style={item.style}
              />
            )}
            {item.type === "List" && (
              <ListBlock
                type={item.listType}
                items={item.items}
                style={item.style}
              />
            )}
            {item.type === "Image" && (
              <img
                className="grid-img"
                src={item.src}
                alt={item.alt}
                style={item.style}
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
}
