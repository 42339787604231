import { useLocation } from "react-router-dom";

import IntroMarkets from "./IntroMarkets/IntroMarkets";

import AppStore from "AppStore";

import "./Intro.scss";

export default function Intro({ title, children, marketPath }) {
  const { pathname } = useLocation();

  return (
    <section className="intro">
      <div className="intro__info-container">
        <h1 className="intro__title">{title}</h1>
        <div>{children}</div>
      </div>
      <div className="intro__container-markets">
        {AppStore.isMarketPage(pathname) && <IntroMarkets marketPath={marketPath} />}
        <div className="intro__container-markets_big-circle" />
        <div className="intro__container-markets_small-circle" />
      </div>
    </section>
  );
}
