import { makeAutoObservable } from 'mobx';

class MessageBoxStore {
  isOpen = false;

  constructor() {
    makeAutoObservable(this);
  }

  showCheckbox = false;
  checkboxChecked = false;
  setCheckboxChecked(value) {
    this.checkboxChecked = value;
  }

  showInfo(message, comment, actionTitle, action, showCheckbox = false) {
    this.open('info', message, comment, actionTitle, action, showCheckbox);
  }

  showWarning(message, comment, actionTitle, action) {
    this.open('warning', message, comment, actionTitle, action);
  }

  showError(message, comment, actionTitle, action) {
    this.open('error', message, comment, actionTitle, action);
  }

  open(status, message, comment, actionTitle, action, showCheckbox) {
    this.status = status;
    this.message = message;
    this.comment = comment;

    this.actionTitle = actionTitle ?? 'Ок';
    this.action = action;

    this.isOpen = true;
    this.showCheckbox = showCheckbox;
    this.checkboxChecked = false;
  }

  close() {
    this.isOpen = false;
  }
}

const messageBoxStore = new MessageBoxStore();
export default messageBoxStore;
