import {
  HEADER_ACTIONS_ICONS,
  ACTION_ICONS,
  MARKET_MODES,
  MARKET_MODE_STATUS,
  Market
} from "../market";

import ozonIcon from "assets/images/MarketLogo/ozon.svg";
import ozonDisabledIcon from "assets/images/MarketLogo/disabled/ozon.svg";
import ozonLogo from "assets/images/MarketFullLogo/ozon.svg";

const OZON_FAQ = [
  {
    title: "Что такое поведенческий сценарий или поведенческий фактор (ПФ) на Озон?",
    isH3: true,
    text: (
      <p>
        Поведенческий фактор (ПФ) на Озон относится к анализу и учету действий и
        предпочтений пользователей при принятии решений
        <br />
        о показе и ротации карточек товаров на платформе. ПФ включает в себя
        такие факторы, как клики, просмотры, добавление товаров
        <br />
        в корзину, покупки и другие действия пользователей.
        <br />
        <br />
        Озон использует данные о поведении пользователей для оптимизации показа
        рекламы и товаров, чтобы обеспечить более релевантный
        <br />
        и персонализированный опыт покупателей.
        <br />
        <br />
        Улучшая поведенческий фактор на Озон — вы увеличиваете вероятность
        повышения ваших позиций в поисковой выдачи маркетплейса.
      </p>
    ),
  },
  {
    title: "Как работают алгоритмы органического продвижения на Озон?",
    isH3: true,
    text: (
      <div>
        <ul>
          <li>
            Релевантность: Алгоритмы учитывают, насколько товар соответствует
            запросу пользователя.
            <br />
            Это включает в себя ключевые слова, описания, заголовки, а также
            атрибуты товара, такие как бренд, цвет, размер и т.д.
          </li>
          <br />
          <li>
            Продажи: Популярность и уровень продаж товара также влияют на его
            рейтинг. Алгоритмы оценивают объемы продаж, количество отзывов и
            оценки покупателей.
          </li>
          <br />
          <li>
            Качество листинга: Товары с более полной и информативной страницей
            листинга, включая высококачественные изображения,
            <br />
            подробное описание, характеристики и т.д., могут иметь более высокий
            рейтинг.
          </li>
          <br />
          <li>
            Уровень доверия к продавцу: Оценки и отзывы покупателей, а также
            время на платформе Озон и количество успешных сделок влияют
            <br />
            на доверие к продавцу и могут повлиять на ранжирование его товаров.
          </li>
          <br />
          <li>
            Цены и скидки: Цены на товары и наличие скидок также могут сыграть
            роль в алгоритмах органического продвижения.
            <br />
            Более конкурентоспособные цены и привлекательные предложения могут
            повысить рейтинг товаров.
          </li>
          <br />
        </ul>
        Boostclick может влиять на CTR карточки товара, на конверсию добавления
        товара в корзину, на продолжительность сессии на карточки товара.
        Данные действия положительно скажутся на выдаче товара только в
        совокупности соблюдения первых 5 пунктов.
      </div>
    ),
  },
  {
    title: "Гарантии и безопасность",
    isH3: true,
    text: (
      <p>
        Накрутка поведенческого фактора является вспомогательной и
        дополнительной мерой в рекламных кампаниях.
        <br />
        Мы гарантируем, что исполнители Boostclick реальные люди, которые
        получают точные инструкции для безопасной накрутки ПФ.
        <br />
        <br />
        Для соблюдения органичного поиска, исполнители не получают URL ссылки на
        продвигаемые страницы, что гарантирует органический поиск.
        <br />
        Мы запрещаем исполнителям делать скриншоты, что исключает фиксирование
        Яндекс Метрикой и Вебвизором сам факт скриншота.
        <br />
        Для проверки мы используем различные микрозадачи, которые можно
        выполнить только находясь на продвигаемой странице.
      </p>
    ),
  },
  {
    title: "Как оплатить улучшение поведенческого фактора?",
    isH3: true,
    text: (
      <p>
        Пополнить личный кабинет Boostclick можно любым удобным способом через
        сервис Робокасса.
        <br />
        Минимальная оплата для физических лиц {`\u00A0—\u00A090\u00A0`}рублей.
        <br />
        Минимальная сумма пополнения для юридических лиц
        {`\u00A0—\u00A010000\u00A0`}рублей.
      </p>
    ),
  },
];

const STEP_TWO_TITLE_FAQ = (
  <p>
    Почему при добавлении одного действия в калькуляторе появляются
    дополнительные действия?
    <br />
    <br />
    Сервис формирует уникальные сценарии для каждого исполнителя, и каждый
    сценарий начинается для нового исполнителя с поиска товара по сделанному
    скриншоту вашей ссылки.
  </p>
);

const REVIEW_PRICE = 250;

const ACTIONS = [
  {
    type: "MAIN.FAVORITE",
    icon: ACTION_ICONS.HEART,
    title: "Добавить товар в избранное",
    initialShare: 0.5,
    activityType: 'Избранное',
  },
  {
    type: "MAIN.STAR",
    icon: ACTION_ICONS.STAR,
    title: "Добавить бренд в избранное",
    initialShare: 0.2,
    activityType: 'Избранное',
  },
  {
    type: "MAIN.BUSKET",
    icon: ACTION_ICONS.BUSKET,
    title: "Добавить товар в корзину",
    initialShare: 0.2,
    activityType: 'Корзина',
  },
  {
    type: "MAIN.LIKE",
    icon: ACTION_ICONS.LIKE,
    title: "Поставить положительную реакцию на отзыв",
    initialShare: 0.1,
    activityType: 'Лайк',
  },

  {
    type: "ALWAYS.OPEN",
    icon: ACTION_ICONS.OPEN,
    title: "Открыть сайт или приложение OZON",
    initialShare: 1,
  },
  {
    type: "ALWAYS.SEARCH",
    icon: ACTION_ICONS.SEARCH,
    title: "Найти товар через поиск OZON",
    initialShare: 1,
  },
  {
    type: 'ALWAYS.COMPETITORS',
    icon: ACTION_ICONS.TIME,
    title: 'Быстро просмотреть конкурентов',
    initialShare: 1,
  },
  {
    type: "ALWAYS.CLICK",
    icon: ACTION_ICONS.CLICK,
    title: "Зайти на карточку товара",
    initialShare: 1.0,
  },

  {
    type: "EXTRA.MEDIA",
    icon: ACTION_ICONS.EYE,
    title: "Посмотреть все фото и видео на карточке",
    initialShare: 0.6,
  },
  {
    type: "EXTRA.DESCRIPTION",
    icon: ACTION_ICONS.GLASSES,
    title: "Прочитать полное описание",
    initialShare: 0.2,
  },
  {
    type: "EXTRA.GOODS",
    icon: ACTION_ICONS.CLICK,
    title: "Посмотреть другие товары продавца",
    initialShare: 0.1,
  },
  {
    type: "EXTRA.FEEDBACKS",
    icon: ACTION_ICONS.GLASSES,
    title: "Прочитать отзывы на товар",
    initialShare: 0.1,
  },

  {
    type: "MAIN.DISLIKE",
    icon: ACTION_ICONS.DISLIKE,
    title: "Поставить отрицательную реакцию на отзыв",
    initialShare: 0,
    activityType: 'Дизлайк',
  },
  {
    type: "MAIN.APPEAL",
    icon: ACTION_ICONS.DISLIKE,
    title: "Пожаловаться на последний отзыв",
    initialShare: 0,
    activityType: 'Жалоба',
  },
];

const STEP_PHRASE_2 = "Укажите информацию для поиска товара";

const OZON_KEYWORDS = [
  'Ozon', 'ozon', 'Озон', 'озон'
];

class OzonClass extends Market {
  id = "ozon";
  host = "ozon.ru";
  url = "https://ozon.ru/";

  title = "OZON";

  isMarketplace = true;

  icon = ozonIcon;
  disabledIcon = ozonDisabledIcon;
  logo = ozonLogo;
  titlePlaces = "OZON";
  statusForHomePage = MARKET_MODE_STATUS.POPULAR;

  categories = [
    { type: "Электроника" },
    { type: "Одежда" },
    { type: "Обувь" },    
    { type: "Дом и сад" },
    { type: "Детские товары" },
    { type: "Красота и здоровье" },
    { type: "Бытовая техника" },
    { type: "Спорт и отдых" },
    { type: "Строительство и ремонт" },
    { type: "Продукты питания" },
    { type: "Аптека" },
    { type: "Товары для животных" },
    { type: "Книги" },
    { type: "Туризм, рыбалка, охота" },
    { type: "Автотовары" },
    { type: "Мебель" },
    { type: "Хобби и творчество" },
    { type: "Ювелирные украшения" },
    { type: "Аксессуары" },
    { type: "Игры и консоли" },
    { type: "Канцелярские товары" },
    { type: "Антиквариат и коллекционирование" },
    { type: "Цифровые товары" },
    { type: "Бытовая химия и гигиена" },
    { type: "Музыка и видео" },
    { type: "Автомобили" },
  ];

  chainsPerDayNormal = 200 / 30;

  chainPrice = 24;
  chainsMin = 4;
  chainsMax = 250;

  chainsDefault = 10;

  reviewPrice = REVIEW_PRICE;

  chainsStep = 1;
  viewsStep = 10;

  keywords = OZON_KEYWORDS;

  targetLinkComment = "Укажите тут адрес вашего товара на OZON";
  targetLinkCommentShort = "Укажите ссылку товара";

  modes = [
    {
      id: MARKET_MODES.CHAINS,
      path: "/povedencheskie-faktory-na-ozon",
      rating: 0.968,
      title: "ПФ",
      navigateTitle: "Улучшение ПФ на OZON",
      icon: HEADER_ACTIONS_ICONS.PROMOTION,
      iconForHomePage: ACTION_ICONS.ACTIONS,
      status: MARKET_MODE_STATUS.NEW,
      statusForHomePage: MARKET_MODE_STATUS.HIT,
      meta: {
        title:
          "Накрутка поведенческих факторов (ПФ) OZON - купить за 1.9 руб просмотры, отзывы, лайки | Boostclick",
        description: "Улучшайте ПФ на OZON с помощью сервиса Boostclick. С нами вы можете заказать поведенческий фактор.",
      },
      intro: {
        title: "Накрутка поведенческих факторов на OZON",
        comment:
          "Благодаря индивидуальным поведенческим сценариям пользователей",
        points: [
          "Без ботов",
          "Без самовыкупов",
          "Только уникальные пользователи",
          "Соблюдение сроков",
          "Подробные отчеты",
          "Поиск вашего магазина Озон по ключевым запросам",
        ],
      },
      howItWorks: [
        "Настройте параметры активности в соответствии с конкуренцией",
        "Укажите ссылку на товар для скриншота",
        "Укажите категорию, город и ключевые фразы из карточки товара",
        "После оплаты мы передадим исполнителю скриншот и параметры для поиска",
        "Проверьте ответы и активность, примите работу или отправьте на доработку",
      ],
      stepPhrase1: "Настройте параметры поведенческого фактора (ПФ) Озон",
      stepPhrase2: STEP_PHRASE_2,
      stepTwoTitleFaq: STEP_TWO_TITLE_FAQ,
      calculatorTitle: "Продвижение на OZON",
      titleFAQ: "Вопрос-ответ про накрутку просмотров на Озон",
      faq: OZON_FAQ,
      actions: (() => {
        return [
          ...ACTIONS.filter(
            (action) =>
              action.type !== "MAIN.REVIEW" && !action.type.startsWith("VIEW.")
          ),
          ...ACTIONS.filter((action) => action.type === "MAIN.REVIEW"),
        ];
      })(),
      notice: [
        "* Сервис сгенерирует уникальные цепочки действий с учетом поисковых запросов.",
        "Каждый исполнитель получит индивидуальное задание, что гарантирует разное поведение пользователей на странице вашего товара.",
        "В первую очередь будут выполняться действия связанные с органическим поиском и изучением товаров, далее добавление в избранное и корзину.",
        "Сложность цепочек и время исполнения зависит от их количества в проекте.",
      ],
    },
  ];

  actionsMap = ACTIONS.reduce((map, action) => {
    map[action.type] = action;
    return map;
  }, {});

  emptyUrlStr = 'Укажите ссылку на товар';
  invalidUrlStr = 'Укажите корректную ссылку на товар';
}

const Ozon = new OzonClass();
export default Ozon;
