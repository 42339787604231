import popupBoxStore from "PopupBox/PopupBoxStore";
import Tooltips from "components/UI/Tooltips/Tooltips";

import linkGreen from "assets/images/linkGreen.svg";

import "./ProjectLinkButton.scss";

export default function ProjectLinkButton({ style, link }) {

  function onClick() {
    navigator.clipboard.writeText(link);
    popupBoxStore.showInfo("Ссылка скопирована", linkGreen);
  }

  return (
    <Tooltips description="Cкопировать ссылку на источник" style={style} innerStyle={{ left: "90px" }}>
      <div className="project-link" onClick={onClick}>
        <div className="project-link-icon" />
        URL
      </div>
    </Tooltips>
  );
}
