import {
  HEADER_ACTIONS_ICONS,
  ACTION_ICONS,
  MARKET_MODES,
  MARKET_MODE_STATUS,
  Market
} from '../market';

import avitoIcon from "assets/images/MarketLogo/avito.svg";
import avitoDisabledIcon from "assets/images/MarketLogo/disabled/avito.svg";
//import avitoLogo from "assets/images/MarketFullLogo/avito.svg";

const REVIEW_PRICE = 250;
const WARRANTY_HOURS = 168;
const CHAIN_ACTION_PRICE = 3.5;

const CHAINS_FAQ = [
  {
    title: 'Что такое поведенческий сценарий или поведенческий фактор (ПФ) на досках объявлений?',
    isH3: true,
    text:
      <p>
        Это взаимодействие живого человека с объявлением в магазине размещенным на досках объявлений.
        <br />
        Например, поиск по ключам вашего объявления, просмотр фотографий, чтение текста, добавление в избранное, подписка на магазин,
        переход в профиль, чтение отзывов, запрос контакта, отправка сообщения, оставить отзыв, длительное нахождение на объявлении.
      </p>
  },
  {
    title: 'Как SEO поможет занять высокие позиции?',
    isH3: true,
    text:
      <p>
        Как и многие сервисы, Доска объявлений использует динамическую выдачу по поисковым запросам и ориентируется на поведенческие факторы,
        в том числе на основе данных показателей Доска объявлений повышает или понижает ваш рейтинг, показывает или скрывает вас в рекомендациях.
        <br />
        <br />
        Например, как в соцсетях, ваш пост собирает просмотры.
        <br />
        Объявления, на которых пользователи регулярно долго задерживаются, а также вызывают большой интерес (например, за добавление в избранное или подписку),
        помогают подниматься и занимать топовые места в поисковой выдаче.
        <br />
        <br />
        Этот фактор позволяет дольше находиться на высоких позициях и снижать затраты на платную рекламу, не теряя при просмотре объявлений.
      </p>
  },
  {
    title: 'Как работают алгоритмы на досках объявлений?',
    isH3: true,
    text:
      <p>
        Выдача объявления в поисковом запросе зависит от совпадений ключевых запросов
        и фильтров (гео, параметры товара или услуги) покупателей и общего рейтинга вашего магазина.
        <br />
        Задача поисковой выдачи — предоставить покупателю качественный выбор продавцов по его запросу.
        <br />
        Алгоритмы Доски объявлений собирает данные продавца с учетом многих параметров.
        Основным из них является поведенческий сценарий, включающий в себя: активность, отзывы, конверсии, стратегию работы, время ответа на сообщения и звонки, платное продвижение.
        <br />
        <br />
        По статистике, выдача на первых 10-20 позициях в поиске, собирает большую часть горячих лидов, но это также не является гарантом.
        <br />
        Как и в любой поисковой площадке, из списка продавцов покупатель выбирает по контенту:
        оформление шапки профиля, лого/фото, первое фото объявления, название, первые 215 символов описания.
      </p>
  },
  {
    title: 'Как подобрать поисковые запросы?',
    isH3: true,
    text:
      <div>
        Для корректного подбора поисковых запросов используйте следующие инструменты:
        <br />
        <br />
        <ul>
          <li>Сервис <a href='https://wordstat.yandex.ru'>wordstat.yandex.ru</a></li>
          <li>Анализ конкурентов</li>
        </ul>
      </div>
  },
  {
    title: 'Как составить SEO-текст объявления?',
    isH3: true,
    text:
      <div>
        Пример структуры текста<br />
        <br />
        <ol>
          <li>Заголовок такой же, как и название объявления, это дает преимущество в SEO.</li>
          <li>Описание товара.</li>
          <li>О компании — коротко и по делу, тут же пишем о скидке, акции.</li>
          <li>Почему важно выбрать именно вас.</li>
          <li>Призывы к действию: напиши нам, позвони нам, есть еще такая фишка, как напиши нам "КАТАЛОГ" в чат и т.д.</li>
          <li>Просите клиента дать вам мессенджер, куда отправить прайс. Тем самым вы получаете горячего клиента. Это повышает конверсию.</li>
          <li>Также ключи</li>
        </ol>
        <br />
        Ключевые слова должны быть выписаны отдельным абзацем в конце объявления и естественно вписаны в текст.
      </div>
  },
  {
    title: 'Как работает автоматическая стратегия SEO-продвижения Boostclick?',
    isH3: false,
    text:
      <p>
        Сервис использует накопленные исторические данные, анализирует альтернативу в выбранной категории и автоматически подбирает и восстанавливает активность.
      </p>
  },
  {
    title: 'Как работает ручная стратегия SEO-продвижения Boostclick?',
    isH3: false,
    text:
      <p>
        Ручная стратегия может быть адаптирована для опытных пользователей, которые хорошо понимают ее особенности и знают особенности алгоритмов.
        <br />
        <br />
        Данная стратегия может оказаться более эффективной (в сравнении с автоматической стратегией) узкопрофильных категориях.
      </p>
  },
  {
    title: 'Зона вашей ответственности, в чем она заключается?',
    isH3: true,
    text:
      <div>
        Результаты сервиса по размещению активностей в полном объеме можно увидеть в карточке объявлений и в отчетах сервиса.
        <br />
        <br />
        Сервис не несет ответственности и не гарантирует 100% продвижение по запросам, т.к. не может повлиять на:
        <br />
        <br />
        <ul>
          <li>На качество контента</li>
          <li>По выбору поисковых запросов</li>
          <li>На составление текстов объявлений</li>
          <li>По SEO-оптимизации текста объявления</li>
          <li>По конкуренции в категории</li>
          <li>На ценовую политику объявления</li>
        </ul>
      </div>
  },
  {
    title: 'Сколько накручивать поведенческих факторов на досках объявлений, чтобы выйти в топ поиска?',
    isH3: true,
    text:
      <p>
        Для каждой сферы есть выстроенная стратегия, которая была построена опытным и многократно протестированным путем с учетом всех особенностей каждой ниши.
      </p>
  },
  {
    title: 'Когда ждать результаты от поведенческого сценария?',
    isH3: true,
    text:
      <div>
        Каждая ниша индивидуальна, результаты можно увидеть уже на 3-ий день.
        <br />
        <br />
        Зависит это:
        <br />
        <ul>
          <li>какую позицию ваши объявления уже занимают в поиске: чем ниже, тем больше нужно времени набрать динамику и подняться;</li>
          <li>Ваш контент: если вы заняли первые места в поиске это не залог продажи — нужен продающий контент, для того чтобы покупатель перешел на ваш магазин.</li>
        </ul>
      </div>
  },
  {
    title: 'Почему я не вижу свое объявление после продвижения?',
    isH3: true,
    text:
      <div>
        Есть несколько причин, по которым вы не можете увидеть в поиске свое объявление:
        <br />
        <br />
        <ol>
          <li>Не прошло достаточно времени</li>
          <li>Неправильный раздел или категория</li>
          <li>Неправильные ключевые слова</li>
          <li>Объявление на 100+ позициях в поиске на досках объявлений</li>
          <li>Объявление удалено</li>
        </ol>
      </div>
  },
  {
    title: 'Как оплатить продвижение?',
    isH3: false,
    text:
      <p>
        Оплата производится через Робокассу, а также через безналичный расчет по договору оферты.
      </p>
  },
  {
    title: 'Как избежать блокировки от накрутки?',
    isH3: true,
    text:
      <ul>
        <li>корректно прописанные сценарии</li>
        <li>живые люди-исполнители</li>
        <li>отсутствие ботов при накрутке</li>
        <li>уникальный расчет формулы продвижения</li>
      </ul>
  },
];

export const REVIEWS_FAQ = [
  {
    title: 'Накрутить отзывы на досках объявлений в 2024 году',
    isH3: true,
    text:
      <div>
        Сколько стоит накрутка отзывов на досках объявлений
        <br />
        <br />
        Мы проанализировали предложения на рынке и выявили несколько категорий продавцов:
        <br />
        <br />
        <ol>
          <li>Мошенники: Заманивают низкими ценами (от 100 рублей), не предоставляют гарантий и исчезают после оплаты.</li>
          <li>Биржи фриланса: Цены начинаются от 250 рублей, но задания могут висеть неделями, так как исполнители предпочитают более дорогие заказы.</li>
          <li>Kwork: Стоимость отзывов стартует от 500 рублей.</li>
          <li>Авитологи: Перепродают отзывы по 600-700 рублей.</li>
          <li>Агентства: Предлагают самые высокие цены – от 1200 до 1600 рублей за отзыв.</li>
        </ol>
        <br />
        Почему такая цена?
        <br />
        <br />
        Дело в том, что найти добросовестных исполнителей стало сложнее. Доска объявлений активно борется с накруткой, блокируя подозрительные аккаунты. Ответственные исполнители дорожат своей репутацией и:
        <br />
        <br />
        <ol>
          <li>Оставляют не более одного отзыва с одного аккаунта в три дня, чтобы не вызвать подозрений.</li>
          <li>Выбирают заказы с максимально выгодной ценой.</li>
          <li>Не берут заказы дешевле 250 рублей, так как рынок и так переполнен предложениями.</li>
        </ol>
        <br />
        Boostclick: выгодное решение для накрутки отзывов
        <br />
        <br />
        Биржа Boostclick предлагает эффективное и безопасное решение для получения отзывов на досках объявлений. Мы внедрили систему аукционов, которая позволяет привлечь внимание исполнителей к вашему заказу. Если в течение 3 дней никто не возьмется за выполнение, запускается аукцион, и исполнители могут предложить свою цену.
      </div>
  },
  {
    title: 'Как купить отзывы на досках объявлений?',
    isH3: true,
    text:
      <p>
        Накрутка отзывов на досках объявлений: пошаговая инструкция от сервиса Boostclick.ru
        <br />
        <br />
        1. Зарегистрируйтесь на сервисе.
        <br />
        Это займет всего 1 минуту.
        <br />
        <br />
        2. Выберите тип и количество активностей.
        <br />
        Помимо отзывов, вы можете заказать просмотры объявлений, добавление в избранное, сообщения в чат и другие действия, которые сделают ваш профиль более живым и привлекательным для алгоритмов.
        <br />
        <br />
        3. Сформулируйте задачу для исполнителей.
        <br />
        Укажите пожелания к тексту отзыва или прикрепите ссылку на Яндекс.Диск с фото товара/услуги.
        <br />
        <br />
        4. Дождитесь, пока ваше задание возьмет исполнитель.
        <br />
        Биржа Boostclick работает с проверенными исполнителями, которые гарантируют качественное выполнение.
        <br />
        <br />
        5. Проверьте отчет исполнителя.
        <br />
        В отчете вы найдете текст отзыва, имя аккаунта и другие подробности, которые помогут убедиться в правильности выполнения задания.
        <br />
        <br />
        6. Оплатите задание.
        <br />
        Если отчет корректен, а отзыв прошел модерацию, оплатите задание в течение 78 часов.
        <br />
        <br />
        7. Наслаждайтесь результатом! 🚀
      </p>
  },
  {
    title: 'Почему накрутить отзывы через Boostclick выгодно?',
    isH3: false,
    text:
      <p>
        1. Официальная работа: Мы работаем через Робокассу, предоставляем чеки и гарантируем безопасность ваших платежей.
        <br />
        2. Огромная база исполнителей: Более 6000 проверенных исполнителей готовы приступить к выполнению вашего задания уже сегодня.
        <br />
        3. Оплата по факту: вы платите только за размещенные отзывы, которые прошли модерацию.
        <br />
        4. Гарантия до {WARRANTY_HOURS} часов: Мы уверены в качестве наших услуг и предоставляем гарантию на все отзывы.
        <br />
        5. Самые низкие цены: Стоимость накрутки отзывов начинается от {REVIEW_PRICE} рублей.
        <br />
        6. Комплексное продвижение: Мы не просто пишем отзывы, но и прогреваем аккаунты, чтобы сделать их более привлекательными для алгоритмов.
        <br />
        7. Органичный подход: Наши исполнители имитируют действия реальных пользователей, чтобы отзывы выглядели максимально естественно.
        <br />
        8. Быстрая техподдержка: Мы всегда на связи и готовы ответить на любые ваши вопросы.
      </p>
  },
  {
    title: 'Как создать задание по накрутке отзывов на досках объявлений для исполнителей?',
    isH3: false,
    text:
      <div>
        Секреты идеального задания для накрутки отзывов на досках объявлений
        <br />
        <br />
        Хотите, чтобы исполнители писали реалистичные отзывы на досках объявлений, которые не вызовут подозрений у алгоритмов?
        <br />
        <br />
        Boostclick автоматически генерирует органические сценарии для каждого исполнителя. Вам не нужно прописывать пути поиска, придумывать диалоги или объяснять, как оставить отзыв. Сервис учел все нюансы и позаботился о естественности процесса.
        <br />
        <br />
        Как сделать задание еще эффективнее?
        <br />
        <br />
        1. Укажите в пожеланиях, какие моменты нужно обсудить с продавцом:
        <br />
        <ol>
          <li> - Задайте часто встречающиеся вопросы о товаре/услуге.</li>
          <li> - Попросите рассказать о доставке или особенностях работы.</li>
          <li> - Сделайте акцент на преимуществах вашего предложения.</li>
        </ol>
        2. Не переусердствуйте с похвалой. Отзывы должны быть правдоподобными.
        <br />
        3. Прикрепите ссылку на Яндекс.Диск с уникальными фото вашего товара/услуги. Это поможет исполнителю создать более детальный и убедительный отзыв.
        <br />
        <br />
        Помните: Boostclick ограничивает длину пожеланий 450 символами, чтобы задание не казалось исполнителю слишком сложным и объемным.
        <br />
        <br />
        Boostclick поможет вам получить качественные, естественные и эффективные отзывы, которые повысят доверие к вашему профилю на досках объявлений.
      </div>
  },
  {
    title: 'Как выбрать исполнителя, чтобы получить качественный отзыв?',
    isH3: false,
    text:
      <div>
        Накрутка отзывов на досках объявлений становится все более популярной, давайте разберемся, кто пишет отзывы и какие из них заслуживают доверия.
        <br />
        <br />
        Три типа исполнителей:
        <br />
        <br />
        <ol>
          <li>Профессионалы (60%): Это опытные пользователи, которые хорошо знают алгоритмы и умеют писать реалистичные отзывы. Они используют старые и активные аккаунты, отбирают релевантные задания, соблюдают перерывы между отзывами и тщательно изучают информацию о товарах/услугах.</li>
          <li>Новички (25%): Это студенты, домохозяйки или просто люди, которые ищут подработку. Они могут не обладать опытом и знаниями о правилах, поэтому их отзывы могут быть не осмысленными, а часть заданий проигнорированными.</li>
          <li>Фрод и боты (15%): Эти пользователи используют ботов или занимаются мошенничеством. Биржа Boostclick активно блокирует таких исполнителей, чтобы гарантировать качество услуг.</li>
        </ol>
        <br />
        Как отличить профессионального исполнителя?
        <br />
        <br />
        <ol>
          <li>Качество отзывов: Профессиональный исполнитель пишет реалистичные и детальные отзывы, которые не вызывают подозрений.</li>
          <li>Коммуникация: Профессионал готов ответить на ваши вопросы и предоставить дополнительную информацию о выполнении заказа.</li>
          <li>Цена: Важно понимать, что спрос на отзывы постоянно растет и исполнители выбирают задания по фильтрам.</li>
        </ol>
        Опытный исполнитель сейчас берет задания от 450 рублей и пишет не чаще одного раза в три дня c одного аккаунта.
        <br />
        <br />
        Помните: качество отзывов на досках объявлений прямо влияет на эффективность вашего продвижения. Выбирайте выставляя цену от 450 рублей профессиональных исполнителей и получайте максимальную отдачу от накрутки отзывов!
      </div>
  },
  {
    title: 'Когда публикуются отзывы на досках объявлений?',
    isH3: true,
    text:
      <div>
        Скорость появления отзывов на досках объявлений зависит от нескольких факторов:
        <br />
        <br />
        Первые отзывы обычно появляются на досках объявлений через 2-6 дней.
        <ol>
          <li>Примерно 1-2 дня уходит на написание отзыва т.к. иногда надо выждать паузу после сделки.</li>
          <li>3 дня может уйти на модерацию сайтом и на подтверждение отзывов.</li>
        </ol>
        <br />
        Статусы проектов и даты активности исполнителей вы можете отслеживать в разделе <a href='/projects'>"Мои проекты"</a>.
        <br />
        <br />
        Важно помнить:
        <br />
        <br />
        <ol>
          <li>Модерация отзывов может занять больше времени, если отзыв содержит некорректную информацию или не соответствует правилам.</li>
          <li>Если вы не видите отзыв после 6 дней, обратитесь в службу поддержки сервиса.</li>
        </ol>
        <br />
        В целом, ждать появления отзыва на досках объявлений стоит от 2 до 6 дней при условии, если исполнитель взял ваш проект и его устроила цена за отзыв и техническое задание.
      </div>
  },
  {
    title: 'Как распознать некачественный отчет?',
    isH3: true,
    text:
      <ul>
        <li>Отчет не по теме: Исполнитель отвечает невпопад или просто отправляет шаблонный текст.</li>
        <br />
        <li>Пустой отчет: В отчете нет никакой информации, кроме, возможно, фразы "работа выполнена".</li>
        <br />
        <li>Отчет на иностранном языке:  Скорее всего, вы имеете дело с недобросовестным исполнителем, который использует ботов.</li>
      </ul>
  },
  {
    title: 'Что делать, если отчет некачественный?',
    isH3: false,
    text:
      <ul>
        <li>Убедитесь, что исполнитель действительно не выполнил работу. Возможно, он просто забыл прикрепить доказательства.</li>
        <br />
        <li>Смело отклоняйте отчет, если работы не было. Задание автоматически уйдет другому исполнителю. </li>
        <br />
        <li>Если отзыв появился, но отчет составлен некорректно, отправьте на доработку. Попросите исполнителя ответить на все вопросы.</li>
        <br />
        <li>Проверьте, соответствует ли тип активности вашим запросам. Иногда заказчики путают отзывы с другими действиями, например, "добавлением в избранное".</li>
      </ul>
  },
  {
    title: 'Признаки качественного отчета',
    isH3: false,
    text:
      <div>
        <ul>
          <li>Развернутые ответы на все проверочные вопросы. В отчете должны быть скриншоты, подтверждающие публикацию отзыва.</li>
          <br />
          <li>Соответствие результата на площадке. Проверьте, действительно ли появился отзыв на ваш товар или услугу.</li>
          <br />
          <li>Адекватная коммуникация. Хороший исполнитель предупредит, если отзыв задерживается на модерации, или предложит обсудить детали в чате.</li>
        </ul>
        <br />
        Рекомендации:
        <br />
        1. Не торопитесь принимать работу. Подождите до 48 часов, чтобы убедиться, что отзыв прошел модерацию.
        <br />
        2. Если работа выполнена не полностью, дайте исполнителю время на доработку.  Опишите, что нужно исправить.
        <br />
        <br />
        Помните: проверка отчетов – важный этап накрутки отзывов. Тщательно контролируйте исполнителей, и тогда ваши деньги не будут потрачены зря!
      </div>
  },
  {
    noindex: true,
    title: 'Топ 6 ошибок заказчиков при накрутке отзывов на досках объявлений',
    isH3: false,
    text:
      <p>
        Топ ошибок от заказчика, которые не дают быстро разместить отзывы на досках объявлений. 😨
        <br />
        <br />
        1. Некорректная ссылка на объявление. ❌🔗
        <br />
        2. Объявление пропало. 💨
        <br />
        3. Не отвечают исполнителям в чат или на телефон. 📵
        <br />
        4. Одинаковый текст отзыва для всех отзывов. 👯‍♀️👯
        <br />
        5. Не проверяются отчеты от исполнителей. 👀
        <br />
        6. Не подтверждается сделка. 🙅‍♀️💰
        <br />
        7. Очень сложное задание для исполнителей. 🤯
        <br />
        8. Не соблюдается пропорция 1 отзыв на 10 заявок. ⚖️
      </p>
  },
  {
    noindex: true,
    title: 'Топ 6 ошибок исполнителей при накрутке отзывов на досках объявлений',
    isH3: false,
    text:
      <p>
        Топ ошибок исполнителей, которые не дают быстро разместить отзывы на досках объявлений. 😨
        <br />
        <br />
        1. Не соблюдается техническое задание. 📑
        <br />
        2. Игнорируется диалог с заказчиком. 😶
        <br />
        3. Однотипные поверхностные вопросы заказчикам. 🤔
        <br />
        4. С аккаунта пишется больше чем 1 отзыв в 3 дня. ✍️✍️✍️
        <br />
        5. Поверхностный, хвалебный и универсальный текст отзыва без деталей. 🌟
        <br />
        6. Забывают отправить отчет с ответами на вопросы.
      </p>
  },
  {
    title: 'Как накрутить больше 10 отзывов на досках объявлений?',
    isH3: false,
    text:
      <p>
        Получение большого количества отзывов на досках объявлений на один аккаунт это сложная и не быстрая задача.
        <br />
        В разных тематиках это займет разное время, тем активности заказов отзывов можно посмотреть у конкурентов.
        <br />
        Важно помнить, что естественный процент отзывов относительно заявок на аккаунте 5-30% от всех запросов.
        <br />
        Если вы будите пытаться ускорить этот процесс, то сайт может начать удалять даже реальные отзывы.
        <br />
        А хорошие исполнители не будут брать ваше задание, чтобы не тратить время, т.к. оплату они получают после прохождения модерации.
        <br />
        <br />
        Как можно ускорить накрутку отзывов на досках объявлений?
        <br />
        <br />
        1. Создание отдельных проектов: Рекомендуем создавать отдельный проект для каждого отзыва. Это позволит вам получить больше отзывов за меньшее время.
        <br />
        2. Уникальные тексты: Текст каждого отзыва должен быть уникальным. Копирование отзывов может привести к их удалению.
        <br />
        3. Естественность: Текст отзыва должен быть простым и естественным, без сложных оборотов и излишней детализации.
        <br />
        4. Количество отзывов: Убедитесь, что количество отзывов в вашей нише не выглядит подозрительно.
        <br />
        5. Скорость работы: Boostclick, отправляют 30% заданий сразу, остальные – согласно выбранному темпу.
        <br />
        6. Рекомендации: Следуйте рекомендациям на сервисе. Отклонения могут привести к удалению отзыва или отказу исполнителей.
        <br />
        <br />
        В итоге, время получения 10 и более отзывов будет зависеть от нескольких факторов:
        <br />
        <br />
        1. От ниши и категории в которой размещается отзыв.
        <br />
        2. От возраста аккаунта и его активности.
        <br />
        3. От качества аккаунта исполнителя.
        <br />
        <br />
        Важно помнить:
        <br />
        Не все отзывы будут опубликованы. Модерация может отказать в публикации отзывов, которые не соответствуют ее правилам, в таком случае просто смените исполнителя указав в комментарии причину.
        <br />
        В заключение, получение 10 и более отзывов – это долгосрочная задача, требующая терпения и усилий. Используйте сервисы по написанию отзывов, следуйте рекомендациям и не бойтесь экспериментировать, чтобы добиться успеха.
      </p>
  },
];

const VIEWS_FAQ = [
  {
    title: 'Зачем нужна накрутка просмотров реальными людьми на досках объявлений, если можно использовать ботов? 🤔',
    isH3: true,
    text:
      <p>
        Боты легко вычисляются алгоритмами и могут привести к блокировке объявления 🚫.
        Реальные просмотры от живых людей выглядят естественно и повышают позиции объявления в поиске, привлекая внимание потенциальных покупателей. 📈
      </p>
  },
  {
    title: 'Как обеспечивается реальность просмотров? Откуда берутся эти пользователи?',
    isH3: true,
    text:
      <p>
        На бирже Boostclick, исполнители выполняют задания за вознаграждение, в том числе просматривают объявления на досках объявлений.
        <br />
        <br />
        Чтобы исключить ботов и подозрительную активность, в каждом задании используются разные проверочные вопросы, на которые можно ответить только найдя информацию в объявлении. 🔎
      </p>
  },
  {
    title: 'Как быстро я увижу результат от накрутки просмотров? ⏱️',
    isH3: true,
    text:
      <p>
        Эффект от накрутки проявляется постепенно. Обычно увеличение просмотров заметно уже в течение суток, а позиции объявления в поиске начинают расти в течение нескольких дней.
      </p>
  },
  {
    title: 'Это безопасно для моего аккаунта? 🔐',
    isH3: true,
    text:
      <p>
        При выборе надежного сервиса с качественными просмотрами риски минимальны.
        Важно не гнаться за слишком дешевыми предложениями и не накручивать огромное количество просмотров за короткий срок, чтобы не вызвать подозрений у алгоритмов сайта.
        ⚠️ Избегайте просмотров, которые длятся менее 20 секунд - такие просмотры сервисами аналитики засчитываются как некачественные.
        <br />
        <br />
        В Boostclick мы используем в заданиях проверочные вопросы. Чтобы на них ответить, исполнители тратят на карточке до 120 секунд.
      </p>
  },
  {
    title: 'Сколько стоят реальные просмотры на досках объявлений? 💰',
    isH3: true,
    text:
      <p>
        Биржа Boostclick использует в накрутке просмотров только реальных пользователей.
        Стоимость просмотра составляет 2 рубля + чтобы просмотр был качественным и исполнители тратили на карточке больше времени,
        мы добавляем одно целевое действие по карточке, за это исполнители получают дополнительную оплату в 2 рубля.
      </p>
  },
];

const STEP_TWO_TITLE_FAQ = <p>
  Почему при добавлении одного действия в калькуляторе появляются дополнительные действия?
  <br />
  <br />
  Сервис формирует уникальные сценарии для каждого исполнителя, и каждый сценарий начинается для нового исполнителя с поиска объявления по сделанному скриншоту вашей ссылки.
  <br />
  <br />
  Например, если вы заказали «Добавить в избранное», то сервис автоматически создаст новый сценарий, начиная с «Открыть сайт или приложение» - «Найти по фразе» - «Посмотреть конкурентов» и т.д.
  <br />
  <br />
  Исключение «Просмотры». Там мы даем не прямую ссылку, чтобы удешевить задание.
</p>;

const VIEW_SUBACTIONS_COMMENT = <p>
  Мы добавили дополнительные задания для исполнителей, чтобы они тратили больше времени на просмотр карточек и проявляли реальный интерес, а не просто кликали.
  <br />
  <br />
  Такие просмотры от реальных пользователей не считаются отказом (некачественными) на досках объявлений, а контрольные вопросы помогут вам убедиться, что задания действительно выполнены.
</p>;

const ACTIONS = [
  {
    type: 'MAIN.REVIEW',
    icon: ACTION_ICONS.MESSAGE,
    title: 'Написать отзыв на продавца',
    titleComment: 'со звонком или перепиской в чате',
    initialShare: 1,
    price: REVIEW_PRICE,
    itemTitle: 'отзыв',
    activityType: 'Отзыв', // NOTE: do not change (see PopupStore.js abd ReportModal.jsx)
  },

  {
    type: 'MAIN.LIKE',
    icon: ACTION_ICONS.HEART,
    title: 'Добавить объявление в избранное',
    initialShare: 0.3,
    activityType: 'Избранное',
    price: CHAIN_ACTION_PRICE,
  },
  {
    type: 'MAIN.LIKE_PHONE',
    icon: ACTION_ICONS.PHONE,
    title: 'Добавить объявление в избранное +\nпросмотр контактов',
    titleComment: 'клик по номеру',
    initialShare: 0.4,
    activityType: 'Контакты',
    price: CHAIN_ACTION_PRICE,
  },
  {
    type: 'MAIN.MESSAGE',
    icon: ACTION_ICONS.MESSAGE,
    title: 'Написать сообщение продавцу',
    initialShare: 0.1,
    activityType: 'Сообщение',
    price: CHAIN_ACTION_PRICE,
  },
  {
    type: 'MAIN.SUBSCRIBE',
    icon: ACTION_ICONS.PEOPLE,
    title: 'Подписаться на продавца',
    initialShare: 0.2,
    activityType: 'Подписка',
    price: CHAIN_ACTION_PRICE,
  },

  { type: 'ALWAYS.OPEN_AVITO', icon: ACTION_ICONS.OPEN, title: 'Открыть сайт или приложение', initialShare: 1.00 },
  { type: 'ALWAYS.SEARCH_PHRASE', icon: ACTION_ICONS.SEARCH, title: 'Написать поисковый запрос', initialShare: 1.00 },
  { type: 'ALWAYS.SEE_COMPETITORS', icon: ACTION_ICONS.TIME, title: 'Быстро просмотреть конкурентов', initialShare: 1.00 },
  { type: 'ALWAYS.FIND_ME', icon: ACTION_ICONS.SEARCH, title: 'Найти профиль и объявление через поиск', initialShare: 1.00 },
  { type: "ALWAYS.CLICK", icon: ACTION_ICONS.CLICK, title: "Зайти на карточку объявления", initialShare: 1.0 },

  { type: 'EXTRA.SEE_MEDIA', icon: ACTION_ICONS.EYE, title: 'Просмотреть все фото и видео на карточке', initialShare: 0.30 },
  { type: 'EXTRA.SEE_MAP', icon: ACTION_ICONS.CLICK, title: 'Нажать «Показать на карте»', initialShare: 0.20 },
  { type: 'EXTRA.READ_NEXT', icon: ACTION_ICONS.CLICK, title: 'Нажать «Читать далее»', initialShare: 0.15 },
  { type: 'EXTRA.READ_DESCRIPTION', icon: ACTION_ICONS.GLASSES, title: 'Прочитать описание объявления', initialShare: 0.10 },
  { type: 'EXTRA.READ_FEEDBACKS', icon: ACTION_ICONS.GLASSES, title: 'Прочитать отзывы на продавца', initialShare: 0.20 },
  { type: 'EXTRA.SEE_OTHER_ADS', icon: ACTION_ICONS.CLICK, title: 'Посмотреть другие объявления продавца', initialShare: 0.05 },

  {
    type: 'VIEW.MEDIA',
    icon: ACTION_ICONS.EYE,
    title: 'Просмотр объявления от 30 сек +\nфото / видео',
    titleComment: 'Непрямая ссылка. Заход через браузер',
    initialShare: 1 / 3,
    price: 2,
    subactions: 2,
    itemTitle: 'сценарий',
    subactionsComment: VIEW_SUBACTIONS_COMMENT,
  },
  {
    type: 'VIEW.PHONE',
    icon: ACTION_ICONS.EYE,
    title: 'Просмотр объявления от 30 сек +\nпрочитать описание',
    titleComment: 'Непрямая ссылка. Заход через браузер',
    initialShare: 1 / 3,
    price: 2,
    subactions: 2,
    itemTitle: 'сценарий',
    subactionsComment: VIEW_SUBACTIONS_COMMENT,
  },
  {
    type: 'VIEW.PHOTO',
    icon: ACTION_ICONS.EYE,
    title: 'Просмотр объявления от 30 сек +\nпочитать отзывы',
    titleComment: 'Непрямая ссылка. Заход через браузер',
    initialShare: 1 / 3,
    price: 2,
    subactions: 2,
    itemTitle: 'сценарий',
    subactionsComment: VIEW_SUBACTIONS_COMMENT,
  },
];

const STEP_PHRASE_2 = 'Укажите информацию для поиска объявления';

const AVITO_KEYWORDS = [
  'Avito', 'avito', 'Авито', 'авито'
];

class AvitoClass extends Market {

  id = 'avito';
  host = 'avito.ru';
  url = 'https://www.avito.ru/';

  title = 'Доска объявлений';

  isMarketplace = true;

  icon = avitoIcon;
  disabledIcon = avitoDisabledIcon;
  //logo = avitoLogo;
  titlePlaces = 'Доски объявлений';
  statusForHomePage = MARKET_MODE_STATUS.POPULAR;

  categories = [
    { type: 'Транспорт' },
    { type: 'Недвижимость' },
    { type: 'Работа' },
    { type: 'Услуги' },
    { type: 'Личные вещи' },
    { type: 'Для дома и дачи' },
    { type: 'Запчасти и аксессуары' },
    { type: 'Электроника' },
    { type: 'Хобби и отдых' },
    { type: 'Животные' },
    { type: 'Бизнес и оборудование' },
    { type: 'Молл' },
  ];

  chainsPerDayNormal = 200 / 30;

  chainPrice = 24;
  chainsMin = 4;
  chainsMax = 250;

  chainsDefault = 10;

  reviewPrice = REVIEW_PRICE;

  chainsStep = 1;
  viewsStep = 10;

  keywords = AVITO_KEYWORDS;

  targetLinkComment = 'Укажите тут адрес вашего объявления';
  targetLinkCommentShort = 'Укажите ссылку объявления';

  //статусы для главной страницы ПФ — хит; Отзывы — популярно
  modes = [
    {
      id: MARKET_MODES.CHAINS,
      path: '/povedencheskie-faktory-na-obyavleniya',
      rating: 0.99,
      title: 'ПФ',
      navigateTitle: 'Улучшение ПФ на досках объявлений',
      icon: HEADER_ACTIONS_ICONS.PROMOTION,
      iconForHomePage: ACTION_ICONS.ACTIONS,
      status: MARKET_MODE_STATUS.REGULAR,
      statusForHomePage: MARKET_MODE_STATUS.HIT,
      meta: {
        title: 'Накрутка поведенческих факторов на объявлениях за 1.9 руб — купить ПФ в сервисе Boostclick.ru',
        description: 'Boostclick.ru - сервис накрутки поведенческих факторов на объявлениях. На бирже вы можете купить ПФ для доски объявлений от 1 рубля за действие по уникальной цене. Все вопросы можно задать через тех. поддержку.',
      },
      intro: {
        title: 'Накрутка поведенческих факторов на объявлениях',
        comment: 'Через выполнение микрозадач от реальных пользователей',
        points: [
          'Увеличит просмотры и активность',
          'Оптимизирует расходы на рекламу',
          'Улучшит позиции в поиске',
          'Увеличит шансы на попадание в рекомендации и рассылки на досках объявлений'
        ]
      },
      howItWorks: [
        'Настройте параметры активности в соответствии с конкуренцией',
        'Укажите ссылку на объявление для скриншота',
        'Укажите категорию, город и ключевые фразы из объявления',
        'После оплаты мы передадим исполнителю скриншот и параметры для поиска',
        'Проверьте ответы и активность, примите работу или отправьте на доработку'
      ],
      stepPhrase1: 'Настройте параметры поведенческого фактора (ПФ) на досках объявлений',
      stepPhrase2: STEP_PHRASE_2,
      stepTwoTitleFaq: STEP_TWO_TITLE_FAQ,
      calculatorTitle: 'Распределите ПФ на объявления',
      titleFAQ: "Вопрос-ответ по работе сервиса накрутки ПФ на досках объявлений",
      faq: CHAINS_FAQ,
      actions: (() => {
        return [
          ...ACTIONS.filter(action => action.type !== 'MAIN.REVIEW' && !action.type.startsWith('VIEW.')),
          ...ACTIONS.filter(action => action.type === 'MAIN.REVIEW'),
        ];
      })(),
      notice: [
        '* Сервис сгенерирует уникальные цепочки действий с учетом поисковых запросов.',
        '** При написании отзывов исполнители полностью проходят путь пользователя от органического поиска до общения по товару / услуге с продавцом.',
        'Отзывы будут оставлены исполнителем в промежутке от 1 до 5 дней после общения',
        'Каждый исполнитель получит индивидуальное задание, что гарантирует разное поведение пользователей на вашем объявлении.',
        'В первую очередь будут выполняться действия связанные с органическим поиском и изучением объявления, далее добавление в избранное и подписки. В последнюю очередь делаются действия с чатом на досках объявлений.',
        'Сложность цепочек и время исполнения зависит от их количества в проекте.',
      ]
    },
    {
      id: MARKET_MODES.REVIEWS,
      path: '/reviews-na-obyavleniya',
      rating: 0.98,
      title: 'Отзывы',
      navigateTitle: 'Заказать отзывы на досках объявлений',
      icon: HEADER_ACTIONS_ICONS.MESSAGE,
      iconForHomePage: ACTION_ICONS.MESSAGE,
      status: MARKET_MODE_STATUS.REGULAR,
      statusForHomePage: MARKET_MODE_STATUS.POPULAR,
      meta: {
        title: `Купить отзывы на досках объявлений от ${REVIEW_PRICE} рублей — заказать накрутку отзывов на досках объявлений людьми | Boostclick`,
        description: `Улучшайте отзывы на досках объявлений с помощью сервиса Boostclick. С сервисом вы можете заказать накрутку отзывов от ${REVIEW_PRICE
          } р. Задания выполняют реальные люди. Нужна помощь по сервису? Пишите в поддержу.`,
      },
      intro: {
        title: `Купить отзывы на досках объявлений`,
        comment: 'Через выполнение микрозадач от реальных пользователей',
        points: [
          'Гарантия на отзывы',
          'Официально, чек после оплаты',
          'Переписка с исполнителем или звонок в чате для лучшей модерации при накрутке отзыва на досках объявлений',
          'Оплата исполнителю только после публикации отзыва до 168 часов'
        ]
      },
      howItWorks: [
        'Настройте параметры активности в соответствии с конкуренцией',
        'Укажите ссылку на объявление для скриншота',
        'Укажите категорию, город и ключевые фразы из объявления',
        'После оплаты мы передадим исполнителю скриншот и параметры для поиска',
        'Проверьте ответы и активность, примите работу или отправьте на доработку'
      ],
      stepPhrase1: 'Настройте стоимость отзывов на досках объявлений и параметры',
      stepPhrase2: STEP_PHRASE_2,
      stepTwoTitleFaq: STEP_TWO_TITLE_FAQ,
      calculatorTitle: 'Заказать отзывы',
      titleFAQ: "Вопрос-ответ по накрутке отзывов на досках объявлений",
      faq: REVIEWS_FAQ,
      actions: (() => {
        return [
          ...ACTIONS.filter(action => action.type === 'MAIN.REVIEW'),
          ...ACTIONS.filter(action => action.type !== 'MAIN.REVIEW' && !action.type.startsWith('VIEW.')),
        ];
      })(),
      notice: [
        '* Сервис сгенерирует уникальные цепочки действий с учетом поисковых запросов',
        '** При написании отзывов исполнители полностью проходят путь пользователя от органического поиска до общения по товару / услуге с продавцом.',
        'Отзывы будут оставлены исполнителем в промежутке от 1 до 5 дней после общения',
        'Каждый исполнитель получит индивидуальное задание, что гарантирует разное поведение пользователей на вашем объявлении.',
        'В первую очередь будут выполняться действия связанные с органическим поиском и изучением объявления, далее добавление в избранное и подписки. В последнюю очередь делаются действия с чатом на досках объявлений.',
        'Сложность цепочек и время исполнения зависит от их количества в проекте',
      ]
    },
    {
      id: MARKET_MODES.VIEWS,
      path: '/views-na-obyavleniya',
      rating: 0.97,
      title: 'Просмотры',
      navigateTitle: 'Накрутка просмотров на досках объявлений',
      icon: HEADER_ACTIONS_ICONS.EYE,
      iconForHomePage: ACTION_ICONS.EYE,
      status: MARKET_MODE_STATUS.REGULAR,
      meta: {
        title: `Купить просмотры на на досках объявлений от 1.9 руб — заказать накрутку | Boostclick`,
        description: 'Накручивайте просмотры на досках объявлений с помощью сервиса Boostclick. С сервисом вы можете заказать просмотры реальными людьми.',
      },
      intro: {
        title: 'Накрутка просмотров на досках объявлений',
        comment: 'Через выполнение микрозадач от реальных пользователей',
        points: [
          'Увеличит просмотры и активность',
          'Оптимизирует расходы на рекламу',
          'Улучшит позиции в поиске',
          'Увеличит шансы на попадание в рекомендации и рассылки на досках объявлений'
        ]
      },
      howItWorks: [
        'Разместите задание исполнителям',
        'Укажите ссылку на объявление',
        'Исполнители выполнят задания и ответят на контрольные вопросы',
        'Если задание выполнено верно, то примите его. Если нет, то смените исполнителя.'
      ],
      stepPhrase1: 'Настройте параметры просмотров объявления',
      stepPhrase2: STEP_PHRASE_2,
      stepTwoTitleFaq: STEP_TWO_TITLE_FAQ,
      calculatorTitle: 'Заказать просмотры на объявления',
      titleFAQ: "Вопрос-ответ про накрутку просмотров на досках объявлений",
      faq: VIEWS_FAQ,
      actions: (() => {
        return [
          ...ACTIONS.filter(action => action.type.startsWith('VIEW.')),
        ];
      })(),
      notice: [
        '* Сервис сгенерирует уникальные цепочки действий с учетом поисковых запросов.',
        'Каждый исполнитель получит индивидуальное задание, что гарантирует разное поведение пользователей на вашем объявлении.',
        'Время исполнения зависит от количества задач в проекте.',
      ]
    },
  ];

  actionsMap = ACTIONS.reduce((map, action) => {
    map[action.type] = action; return map;
  }, {});

  emptyUrlStr = 'Укажите ссылку на объявление';
  invalidUrlStr = 'Укажите корректную ссылку на объявление';
}

const Avito = new AvitoClass();
export default Avito;
