import { observer } from "mobx-react";
import { useRef } from "react";

import { ButtonWithIcon } from "components/UI/ButtonWithIcon/ButtonWithIcon";
import messageBoxStore from "MessageBox/MessageBoxStore";
import AppStore from "AppStore";

import deleteIcon from "assets/images/delete.svg";

import "./UploadFile.scss";

const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5 MB
const ALLOWED_FORMATS = ["image/jpeg", "image/png", "image/webp"];

const UploadFile = observer(
  ({
    fileType = "default",
    title,
    icon,
    className,
    showSuccess = true,
    alwaysShowButton = false,
    disabled
  }) => {
    const fileClicker = useRef(null);

    const validateFile = (file) => {
      if (!file) {
        return "Файл не выбран";
      }
      if (!ALLOWED_FORMATS.includes(file.type)) {
        return "Неподдерживаемый формат файла. Пожалуйста, используйте JPG, PNG или WebP.";
      }
      if (file.size > MAX_FILE_SIZE) {
        return `Файл слишком большой. Максимальный размер: ${
          MAX_FILE_SIZE / (1024 * 1024)
        } МБ.`;
      }
      return null;
    };

    const handleChange = (e) => {
      const file = e.target.files[0];
      const errorMessage = validateFile(file);

      if (errorMessage) {
        messageBoxStore.showError(errorMessage);
        AppStore.clearUploadedFile(fileType);
      } else {
        AppStore.setUploadedFile(fileType, file);
      }

      e.target.value = "";
    };

    const handlePick = () => {
      fileClicker.current.click();
    };

    const uploadedFile = AppStore.getUploadedFile(fileType);

    return (
      <div className="avito-upload-file">
        <input
          ref={fileClicker}
          className="avito-upload-file__input"
          type="file"
          accept="image/*,.png,.jpg,.web"
          onChange={handleChange}
        />
        {(!uploadedFile || alwaysShowButton) && (
          <ButtonWithIcon
            title={title}
            className={className}
            icon={icon}
            onClick={handlePick}
            ariaLabel="Выбрать скриншот"
            disabled={disabled}
          />
        )}
        {uploadedFile && showSuccess && (
          <div className="avito-upload-file__success">
            <div className="avito-upload-file__success-upload">
              <div className="avito-upload-file__success-upload-icon" />
              <p>Скриншот прикреплен</p>
            </div>
            <ButtonWithIcon
              title="Удалить"
              className="avito-upload-file__delete-button"
              icon={deleteIcon}
              onClick={() => AppStore.clearUploadedFile(fileType)}
              ariaLabel="Удалить скриншот"
            />
          </div>
        )}
      </div>
    );
  }
);

export default UploadFile;
