import './SelectTag.scss';

export default function SelectTag({ title, onDelete }) {
  return (
    <div className='avito-select-tag'>
      <p className='avito-select-tag__title'>{title}</p>
      <button className="avito-select-tag__button-close" onClick={(event) => { onDelete(event) }}></button>
    </div>
  );
}
