import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { observer } from 'mobx-react';

import { Helmet } from 'react-helmet';

import Plate from 'components/UI/Plate/Plate';
import Button from 'components/UI/Button/Button';
import { TextInput } from 'components/UI/TextInput/TextInput';

import messageBoxStore from 'MessageBox/MessageBoxStore';

import client from 'client';
import { ApiError } from '../../client.js';

import './Reset.scss';

export const Reset = observer(() => {

  const navigate = useNavigate();

  const [isBusy, setIsBusy] = useState(false);

  const [password1, setPassword1] = useState('');
  const [password2, setPassword2] = useState('');

  async function onSubmit() {
    if (isBusy)
      return;

    const params = new URLSearchParams(window.location.search);
    const token = params.get('token');
    if (!token)
      return messageBoxStore.showError('Отсутстует токен восстановления.');

    if (!password1)
      return messageBoxStore.showError('Введите пароль.');
    if (password2 !== password1)
      return messageBoxStore.showError('Пароли не совпадают.');

    setIsBusy(true);
    try {
      await client.reset(token, password1);
    } catch (e) {
      if (e instanceof ApiError) {
        messageBoxStore.showError(e.message);
        return;
      }
      throw e;
    } finally {
      setIsBusy(false);
    }

    messageBoxStore.showInfo('Пароль обновлен.', 'Вы можете произвести вход с новым паролем.');
    navigate('/');
  }

  return (
    <div className='reset'>
      <Helmet>
        <title>Установка пароля в сервисе — Boostclick</title>
        <meta name="description" content="Установить пароль в сервисе Boostclick" />
        <meta name="robots" content="noindex" />
      </Helmet>

      <form className="restore__form" onSubmit={e => { e.preventDefault(); onSubmit() }}>
        <Plate className='reset__plate'>
          <h1 className='reset__title'>Восстановление пароля</h1>

          <TextInput classes={["reset__input"]} placeholder='Новый пароль' type='password' onChange={e => setPassword1(e.target.value)} />
          <TextInput classes={["reset__input"]} placeholder='Повторите пароль' type='password' onChange={e => setPassword2(e.target.value)} />

          <Button title='Обновить пароль' />
        </Plate>
      </form>
    </div>
  );
});
