import { observer } from "mobx-react";
import { Fragment } from "react";

import Plate from "components/UI/Plate/Plate";

import "./PromoStep.scss";

export const PromoStep = observer(({ icon, step, title, text }) => {
  return (
    <Plate className="avito-promo-step">
      <div className="avito-promo-step__container">
        <div className="avito-promo-step__icon">
          <img src={icon.src} alt={icon.alt} className="avito-promo-step__icon-img" />
          <img src={step.src} alt={step.alt} className="avito-promo-step__icon-step" />
        </div>
        <hr className="avito-promo-step__icon-border" />


        <div className="avito-promo-step__description">
          <p className="avito-promo-step__description-title">{title}</p>
          <p className="avito-promo-step__description-text">
            {Array.isArray(text) 
              ? text.map((item, i) => <Fragment key={i}>{item}</Fragment>)
              : text
            }
          </p>
        </div>
      </div>
    </Plate>
  );
});

