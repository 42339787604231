import { InfoArticle } from "../InfoArticle/InfoArticle";
import { TimeArticle } from "../TimeArticle/TimeArticle";

import "./FirstArticle.scss";

export default function FirstArticle({ article }) {
  const { type, time, title, data, description } = article;

  return (
    <div className="first-article__background">
      <div className="first-article__type">{type}</div>
      <TimeArticle
        time={time}
        style={{
          marginTop: "16px",
          marginRight: "16px",
        }}
      />
      <div className="first-article__title">
        <h2 className="first-article__title-text">{title}</h2>
        <p className="first-article__title-subtext">{description}</p>
      </div>
      <InfoArticle
        data={data}
        style={{
          flexDirection: "column",
          justifyContent: "flex-end",
          color: "#f9f9fb",
          gridArea: "2/2",
        }}
      />
    </div>
  );
}
