import classNames from 'classnames';

import FAQ from '../FAQ/FAQ';

import './Faqed.scss';

export default function Faqed({ children, className, text, customPlateStyle }) {
  return (
    <div className={classNames('faqed', className)}>
      <div className='faqed__children-container'>
        {children}
      </div>
      <FAQ text={text} customPlateStyle={customPlateStyle} />
    </div>
  );
}
