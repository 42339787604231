export const REVIEW_PLACEHOLDER_TXT = `\
Напишите, что исполнитель должен спросить в переписке или при звонке? Какие качества надо отразить в отзыве?\n\
\n\
Для передачи фото воспользуйтесь файлообменником и прикрепите ссылку.\n\
`;

export const REVIEW_PLACEHOLDER_TXT_ANOTHER = `\
Напишите, что исполнитель должен написать в отзыве? На что обратить внимание?\
`;

export const REVIEW_ERROR_MESSAGE = `\
Максимально 550 символов. Мы ограничили количество символов, чтобы исполнители быстрее обрабатывали задание.\
`;

export const COMMENT_PLACEHOLDER_TXT = `\
Напишите, что исполнитель должен написать в комментарии?\n\
\n\
Можете привести примеры комментариев.\n\
`;
