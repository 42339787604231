import { observer } from "mobx-react";
import Button from "components/UI/Button/Button";

import "./SortMenu.scss";

const SortMenu = observer(({ store }) => {
  const handleTypeFilter = (type) => {
    store.filterType(type);
  };

  const handleCategoryFilter = (category) => {
    store.filterCategory(category);
  };

  const typeButtons = ["Новости", "Гайд"].map((type) => (
    <Button
      key={type}
      title={type === "Гайд" ? "Гайды" : type}
      classes={["blog", store.selectedType === type ? "active" : ""]}
      onClick={() => handleTypeFilter(type)}
    />
  ));

  const categoryButtons = [
    "Все",
    "Поисковые системы",
    "Маркетплейсы",
    "Технологии",
    "Агрегаторы"
  ].map((category) => (
    <Button
      key={category}
      title={category}
      classes={[
        "blog",
        store.selectedCategory === category ||
        (!store.selectedCategory && category === "Все")
          ? "active"
          : "",
      ]}
      onClick={() =>
        category === "Все"
          ? store.resetFilterCategory()
          : handleCategoryFilter(category)
      }
    />
  ));

  return (
    <div className="sort-menu">
      <div className="sort-menu__container">
        <div className="sort-menu__container-block">{typeButtons}</div>
      </div>

      <div className="sort-menu__container">
        <div className="sort-menu__container-block">{categoryButtons}</div>
      </div>
    </div>
  );
});

export default SortMenu;
