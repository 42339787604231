import "./SkeletonArticle.scss";

const SkeletonArticle = () => {
  return (
    <>
      <div className="skeleton-image" />
      <div className="skeleton-content">
        <div className="skeleton-time">
          <div className="skeleton-text" />
        </div>
        <div className="skeleton-container">
          <div className="skeleton-type">
            <div className="skeleton-text" />
          </div>
          <div className="skeleton-title">
            <div className="skeleton-text title" />
            <div className="skeleton-text title" />
          </div>
          <div className="skeleton-info">
            <div className="skeleton-text info" />
            <div className="skeleton-text info" />
          </div>
        </div>
      </div>
    </>
  );
};

export default SkeletonArticle;
