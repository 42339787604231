import { observer } from "mobx-react";
import { useEffect, useState } from "react";

import Preloader from "components/Preloader/Preloader";

import popupStore from "components/Popup/PopupStore";

import "./ProjectEditingScreen.scss";

const ProjectEditingScreen = observer(({ store, project, hasLink, onLoadingStateChange }) => {
  const [isLoading, setIsLoading] = useState(true);
  const screenshot = store.uploadedFiles.default
    ? URL.createObjectURL(store.uploadedFiles.default)
    : store.projectScreenshot
    ? `data:image/jpeg;base64,${store.projectScreenshot}`
    : null;

  useEffect(() => {
    setIsLoading(true);
    if (project) {
      store.loadProjectScreenshot(project)
      .finally(() => {
        setIsLoading(false);

        if (!hasLink) onLoadingStateChange(true);
      });
    } else {
      setIsLoading(false);
      onLoadingStateChange(false);
    }
  }, [store, project, onLoadingStateChange, hasLink]);

  const handleClick = () => {
    if (screenshot && !store.uploadedFiles.default) {
      popupStore.open("project-full-screenshot");
    }
  };

  return (
    <>
      {isLoading ? <Preloader className={!hasLink ? 'other-size' : ''} /> :
        <div className={`project-setup__screen ${store.uploadedFiles.default ? 'no-fullscreen' : ''} ${!hasLink ? 'other-size' : ''}`} onClick={handleClick}>
          <div className={`project-setup__screen-icon ${!screenshot || store.uploadedFiles.default ? 'no-fullscreen' : ''}`} />
          {screenshot ?
            <div className={`project-setup__screen-image-wrapper ${!hasLink ? 'other-size' : ''}`}>
              <img src={screenshot} alt="Full screenshot" />
            </div>
          : 
            <div className="project-setup__screen-placeholder">Нет скриншота</div> 
          }
       </div>
      }
    </>
  );
});

export default ProjectEditingScreen;