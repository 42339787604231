import { Fragment } from "react";
import classNames from "classnames";

import { FooterButton } from "Footer/FooterButton/FooterButton";

import "./FooterWrapper.scss";

export default function FooterWrapper({
  className,
  title,
  subtitle,
  icon,
  titleButton,
  iconButton,
  to,
  onClick,
  mobileSubtitle
}) {
  const iconStyle = { backgroundImage: `url(${icon})` };

  return (
    <div className={classNames("footer-wrapper", className)}>
      <div>
        {mobileSubtitle && <p>{mobileSubtitle}</p>}
        <p>{title}</p>
        {subtitle && 
          <p>
            {Array.isArray(subtitle) 
              ? subtitle.map((item, i) => <Fragment key={i}>{item}</Fragment>)
              : subtitle
            }
          </p>
        }
        <FooterButton
          icon={iconButton}
          title={titleButton}
          to={to}
          onClick={onClick}
        />
      </div>
      <div className="footer-wrapper__icon" style={iconStyle} />
    </div>
  );
}
