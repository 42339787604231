import { observer } from "mobx-react";
import { useEffect } from "react";
import { Helmet } from "react-helmet";

import IntroPerformers from "components/Intro/IntroPerformers/IntroPerformers";
import ServicesSection from "./components/ServicesSection/ServicesSection";
import PlatformsSection from "./components/PlatformsSection/PlatformsSection";
import AboutSection from "./components/AboutSection/AboutSection";
import StatisticsSection from "./components/StatisticsSection/StatisticsSection";
import AdvantagesSection from "./components/AdvantagesSection/AdvantagesSection";

import AppStore from "AppStore";

import { carouselSlides } from "./carouselData";
import { useMatchMedia } from "utils/hooks/use-match-media";

import "./Main.scss";

const Main = observer(() => {
  const { isMobile } = useMatchMedia();

  useEffect(() => {
    window.scroll(0, 0);
  }, []);

  return (
    <main className="main">
      <Helmet>
        <title>Биржа микрозадач и ПФ от 1 руб | Сервис визитов, социального продвижения Boostclick.ru</title>
        <meta name="description" content="Сервис для накрутки визитов, поведенческих и социальных факторов, биржа микрозадач, софт по работе с отзывами на досках объявлений, OZON, WB и другими маркетплейсами - Boostclick.ru. Цены на задания от 1 руб. Все вопросы уточнить можно в тех. поддержке." />
        <meta name="robots" content="all" />
      </Helmet>

      <section className="main__header">
        <div className="main__header-description">
          <div className="main__header-description-title">
            <h1>Биржа&nbsp;задач&nbsp;</h1>
            <p>и ПФ&nbsp;&nbsp;— Boostclick <br /> Создайте свой сценарий</p>
            <div className="main__header-description-highlighted" />
          </div>
          <p className="main__header-description-text">Вы отправляете задачу на биржу Boostclick, а&nbsp;исполнители {!isMobile && <br />} лайкают, комментируют, пишут отзывы, смотрят, репостят и все, как вы попросите!</p>
        </div>
        <IntroPerformers />
      </section>

      <ServicesSection store={AppStore} isMobile={isMobile} />

      <div className="main__container">
        <PlatformsSection store={AppStore} isMobile={isMobile} />
        <AboutSection isMobile={isMobile} />
        <StatisticsSection />
        <AdvantagesSection slides={carouselSlides} isMobile={isMobile} />
      </div>

    </main>
  );
});

export default Main;
