import './ProjectsDraftsAbstractRow.scss';

export default function ProjectsDraftsAbstractRow({className, children}) {
  className = 'projects-drafts-abstract-row' + (className ? (' ' + className) : '');
  return (
    <div className={className}>
      {children}
    </div>
  );
}
