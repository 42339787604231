import { useState } from "react";
import { Link } from "react-router-dom";

import Plate from "components/UI/Plate/Plate";
import Button from "components/UI/Button/Button";
import FoldableSection from "components/UI/FoldableSection/FoldableSection";
import { PromoStep } from "./PromoStep/PromoStep";

import AppStore from "AppStore";
import popupStore from "components/Popup/PopupStore";

import { useMatchMedia } from "utils/hooks/use-match-media";
import { EVENTS, metricsEvent } from "metrics";
import {
  STEP1_TITLE_CREATE_TASK,
  STEP1_DESCRIPTION_CREATE_TASK_PART1,
  STEP1_DESCRIPTION_CREATE_TASK_PART2,
  STEP2_TITLE_LOOKING_PERFORMER,
  STEP2_DESCRIPTION_LOOKING_PERFORMER,
  STEP3_TITLE_CHECK_PAY_REPORT,
  STEP3_DESCRIPTION_CHECK_PAY_REPORT,
} from "./promoConstants";
import {
  create,
  search,
  report,
  step1,
  step2,
  step3,
} from "./promoConstants";

import "./Promo.scss";

export default function Promo({ isExpanded, howItWorks }) {
  const { isMobile } = useMatchMedia();
  const [expandedDesktop, setExpandedDesktop] = useState(false);

  const stepData = [
    {
      icon: { src: create, alt: "Иконка шага 1" },
      step: { src: step1, alt: "Номер шага 01" },
      title: STEP1_TITLE_CREATE_TASK,
      text: [
        STEP1_DESCRIPTION_CREATE_TASK_PART1,
        <Link
          to={AppStore.isAuth ? "/projects" : "/signin"}
          className="avito-promo__advices-link"
        >
          Черновики
        </Link>,
        <br />,
        STEP1_DESCRIPTION_CREATE_TASK_PART2,
        <>
          {AppStore.isAuth ? (
            <button
              className="avito-promo__advices-button"
              type="button"
              aria-label="Открыть пополнение баланса"
              onClick={() => popupStore.open("paymodal")}
            >
              Личном кабинете
            </button>
          ) : (
            <Link to="/signin" className="avito-promo__advices-link">
              Личном кабинете
            </Link>
          )}
        </>,
      ],
    },
    {
      icon: { src: search, alt: "Иконка шага 1" },
      step: { src: step2, alt: "Номер шага 01" },
      title: STEP2_TITLE_LOOKING_PERFORMER,
      text: STEP2_DESCRIPTION_LOOKING_PERFORMER,
    },
    {
      icon: { src: report, alt: "Иконка шага 1" },
      step: { src: step3, alt: "Номер шага 01" },
      title: STEP3_TITLE_CHECK_PAY_REPORT,
      text: STEP3_DESCRIPTION_CHECK_PAY_REPORT,
    },
  ];

  const toggleExpandedDesktop = () => {
    setExpandedDesktop(!expandedDesktop);
  };

  return (
    <Plate className={`avito-promo ${!AppStore.isAuth ? "no-margin-top" : ""}`}>
      {isMobile ? (
        <FoldableSection expanded={isExpanded}>
          <ul className="avito-promo__advices-list">
            {howItWorks.map((advice, i) => (
              <li key={i} className="avito-promo__advice">
                {advice}
              </li>
            ))}
          </ul>
        </FoldableSection>
      ) : (
        <FoldableSection expanded={expandedDesktop}>
          <div className="avito-promo__header">
            <p className="avito-promo__header-title">
              🤔 Как работает биржа заданий boostсlick.ru?
            </p>
            <Button
              title="Узнать"
              className="avito-promo__header-button-more"
              onClick={toggleExpandedDesktop}
            />
          </div>

          <div className="avito-promo__advices">
            <div className="avito-promo__advices-container">
              {stepData.map((step, index) => (
                <PromoStep key={index} {...step} />
              ))}
              <ul className="avito-promo__advices-list">
                {howItWorks.map((advice, i) => (
                  <li key={i} className="avito-promo__advice">
                    {advice}
                  </li>
                ))}
              </ul>
            </div>

            <div className="avito-promo__buttons">
              <Link to={process.env.REACT_APP_SUPPORT_ADDRESS} target="_blank">
                <Button
                  title="Хочу задать вопросы поддержке"
                  onClick={() => { metricsEvent(EVENTS.TELEGRAM_HELP) }}
                  style={{
                    backgroundColor: "#F2F1ED",
                    border: "none",
                  }}
                />
              </Link>
              <Button
                title="Все понятно — приступим!"
                classes={["wide"]}
                onClick={() => setExpandedDesktop(false)}
              />
            </div>
          </div>
        </FoldableSection>
      )}
    </Plate>
  );
}
