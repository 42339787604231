import { observer } from "mobx-react";
import InfoSection from "../InfoSection";
import "./TimerSection.scss";
import { formatTime } from "pages/Projects/ProjectsConstants";

const TimerSection = observer(({ reportTimer }) => {
  let timeLeft = reportTimer ? formatTime(reportTimer.timeLeft) : "00:00:00";
  timeLeft = timeLeft < "00:00:00" ? "00:00:00" : timeLeft;

  return (
    <InfoSection
      title="Проверьте ответы исполнителя"
      subtitle="Проверочные вопросы позволяют убедиться, что исполнитель выполнил задачу качественно"
      type="timer"
    >
      {reportTimer && (
        <div className="timer-section__container">
          <p className="timer-section__text">Автоодобрение</p>
          <div className="timer-section__timer">
            <div className="timer-section__timer-img" />
            <div className="report-modal__info-timer-text">
              {timeLeft}
            </div>
          </div>
        </div>
      )}
    </InfoSection>
  );
});

export default TimerSection;
