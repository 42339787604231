export const TOOLTIP_NO_WARRANTY = [
  "Вы не покупали гарантию на отзыв.",
  <br />,
  "Создайте новый проект, чтобы купить гарантию."
];

export const MORE_ABOUT_WARRANTY = "Подробнее о гарантии";

export const TITLE_WARRANTY_INCLUDED = "Гарантия включена";
export const DESCRIPTION_WARRANTY_INCLUDED = [
  "Гарантия 168 часов начнет действовать с момента получения отчета от исполнителя.",
  <br />,
  "После этого времени исполнитель получит оплату за работу.",
  <br />,
  "Если вас не устраивает работа исполнителя, вам необходимо отклонить задачу в отведенное время.",
  <br />,
  "Таймер гарантии начнется заново, после получения нового отчета.",
];

export const TITLE_WARRANTY_EXPIRED = "Гарантия закончилась";
export const DESCRIPTION_WARRANTY_EXPIRED = [
  "Прошло более 168 часов с момента поступления отчета.",
  <br />, 
  "Исполнитель получил оплату.",
  <br />, 
  "Гарантия согласно гарантийному договору по данному заданию больше не действует."
];

export const TITLE_WARRANTY_VALID = "Гарантия на проект - 168 часов";
export const DESCRIPTION_WARRANTY_VALID_PART1 = "Гарантия действует 168 часов c момента поступления последнего отчета от исполнителя по данному заданию.";
export const DESCRIPTION_WARRANTY_VALID_PART2 = [
  "Если ваш отзыв удалили, то просто смените исполнителя.",
  <br />,
  "Задание автоматически начнется заново.",
];
