import { observer } from 'mobx-react';
import classNames from 'classnames';

import Plate from 'components/UI/Plate/Plate';
import popupBoxStore from './PopupBoxStore';

import './PopupBox.scss';

export const PopupBox = observer(() => {
  return (
    <Plate className={classNames('popupbox', popupBoxStore.isOpen ? 'popupbox_opened' : '')}>
      {popupBoxStore.image && <img src={popupBoxStore.image} alt={`Иконка ${popupBoxStore.message}`}/>}
      {popupBoxStore.message && <div className='popupbox__message'>{popupBoxStore.message}</div>}
    </Plate>
  );
});
