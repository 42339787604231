import { observer } from "mobx-react";

import ProjectsActiveReportAuctionVisibilityPercentage from "./ProjectsActiveReportAuctionVisibilityPercentage/ProjectsActiveReportAuctionVisibilityPercentage";

import "./ProjectsActiveReportAuctionVisibility.scss";

const ProjectsActiveReportAuctionVisibility = observer(({ store, report }) => {
  const extraPrice = report?.extraPrice || 0;
  const addition = store.markValues[store.value];
  const percentage = store.getPercentageWithAddition(store.baseAmount, store.basePercentage, extraPrice, addition);

  return (
    <div className="projects-active-report-auction-visibility">
      <ProjectsActiveReportAuctionVisibilityPercentage percentage={percentage} />
      <p className="projects-active-report-auction-visibility__title">
        индекс привлекательности задания
      </p>
    </div>
  );
});

export default ProjectsActiveReportAuctionVisibility;
