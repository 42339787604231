import { observer } from "mobx-react";

import InputLink from "components/InputLink/InputLink";
import TextBlock from "components/UI/TextBlock/TextBlock";
import UploadFile from "components/UploadFile/UploadFile";

import { useMatchMedia } from "utils/hooks/use-match-media";

import clipIcon from "assets/images/clip.svg";

import "./SettingsSeoOther.scss";

const SettingsSeoOther = observer(({ store, linkRef, screenshotRef }) => {
  const { isMobile } = useMatchMedia();

  const onChangeLinkToAd = (e) => {
    store.setLinkToAd(e.target.value);
  };

  return (
    <div className="settings-seo-other__container">
      <div className="settings-seo-other__container-input" ref={linkRef}>
        <div className="settings-seo-step__title">
          <TextBlock title="Укажите URL источника ПФ" titleClassName="settings-seo-step__label" />
          <TextBlock title="С указанного URL пользователи перейдут на ваш сайт" titleClassName="settings-seo-step__sublabel" />
        </div>
        <InputLink defaultValue={store.linkToAd} onChange={onChangeLinkToAd} errortext={store.linkError} />
      </div>

      <div className="settings-seo-other__container-uploaded" ref={screenshotRef}>
        <div className="settings-seo-step__title">
          <TextBlock title="Где находится ссылка на целевую страницу?" titleClassName="settings-seo-step__label" />
          <TextBlock title="Прикрепите скриншот и добавьте комментарий, чтобы вашу ссылку точно нашли" titleClassName="settings-seo-step__sublabel" />
        </div>
        <div className="settings-seo-other__container-uploaded_data">
          {isMobile && <p>Добавьте скриншот в .JPG или .PNG или .WEBP</p>}
          <UploadFile
            className="settings-seo-other__add-button"
            fileType="linkScreenshot"
            title="Прикрепить скриншот ссылки"
            icon={clipIcon}
          />
          <div className="settings-seo-other__container-uploaded_data-comment">
            <p>Комментарий к скриншоту</p>
            <textarea
              minLength={0}
              maxLength={150}
              className={`textarea ${
                store.screenshotCommentError ? " error" : ""
              }`}
              placeholder="Введите комментарий к скриншоту или оставьте поле пустым"
              value={store.screenshotComment}
              onChange={(e) => {
                store.setScreenshotComment(e.target.value);
              }}
            />
          </div>
        </div>
      </div>
    </div>
  );
});

export default SettingsSeoOther;
