import { observer } from "mobx-react";

import "./FAQReportModal.scss";

export const FAQReportModal = observer(({ store, faq, type }) => {
  const handleQuestionClick = (index) => {
    store.open(type);
    store.setSelectedQuestionIndex(index);
    store.setSelectedAnswers(faq[index].answers);
  };

  return (
      <div className="faq-report-modal">
        <div className="faq-report-modal__title">FAQ</div>
        <div className="faq-report-modal__faqs">
          {faq.map((faq, index) => (
            <div
              key={index}
              className="faq-report-modal__faqs-question"
              onClick={() => handleQuestionClick(index)}
            >
              {faq.question}
              <div className="faq-report-modal__faqs-question-img" />
            </div>
          ))}
        </div>
      </div>
  );
});
