import { observer } from "mobx-react";

import CheckBox from "components/UI/CheckBox/CheckBox";

import paymentFormStore from "../../PaymentFormStore";

import "./FormStep3.scss";

const FormStep3 = observer(() => {
  const handleServiceChange = (service) => {
    paymentFormStore.updateService(
      service,
      !paymentFormStore.services[service]
    );
  };

  return (
    <div className="form-step3">
      <p className="form-step3__title">Какие услуги вас интересуют?</p>
      <div className="form-step3__checkboxes">
        {Object.entries(paymentFormStore.services).map(([service, checked]) => (
          <div key={service} className="form-step4__services-item">
            <CheckBox
              title={paymentFormStore.renderTitleServices(service)}
              checked={checked}
              onClick={() => handleServiceChange(service)}
            />
          </div>
        ))}
      </div>
    </div>
  );
});

export default FormStep3;
