import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { observer } from 'mobx-react';

import { Helmet } from 'react-helmet';

import Plate from 'components/UI/Plate/Plate';
import Button from 'components/UI/Button/Button';
import { TextInput } from 'components/UI/TextInput/TextInput';

import messageBoxStore from 'MessageBox/MessageBoxStore';

import { ApiError } from '../../client.js';

import client from 'client';

import './Restore.scss';

export const Restore = observer(() => {

  const navigate = useNavigate();

  const [isBusy, setIsBusy] = useState(false);
  const [login, setLogin] = useState('');

  async function onSubmit() {
    if (isBusy)
      return;

    if (!login)
      return messageBoxStore.showError('Введите email.');

    setIsBusy(true);
    try {
      await client.restore(login);
    } catch (e) {
      if (e instanceof ApiError) {
        messageBoxStore.showError(e.message);
        return;
      }
      throw e;
    } finally {
      setIsBusy(false);
    }

    messageBoxStore.showInfo('Письмо отправлено.', 'Если такой пользователь зарегистрирован, будет отправлена ссылка для восстановления.');
    navigate('/');
  }

  return (
    <div className='restore'>
      <Helmet>
        <title>Сброс пароля в сервисе — Boostclick</title>
        <meta name="description" content="Сбросить пароль в сервисе Boostclick" />
        <meta name="robots" content="noindex" />
      </Helmet>

      <form className="restore__form" onSubmit={e => { e.preventDefault(); onSubmit() }}>
        <Plate className='restore__plate'>
          <h1 className='restore__title'>Восстановление пароля</h1>
          <p className='restore__text'>
            Введите Email, на который был зарегестрирован аккаунт.
            Мы отправим на него временную ссылку для восстановления пароля.
          </p>

          <TextInput classes={["restore__input"]}  placeholder='Email' type='email' onChange={e => setLogin(e.target.value)} />

          <Button title='Получить письмо с ссылкой' />
        </Plate>
      </form>
    </div>
  );
});
