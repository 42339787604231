import './TextInput.scss';

export const TextInput = ({
  id,
  className,
  placeholder,
  minLength,
  maxLength = 200,
  value,
  defaultValue,
  onChange,
  style = {},
  classes = [],
  onKeyDown = () => {},
  errortext,
  type = 'text',
  readonly = false,
  inputmode,
  onBlur,
}) => {
  return (
    <div className={'textinput__container ' + (className ?? '') + ' ' + classes.join(' ')} style={style}>
      {errortext && <p className={'textinput__error ' + classes.join(' ')}>{errortext}</p>}
      <input
        id={id}
        minLength={minLength}
        maxLength={maxLength || 200}
        type={type}
        className={'textinput ' + classes.join(' ')}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
        defaultValue={defaultValue}
        readOnly={readonly}
        onKeyDown={onKeyDown}
        inputMode={inputmode}
        onBlur={onBlur}
      />
    </div>
  );
}
