import { observer } from "mobx-react";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";

import { ButtonWithIcon } from "components/UI/ButtonWithIcon/ButtonWithIcon";

import selectedIcon from "assets/images/selectdeadline.svg";

import AppStore from "AppStore";

import "./ModeSelection.scss";

const ModeSelection = observer(({ store }) => {
  const { pathname } = useLocation();

  useEffect(() => {
    AppStore.selectMarketStoreAndCalculatorByPath(pathname);
  }, [pathname]);

  return (
    <div className="mode-selection">
      <p className="mode-selection__title">Что будем улучшать в первую очередь?</p>
      <div className="mode-selection__container">
        {store.calculators.map((calculator, i) => (
          <ButtonWithIcon
            key={i}
            title={calculator.mode.title}
            className={`mode-selection__button ${
              store.selectedCalculatorIndex === i ? "active" : ""
            }`}
            icon={store.selectedCalculatorIndex === i ? selectedIcon : ""}
            onClick={() => { store.selectCalculator(i) }}
            ariaLabel={`Выбрать режим ${calculator.mode.title}`}
            to={calculator.mode.path}
            target="_self"
          />
        ))}
      </div>
    </div>
  );
});

export default ModeSelection;
