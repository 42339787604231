import { useRef, useState } from 'react';

import HTMLComment from 'react-html-comment';

import './Accordion.scss';

export default function Accordion({ title, text, noindex = false, isH3 = false }) {

  const ref = useRef();

  const [isExpanded, setIsExpanded] = useState(false);
  const [textStyle, setTextStyle] = useState({});

  function onClick() {
    const expand = !isExpanded;

    setIsExpanded(expand);
    setTextStyle(expand ? {
      height: ref.current.scrollHeight,
      paddingBottom: '28px'
    } : {});
  }

  const TitleTag = isH3 ? 'h3' : 'p';

  const div = <div className={'accordion' + (isExpanded ? ' accordion_expanded' : '')}>
      <TitleTag className='accordion__title' onClick={onClick}>{title}</TitleTag>
      <div ref={ref} className='accordion__text' style={textStyle}>{text}</div>
    </div>
  ;

  return (
    <>
      {noindex ?
        <>
          <HTMLComment text='noindex' />
          {div}
          <HTMLComment text='/noindex' />
        </>
        : div
      }
    </>
  );
}
