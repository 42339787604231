import { observer } from "mobx-react";
import { useNavigate } from "react-router-dom";

import { Popup } from "components/Popup/Popup";
import { TextInput } from "../TextInput/TextInput";
import Button from "../Button/Button.jsx";
import Faqed from "../Faqed/Faqed.jsx";
import CheckBox from "../CheckBox/CheckBox";

import popupStore from "components/Popup/PopupStore";
import paymentFormStore from "pages/PaymentForm/PaymentFormStore";

import "./PayModal.scss";

export const PayModal = observer(() => {
  const navigate = useNavigate();

  const handleSumButtonClick = (amount) => {
    popupStore.setAmount(amount.toString());
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    const regex = /^[1-9]?\d*$/;
    if (regex.test(value)) {
      popupStore.setAmount(value);
    }
  };

  const handleCheckboxChange = () => {
    popupStore.setIsCheckboxChecked(!popupStore.isCheckboxChecked);
  };

  const handlePayment = () => {
    if (popupStore.isCheckboxChecked) {
      paymentFormStore.resetForm(true);
      paymentFormStore.setAmountOfPayment(popupStore.amount);
      navigate("/payment-form");
      popupStore.close();
      popupStore.setIsCheckboxChecked(false);
    } else {
      popupStore.okPay();
    }
  };

  return (
    <Popup modalType="paymodal" title="Пополнение баланса">
      <Faqed text="Можно пополнить баланс один раз, а списывать на проекты частями.">
        <p className="paymodal__text">
          Введите сумму перевода или выберите из списка
        </p>
      </Faqed>
      <div className="paymodal__input-container">
        <TextInput
          type="text"
          inputmode="numeric"
          placeholder="Сумма"
          maxLength={7}
          value={popupStore.amount}
          onChange={handleInputChange}
          onKeyDown={(e) => {
            if (e.key === "0" && popupStore.amount === "") {
              e.preventDefault();
            }
          }}
          errortext={popupStore.amountErrorText}
        />
      </div>
      <p className="paymodal__subtext">От 1 до 1 000 000 &#8381;</p>
      <div className="paymodal__buttons-container">
        {[500, 1500, 3000, 5000, 10000].map((amount) => (
          <button
            key={amount}
            className="paymodal__sum"
            type="button"
            aria-label="Выберите сумму взноса"
            onClick={() => handleSumButtonClick(amount)}
          >
            {amount.toLocaleString()} &#8381;
          </button>
        ))}
      </div>

      <div className={`paymodal__checkbox-container ${popupStore.amount < 5000 ? "disable": ""}`}>
        <CheckBox
          title="Создать счет для юр. лица или ИП (от 5000 &#8381;)"
          checked={popupStore.isCheckboxChecked}
          onClick={handleCheckboxChange}
        />
      </div>
      
      <div className="paymodal__buttons">
        <Button
          title="Отмена"
          classes={["small", "wide"]}
          onClick={() => popupStore.close()}
          style={{
            backgroundColor: "#F2F1ED",
            border: "none",
          }}
        />
        <Button
          title="Пополнить"
          classes={["small", "wide"]}
          onClick={handlePayment}
          disabled={!popupStore.isOkEnabled}
        />
      </div>

      <p className="paymodal__warning">
        * Если оплата по Робокассе не проходит, попробуйте указать другую почту для получения чека.
      </p>
    </Popup>
  );
});
