import { observer } from "mobx-react";
import { Link } from "react-router-dom";

import InfoPoint from "components/UI/InfoPoint/InfoPoint";
import { Popup } from "components/Popup/Popup";
import Button from "components/UI/Button/Button";

import popupStore from "components/Popup/PopupStore";

import { useMatchMedia } from "utils/hooks/use-match-media";

import humanGray from "assets/images/humanGray.svg";
import editGray from "assets/images/editGray.svg";
import keyGray from "assets/images/keyGray.svg";
import settingGray from "assets/images/settingGray.svg";

import "./Recommendation.scss";

const infoPointsData = {
  avito: [
    { icon: humanGray, text: "Используйте ", span: "аккаунт активный более 3 месяцев" },
    { icon: editGray, children: 
      <ul className="recommendation__list">
        Используйте качественное объявление:
        <li><span>Фото </span> яркие, четкие, с разных ракурсов — клиентам должно быть понятно, что вы продаете</li>
        <li><span>Описание </span>полное, отвечающее на все возможные вопросы клиентов, с ключевыми словами</li>
        <li><span>Заголовок </span>без описательных прилагательных, краткий, точный, с ключевыми словами</li>
        <li><span>Цена </span>честная, адекватная по рынку</li>
      </ul>
    },
    { icon: keyGray, text: "Подберите ключевые слова для заголовка и описания на ", link: "wordstat.yandex.ru", to: "https://wordstat.yandex.ru/", },
    { icon: settingGray, text: "Внимательно настройте ПФ в проекте на Boostclick. Подсказки в калькуляторе помогут подобрать оптимальный бюджет", },
  ],
  yandexseo: [
    { icon: humanGray, text: "Ведите трафик на сайт из ТОП-20 выдачи или добавляйте к поисковым фразам название бренда" },
    { icon: editGray, children:
      <ul className="recommendation__list">
        <p>Для проверки используйте <Link to="https://metrika.yandex.ru/" target="_blank" rel="noopener noreferrer" className="recommendation__link">Яндекс Метрки</Link> и <Link to="https://metrika.yandex.ru/promo/webvisor" target="_blank" rel="noopener noreferrer" className="recommendation__link">Вебвизор</Link>:</p>
        <li><span>Подключите </span> сервисы к сайту до создание проекта на бирже</li>
        <li><span>Исполнители укажут в отчете </span>время начала и конца их сессии по МСК — вы сможете проверить их по своей статистике</li>
      </ul>
    },
    { icon: settingGray, text: "Внимательно настройте проект на Boostclick. Подсказки в калькуляторе помогут подобрать оптимальный бюджет" },
  ],
};

const Recommendation = observer(({ marketType }) => {
  const { isMobile } = useMatchMedia();
  const infoPoints = infoPointsData[marketType] || [];

  const subtitle = marketType === 'avito' 
    ? "Как не потратить деньги впустую и получить эффект от ПФ"
    : "Как не потратить деньги впустую и получить эффект SEO Яндекс";

  const renderContent = () => (
    <div className="recommendation">
      <p className="recommendation__title">Рекомендации</p>
      <p className="recommendation__subtitle">{subtitle}</p>
      <div className="recommendation__info-points">
        {infoPoints.map((props, index) => (
          <InfoPoint key={index} {...props} />
        ))}
      </div>
    </div>
  );
  
  return (
    <>    
      {isMobile ? 
        <Popup modalType="recommendation-modal">
          {renderContent()}
          <Button title="Спасибо! Учту в проекте" onClick={() => popupStore.close()} />
        </Popup>
      : renderContent()}
    </>
  );
});

export default Recommendation;