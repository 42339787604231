import { observer } from "mobx-react";
import { Link } from "react-router-dom";

import { Popup } from "components/Popup/Popup";
import Button from "components/UI/Button/Button";
import CheckBox from "components/UI/CheckBox/CheckBox";
import InfoPoint from "components/UI/InfoPoint/InfoPoint";

import popupStore from "components/Popup/PopupStore";

import warrantyIcon1 from "assets/images/warranty/warrantyIcon1.svg";
import warrantyIcon3 from "assets/images/warranty/warrantyIcon3.svg";

import { EVENTS, metricsEvent } from "metrics";
import { getDativeCase, getGenitiveCase } from "utils/helpers";

import "./WarrantyPopup.scss";

export const WarrantyPopup = observer(({ store }) => {
  const marketTitle = store.market.title;

  const warrantyList = {
    items: [
      marketTitle !== "Яндекс Карты" && `Я понимаю, что для успешной модерации на 5-10 заявок на ${getDativeCase(marketTitle)} должен приходиться 1 положительный отзыв`,
      marketTitle !== "Яндекс Карты" && `Я понимаю, что не следует заказывать чрезмерно похвальные отзывы, так как это уменьшает шансы на успешную модерацию`,
      <p>Я понимаю, что я <i>должен <b>самостоятельно проверять </b>и модерировать отчеты </i>исполнителей</p>,
      <p>Я понимаю, что перед подтверждением отчета необходимо <b>выждать 48 часов с момента публикации </b>отзыва и убедиться, что отзыв не удален</p>,
      "Я понимаю, что если работа не удовлетворяет, я могу отправить ее на доработку с комментарием для исполнителя/сменить исполнителя",
      <p>Я понимаю, что если время на проверку отчетов <b>по истечении гарантии (168 часов) </b>закончится, исполнитель получит оплату автоматически, и вернуть ее будет невозможно</p>,
      marketTitle !== "Яндекс Карты" && `Обеспечить работоспособность объявления на ${getDativeCase(marketTitle)} (ссылки в задании) на время активности исполнителей. Отвечать на звонки и сообщения, пока пишется отзыв`,
    ].filter(Boolean),
  };

  const infoPoints = [
    { icon: warrantyIcon1, text: "Гарантия защищает ваши отзывы от удаления в течение 168 часов после публикации отзыва" },
    { icon: warrantyIcon3, text: <p>Если отзыв удалят <strong>до модерации</strong> или <strong>после модерации</strong> {getGenitiveCase(marketTitle)} — благодаря гарантии, вы сможете бесплатно сменить исполнителя</p> },
  ];

  const handleAgreeClick = () => {
    store.setReviewWarranty(popupStore.agreed);
    popupStore.close();
  };

  return (
    <Popup modalType="warranty-popup" title="Гарантийное соглашение">
      <div className="warranty-popup__info">
        <div className="warranty-popup__info_points">
          {infoPoints.map(({ icon, text }, index) => (
            <InfoPoint key={index} icon={icon} text={text} />
          ))}
        </div>
        <ol className="warranty-popup__info-list">
          {warrantyList.items.map((item, index) => (
            <li key={index}>{item}</li>
          ))}
        </ol>
      </div>
      {!popupStore.warrantyAgreementReminderPopup && (
        <div className="warranty-popup__consent-container">
          <CheckBox
            title="С правилами и условиями гарантии ознакомлен(-а)"
            checked={popupStore.agreed}
            onClick={() => popupStore.setAgreed(!popupStore.agreed)}
          />
          <div className="warranty-popup__buttons">
            <Link
              className="warranty-popup__support"
              to={process.env.REACT_APP_SUPPORT_ADDRESS}
              target="_blank"
              onClick={() => { metricsEvent(EVENTS.TELEGRAM_HELP) }}
            >
              <Button title="Задать вопрос" />
            </Link>
            <Button
              title="Согласен(-a)"
              onClick={handleAgreeClick}
              disabled={!popupStore.agreed}
            />
          </div>
        </div>
      )}
    </Popup>
  );
});
