import { makeAutoObservable, runInAction } from "mobx";

import AppStore from "AppStore";
import ReportTimerStore from "pages/Projects/ReportModal/ReportTimerStore";
import messageBoxStore from "MessageBox/MessageBoxStore";

import client from "client";

const DEFAULT_LETTER_SEND_TASK_REWORK =
  "Добрый день.\n\nВы не прислали ответы на контрольные вопросы. Ответы являются обязательной частью задания!\n\nПри повторном нарушении сдачи отчета, будет сменен исполнитель без оплаты работы.";

class PopupStore {
  isOpen = false;
  modalType = null;

  constructor() {
    makeAutoObservable(this);
  }

  open(type) {
    this.isOpen = true;
    this.modalType = type;
  }

  close() {
    this.isOpen = false;
    this.agreed = false;
    this.amount = "";
    this.modalType = null;
  }

  // warranty popup
  agreed = false;
  setAgreed(value) {
    this.agreed = value;
  }

  warrantyAgreementReminderPopup = false;
  setWarrantyAgreementReminderPopup(value) {
    this.warrantyAgreementReminderPopup = value;
  }

  // sure modal
  text = "";
  ok = () => {};
  cancel = () => {};

  setText(text) {
    this.text = text;
  }

  setOk(func = () => {}) {
    this.ok = func;
  }

  setCancel(func = () => {}) {
    this.cancel = func;
  }

  // pay modal
  amount = "";
  setAmount(amount) {
    this.amount = amount;
  }

  get amountErrorText() {
    const amount = this.amount;
    if (!amount.length) return "";
    const number = Number(amount);
    if (!(number && number > 0 && number <= 1000000.0 && /^\d+$/.test(amount)))
      return "Введите сумму от 1 до 1 000 000";
    return "";
  }

  get isOkEnabled() {
    const amount = this.amount;
    if (!amount.length) return false;
    const number = Number(amount);
    return number && number > 0 && number <= 1000000.0 && /^\d+$/.test(amount);
  }

  okPay = async () => {
    if (!this.isOkEnabled) return;
    const link = await client.createDepositLink(Number(this.amount));
    if (link) window.location.replace(link);
  };

  isCheckboxChecked = false;
  setIsCheckboxChecked(value) {
    this.isCheckboxChecked = value;
  }

  // report modal
  reportData = undefined;
  projectData = undefined;
  showComment = false;
  showFAQModal = false;
  comment = "";
  statusText = "";
  searchPhrase = "";
  city = "";
  activityType = "";
  reportTimer = null;
  selectedQuestionIndex = null;
  selectedAnswers = [];

  openReportModal(reportData, projectData) {
    this.reportData = reportData;
    this.projectData = projectData;
    this.setComment(reportData.answer ? "" : DEFAULT_LETTER_SEND_TASK_REWORK);
    this.isOpen = true;
    this.startTimer();
  }

  closeReportModal() {
    this.isOpen = false;
    this.setShowComment(false);
    this.setShowFAQModal(false);
    this.stopTimer();
  }

  setShowFAQModal(showFAQModal) {
    this.showFAQModal = showFAQModal;
  }

  setSelectedQuestionIndex(index) {
    this.selectedQuestionIndex = index;
  }

  setSelectedAnswers(answers) {
    this.selectedAnswers = answers;
  }

  setShowComment(showComment) {
    this.showComment = showComment;
  }

  setComment(comment) {
    this.comment = comment;
  }

  setSearchPhrase(searchPhrase) {
    this.searchPhrase = searchPhrase;
  }

  setCity(city) {
    this.city = city;
  }

  setActivityType(activityType) {
    this.activityType = activityType;
  }

  setStatusText(statusText) {
    this.statusText = statusText;
  }

  startTimer() {
    if (this.reportData?.status === "ready")
      this.reportTimer = new ReportTimerStore(this.reportData.acceptDatetime);
  }

  stopTimer() {
    if (this.reportTimer) {
      this.reportTimer.stopCountdown();
      this.reportTimer = null;
    }
  }

  getReportTimer() {
    if (this.reportData?.status === "ready") {
      return new ReportTimerStore(this.reportData.acceptDatetime);
    }
    return null;
  }

  getTimeUntilConfirmationReviews() {
    const report = this.reportData;
    if (!report || report.status !== "ready") {
      return null;
    }

    const isReview = this.activityType === "Отзыв";
    const hasWarranty = this.projectData?.activities?.find(
      (a) => a.type === "MAIN.REVIEW" && a.warranty
    );

    if (!isReview) {
      return null;
    }

    const readyDatetime = new Date(report.readyDatetime);
    const deadline = hasWarranty
      ? readyDatetime.getTime() + 120 * 60 * 60 * 1000 // 120ч - с гарантией
      : readyDatetime.getTime() + 48 * 60 * 60 * 1000; // 48ч - без гарантии

    const timer = new ReportTimerStore(report.readyDatetime);
    return deadline - Date.now() + timer.timeLeft;
  }

  async approve() {
    await client.approveReport(this.reportData.id);
    AppStore.setReportsAsApproved(this.projectData.id, this.reportData.id);
    this.isOpen = false;
    this.stopTimer();
  }

  async rejectOrReplace(isReplace) {
    if (!this.comment) return messageBoxStore.showError("Добавьте комментарий");

    if (this.comment.trim().length < 4)
      return messageBoxStore.showError("Слишком короткий комментарий");

    this.stopTimer();

    const projectId = this.projectData.id;
    const reportId = this.reportData.id;

    if (isReplace) {
      await client.replaceReport(reportId, this.comment);
      AppStore.setReportAsReplaced(projectId, reportId);
    } else {
      await client.rejectReport(reportId, this.comment);
      AppStore.setReportAsRejected(projectId, reportId);
    }

    this.isOpen = false;
    this.setShowComment(false);
  }

  async reject() {
    return this.rejectOrReplace(false);
  }

  async replace() {
    return this.rejectOrReplace(true);
  }

  // project & project setup
  markValues = [50, 100, 200, 250, 350];
  baseAmount = 250;
  basePercentage = 30;
  
  value = 1;
  setValue(newValue) {
    this.value = newValue;
  }

  calculatePercentage(baseAmount, basePercentage, addition) {
    if (addition <= baseAmount) {
      return basePercentage;
    }
    const additionalAmount = addition - baseAmount;
    const additionalPercentage = (additionalAmount / 50) * 10;
    return Math.round(basePercentage + additionalPercentage);
  }

  getPercentageWithAddition(baseAmount, basePercentage, extraPrice, addition) {
    const newAmount = baseAmount + extraPrice + addition;
    return this.calculatePercentage(baseAmount, basePercentage, newAmount);
  }

  getPercentageWithoutAddition(baseAmount, basePercentage, extraPrice) {
    const newAmount = baseAmount + extraPrice;
    return this.calculatePercentage(baseAmount, basePercentage, newAmount);
  }

  selectedReport = null;
  setSelectedReport(report) {
    this.selectedReport = report;
  }
  
  showModalAuction = false;
  setShowModalAuction(value) {
    this.showModalAuction = value;
  }

  reportDataForRaisePrice = null;
  newExtraPriceForRaisePrice = null;
  agreedRulesRaisePrice = false;

  setAgreedRulesRaisePrice(value) {
    this.agreedRulesRaisePrice = value;
  }

  setReportDataForRaisePrice(reportData, newExtraPrice) {
    this.reportDataForRaisePrice = reportData;
    this.newExtraPriceForRaisePrice = newExtraPrice;
  }

  isUpdatingTask = false;
  async updateTaskExtraPrice(reportData, newExtraPrice) {
    if (this.isUpdatingTask) 
      return;
  
    this.isUpdatingTask = true;
    try {
      await client.updateTask(reportData.id, { extraPrice: newExtraPrice });
      runInAction(() => {
        reportData.extraPrice = newExtraPrice;
        reportData.extraPriceUpdateDatetime = new Date().toISOString();
      });
    } finally {
      runInAction(() => { this.isUpdatingTask = false });
    }
  }

  cancelTaskWithConfirm(reportData) {
    if (reportData.status !== "run" || reportData.workerId || reportData.cancelling) {
      return;
    }
    popupStore.setText("Вы действительно хотите отменить выполнение задачи?");
    popupStore.setOk(() => { this.cancelTask(reportData) });
    popupStore.open("suremodal");
  }
  
  isCancellingTask = false;
  async cancelTask(reportData) {
    if (this.isCancellingTask) 
      return;

    if (reportData.status !== "run" || reportData.workerId || reportData.cancelling) {
      return;
    }
  
    this.isCancellingTask = true;
    try {
      await client.cancelTask(reportData.id);
      AppStore.updateProjects();
      messageBoxStore.showWarning([ 
        "Вы остановили задачу.",
        <br />,
        <br />,
        "Сервис вернет вам замороженные деньги по данной задаче на ваш баланс."
      ])
    } finally {
      runInAction(() => { this.isCancellingTask = false });
    }
  }

  // bot modal
  chatStart = 'welcome';
  setChatStart(message) {
    this.chatStart = message;
  }

  chatMessages = [];
  addChatMessage(message) {
    this.chatMessages.push(message);
  }
  clearChatMessages() {
    this.chatMessages = [];
  }
}

const popupStore = new PopupStore();
export default popupStore;