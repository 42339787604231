import "./Badges.scss";

const Badges = ({ status }) => {
  // new, beta, exclusive
  // for home page: popular, soon, hit

  const statusTranslations = {
    new: "Новинка",
    beta: "Бета",
    exclusive: "Эксклюзив",
    popular: "Популярно",
    soon: 'скоро',
    hit: 'хит'
  };

  const translatedStatus = statusTranslations[status] || status;

  return (
    <div className={`badge badge__${status}`}>
      {translatedStatus}
    </div>
  );
};

export default Badges;
