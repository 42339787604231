import { Fragment, useState } from "react";

import "./Tooltips.scss";

export default function Tooltips({ children, description, style, innerStyle }) {
  const [isActive, setIsActive] = useState(false);

  return (
    <div
      className="tooltips"
      style={style}
      onMouseOver={() => {setIsActive(true)}}
      onMouseLeave={() => {setIsActive(false)}}
    >
      {children}
      {isActive && (
        <div className="tooltips__description" style={innerStyle}>
          {Array.isArray(description)
            ? description.map((item, i) => <Fragment key={i}>{item}</Fragment>)
            : description
          }
        </div>
      )}
    </div>
  );
}
